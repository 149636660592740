import { useRouter } from "next/router";
import Error from "next/error";
import { GetStaticPaths, GetStaticProps } from "next";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BreakpointProvider,
  Head,
  Footer,
  DefaultThemeProvider,
} from "@dreambigger/design-system/src";
import {
  Step,
  Flow,
  FinancialBrand,
  OnboardingApplication,
  StartStepAssets,
} from "@dreambigger/shared/src/types";
import { useSegment, useAppcues } from "@dreambigger/shared/src/hooks";
import {
  LocalStorage,
  extractBrowserQueryParam,
  removeBrowserQueryParam,
} from "@dreambigger/shared/src/utils";
import { AuthApi, api } from "@dreambigger/shared/src/api/acquire";
import {
  PasswordlessAuthProvider,
  useAuthToken,
  REFRESH_TOKEN_PARAM,
} from "../../../passwordless-auth";
import { StepRouter, StepStart, StepLayout } from "../../../steps";
import { FlowApi, useFlow } from "../../../api";
import useGTM from "@dreambigger/shared/src/hooks/use-gtm";
import { notification } from "antd";
import useAuthCallback from "../../../hooks/use-auth-callback";
import { CodeVerification } from "../../../components";
import getFingerprint from "@dreambigger/shared/src/utils/getFingerprint";

const authApi = new AuthApi(api);
export type FlowRenderOptions = {
  hideHeader?: boolean;
  hideFooter?: boolean;
};
type FlowPageProps = {
  flowData: Flow;
  skinSlug?: string;
  renderOptions?: FlowRenderOptions;
};

// Note - Steps that do not require authentication will save to local storage if a token isn't available
export type StepProps = {
  step: Step;
  brand: FinancialBrand;
  flow: Flow;
  progress: number;
  application?: OnboardingApplication;
};

// Initialize the various api's we need for this app.
export const flowApi = new FlowApi(api);

// Initialize acquire flows specific keys to access local storage
export const currentApplicationIdKey = "currentApplicationId";
export const emailKey = "email";
export const firstNameKey = "firstName";
export const lastNameKey = "lastName";
export const phoneKey = "phone";
export const skipAheadKey = "skipAhead";
export const keyByLoginModeHash = {
  sms: phoneKey,
  email: emailKey,
};
// **************************************
// How authentication works in flow pages
// **************************************

// The start step does not require the user to be authenticated and is statically rendered
// Hence the start page shows as the first render in a new browser session and as then as the page rehydrates the step router loads the appropriate step based on the stepSlug query param
// The flowId page handles extraction of the refresh token from the query param and provides refresh token and authentication handler to the passwordless auth provider
// On authentication only steps wrapped by the passwordless auth provider will be re-rendered / made aware of the change in authentication state
// All steps needing access to the application object or other protected resources must be wrapped by the passwordless auth provider (ideally in the step router)
// User is redirected to start step if they try to access a protected step and don't have a valid jwt or refresh token

// There are multiple authentication modes:
// 1. Manual OTP verification - Happens only on start step. Step change on authentication ensures propogation of authentication state.
// 2. Query param OTP verification - Used on follow up emails for partially completed applications. Happens on start step. User is redirected to the last step they filled out after authentication.
// 3. Guest login - Happens on start step. User is redirected to the next step (after start step) on authentication. User will not be able to access partially filled applications. May be used in combination with #2.
// 4. Query param refresh token (magic Link) verification - Happens on all steps except start step. May optionally (based on config) require user to enter OTP (without redirecting to the start page).

const FlowPage = ({
  flowData,
  skinSlug,
  renderOptions = {},
}: FlowPageProps) => {
  const { data: flow } = useFlow(flowData);

  const { hideFooter = false } = renderOptions;
  const router = useRouter();
  const { stepSlug } = router.query;
  const localStorage = useMemo(
    () => new LocalStorage(flow?.financialInstitution.id),
    [flow?.financialInstitution.id]
  );
  const tokenHelper = useAuthToken(flow?.financialInstitution.id);
  const segment = useSegment();
  const appcues = useAppcues();
  const googleTagManager = useGTM();
  const keyByLoginMode =
    keyByLoginModeHash[flow ? flow.financialInstitution.loginMode : "email"];
  const [refreshToken, setRefreshToken] =
    useState<string | undefined | null>(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [loginId, setLoginId] = useState("");

  // Use a passed in skinCode or search for a skin that matches the router.locale.
  // Fall back to the first skin in the array if neither exists.
  const skin = useMemo(() => {
    return (
      flow?.skins.find((skin) => skin.code === skinSlug) ||
      flow?.skins.find((skin) => skin.code === router.locale) ||
      flow?.skins[0]
    );
  }, [flow, skinSlug]);

  const landingPageStep: Step | undefined = useMemo(
    () => skin?.steps.find(({ type }) => type === "start"),
    [skin]
  );
  const slug = useMemo(
    () => stepSlug || landingPageStep?.slug,
    [stepSlug, landingPageStep]
  );
  const step = useMemo(() => {
    return skin?.steps.find((step) => step.slug === slug);
  }, [skin, slug]);
  const landingPageAssets: StartStepAssets | undefined = useMemo(
    () => landingPageStep?.assets,
    [landingPageStep]
  );
  const user = useMemo(() => {
    if (!tokenHelper.jwtIsExpired()) {
      const loggedUser = tokenHelper.decodeJWT(tokenHelper.getJwtToken())?.user;
      return loggedUser;
    }
  }, [step]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    //get the refresh token from the url and remove it from the url
    const refreshTokenQueryParam =
      extractBrowserQueryParam(REFRESH_TOKEN_PARAM);

    setRefreshToken(refreshTokenQueryParam);
    if (refreshTokenQueryParam) {
      removeBrowserQueryParam(REFRESH_TOKEN_PARAM, router);
    }
  }, [router.isReady]);

  useEffect(() => {
    // Set the global configuration for Antd notifications.
    // * Example: prevent more than one notification from being open at a time.
    notification.config({ maxCount: 1 }), [];
  });

  useEffect(() => {
    // Initialize google tag manager for the flow if the flow has a GTM ID.
    const gtmId = flow?.googleTagManager?.container?.id;

    if (gtmId) {
      googleTagManager.initialize(gtmId);
    }
  }, []);

  useEffect(() => {
    segment.identify({
      traits: {
        financialInstitutionId: flow?.financialInstitution.id,
        financialInstitutionName: flow?.financialInstitution.name,
        mostRecentFlowId: flow?.id,
      },
    });
  }, []);

  // Fire Google Tag Manager and Segment events upon step change.
  useEffect(() => {
    googleTagManager.push({
      event: "page viewed",
      page: step?.slug || "404",
    });
    segment.page({
      name: step?.slug || "404",
      properties: {
        stepType: step?.type || "404",
      },
    });
  }, [step]);

  // Identify the user in appcues
  useEffect(() => {
    user?.id &&
      appcues.identify(user.id.toString(), {
        flowId: flow?.id,
        step: step?.slug,
      });
  }, [router.query]);

  // handle magic link verification
  const handleAuthentication = useAuthCallback(flow!, keyByLoginMode);

  // returns false if OTP verification is required
  // else returns true
  const verifyRefreshToken = useCallback(
    (token: string) => {
      // remove previous JWT and loginId if it exists
      tokenHelper.removeJwtToken();
      localStorage.removeItem(keyByLoginMode);

      return getFingerprint()
        .then((visitorId) =>
          authApi.verifyMagicLink({
            userType: "acquire",
            deviceFingerprint: visitorId,
            token,
          })
        )
        .then(({ data }) => {
          // refresh token verified
          if ("jwt" in data) {
            handleAuthentication(data.jwt, "Magic Link Verified");
            return true;
          }

          //needs OTP verification
          if (data.needsOtpVerification) {
            setUserId(data.user.id);
            setLoginId(data.user.login.id);
            setShowVerifyModal(true);
            return false;
          }

          // invalid refresh token
          return true;
        })
        .catch((error) => {
          // most likely the URL is ill-formed resulting in a 422
          segment.track({
            action: "Authentication",
            label: "Refresh Token Verification Failed",
            properties: {
              flowId: flow?.id,
              error: error.message,
            },
          });
          return true;
        })
        .finally(() => {
          // clear refresh token from state after verification
          setRefreshToken(undefined);
        });
    },
    [
      handleAuthentication,
      flow,
      keyByLoginMode,
      localStorage,
      segment,
      tokenHelper,
    ]
  );

  if (!flow || !skin || !step) {
    return <Error statusCode={404} />;
  }

  if (!tokenHelper.jwtIsExpired()) {
    const user = tokenHelper.decodeJWT(tokenHelper.getJwtToken())?.user;
    segment.identify({
      userId: user?.id,
    });
  }

  const authParams = localStorage.getItem(keyByLoginMode)
    ? {
        otpId: localStorage.getItem(keyByLoginMode),
        stepSlug: landingPageStep?.slug,
      }
    : {
        stepSlug: landingPageStep?.slug,
      };

  // Remove magic link token from query params.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [REFRESH_TOKEN_PARAM]: _, ...nonAuthQueryParams } = router.query;
  const authProps = {
    authUrl: {
      query: {
        ...nonAuthQueryParams,
        ...authParams,
      },
    },
    financialInstitutionId: flow.financialInstitution.id,
    refreshToken,
    verifyRefreshToken,
  };

  const colors = {
    primary: skin.brand.primaryColor,
    textPrimary: skin.brand.primaryTextColor,
    textSecondary: skin.brand.secondaryTextColor,
    ctaBackground: skin.brand.secondaryColor,
  };

  return (
    <>
      <Head {...skin.head} />
      <DefaultThemeProvider colors={colors}>
        <BreakpointProvider>
          {step.type === "start" ? (
            <StepStart
              step={step}
              brand={skin.brand}
              flow={flow}
              renderOptions={renderOptions}
            />
          ) : (
            <>
              <StepLayout
                step={step}
                brand={skin.brand}
                renderOptions={renderOptions}
              >
                <PasswordlessAuthProvider {...authProps}>
                  <StepRouter
                    step={step}
                    skin={skin}
                    flow={flow}
                    startStep={landingPageStep}
                  />
                </PasswordlessAuthProvider>
              </StepLayout>
              <CodeVerification
                title={landingPageAssets?.otpModalHeaderText}
                headline={landingPageAssets?.otpModalTitle}
                description={landingPageAssets?.otpModalText}
                secondaryCta={landingPageAssets?.otpModalChangeIdText}
                visible={showVerifyModal}
                flow={flow}
                keyByLoginMode={keyByLoginMode}
                loginId={loginId}
                userId={userId}
                onSuccess={() => setShowVerifyModal(false)}
              />
            </>
          )}
          {!hideFooter && <Footer {...skin.brand.footer} />}
        </BreakpointProvider>
      </DefaultThemeProvider>
    </>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const res = await flowApi.getActiveIds();
  const activeIds: string[] = res.data;
  return {
    paths: activeIds.map((id) => ({ params: { flowId: id } })),
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const flowId = params!.flowId as string;

  const flowRes = await flowApi.getById(flowId);
  const flow: Flow = flowRes.data;

  return {
    props: { flowData: flow },
    revalidate: 60,
  };
};

export default FlowPage;
