import { useState, useCallback, useMemo } from "react";
import { message, Row, Col, Input } from "antd";
import {
  Form,
  DatePicker,
  Typography,
} from "@dreambigger/design-system/src/components";
import {
  ResponseFields,
  ExistingMemberStepAssets,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";
import dayjs from "dayjs";
import { useApplication } from "../api";
import { useAuthToken, useSegment } from "@dreambigger/shared/src/hooks";
import { prefixFieldsWithSlug } from "../utils/prefixStepSlug";
import { changeStepForward } from "../utils/changeStepForward";
import { default as PersonApi } from "@dreambigger/shared/src/api/acquire/person";
import { api } from "@dreambigger/shared/src/api/acquire";

const ssnLast4Key = "ssnLast4";
const birthdayKey = "birthday";
const personKey = "personUuid";

const { Text } = Typography;

const isValidSsnLast4 = (ssnLast4: string) => {
  return ssnLast4.match(/\b\d{4}\b/g);
};

export default function ExistingMember({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const [disabled, setDisabled] = useState(true);
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [form] = Form.useForm();
  const segment = useSegment();

  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  const tokenHelper = useAuthToken(flow.financialInstitution.id);

  const assets: ExistingMemberStepAssets = step.assets;
  const { slug, type } = step;

  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    setDisabled(
      !(
        values[birthdayKey] &&
        values[ssnLast4Key] &&
        isValidSsnLast4(values[ssnLast4Key].toString())
      )
    );
  };

  // Upon load, prefill inputs with stored responses.
  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    // Define modifiedValues const to be returned in place of initialValues.
    const modifiedValues: ResponseFields = {};

    for (const field in initialValues) {
      // DatePicker component requires a dayjs object instead of a string, so convert.
      if (field === birthdayKey) {
        modifiedValues[birthdayKey] = dayjs(
          initialValues[birthdayKey] as string
        );
      } else {
        modifiedValues[field] = initialValues[field];
      }
    }

    return modifiedValues;
  }, []);

  const nextStep = useCallback(
    (inputValues: any) => {
      changeStepForward(assets.nextSlug, inputValues);
    },
    [assets]
  );

  const handleNext = useCallback(
    (values: any) => {
      if (!application) {
        message.error("Unable to find application");
        return;
      }

      setProcessing(true);

      const personApi = new PersonApi(api, tokenHelper);
      personApi
        .verify({
          ssnLast4: values[ssnLast4Key],
          birthday: values[birthdayKey],
        })
        .then(({ data }) => {
          const response = {
            [personKey]: data.id,
          };

          applicationHelper
            .update(application.id, {
              slug,
              type,
              fields: response,
            })
            .then(() => {
              nextStep(values);
            })
            .finally(() => {
              setProcessing(false);

              // Prefix step slug followed by double underscore infront of fieldName
              const prefixedProperties = prefixFieldsWithSlug({
                fields: response,
                slug,
              });

              // Track submission in segment
              segment.track({
                action: "Button Click",
                label: `Submit - ${slug}`,
                properties: prefixedProperties,
              });
            });
        })
        .catch(() => {
          setVerifyFailed(true);
          setProcessing(false);
        });
    },
    [application, step]
  );

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    handlePrefill,
    handleNext,
    disabled,
    processing,
  };

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col xs={24} md={16}>
          <label>{assets.dobTitle}</label>
          <Form.Item name={birthdayKey}>
            <DatePicker
              placeholder={assets.dobPlaceholder}
              className="w-40 ao-bl-picker"
              config={assets.dobConfig}
              defaultPickerValue={dayjs(assets.dobConfig?.defaultPickerDate)}
              onFocus={() => setVerifyFailed(false)}
            />
          </Form.Item>

          <label>{assets.ssnLast4Title}</label>
          <Form.Item
            name={ssnLast4Key}
            valuePropName={"value"}
            // Use antd rules to display error message.
            // * Note: We're currently using our own custom disable logic as opposed to antd's, so this
            // * so this rule violation is ONLY for the error message, not for actually preventing the
            // * form from being submitted.
            rules={[
              () => ({
                // Use antd's validator to get value of field
                validator(_, value) {
                  if (
                    value && // if the field isn't empty...
                    !isValidSsnLast4(value)
                  ) {
                    return Promise.reject(
                      new Error(
                        "Invalid social security number. Please try again."
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              minLength={4}
              maxLength={4}
              placeholder={assets.ssnLast4Placeholder}
              className="w-40 ao-bl-input"
              onFocus={() => setVerifyFailed(false)}
            />
          </Form.Item>

          {verifyFailed && (
            <Text className="warning">
              {assets.verifyFailedMessage ||
                "Please enter your SSN and Birthday."}
            </Text>
          )}
        </Col>
      </Row>
    </StepWrapper>
  );
}
