//************************************************************************ /
// CLOUDINARY UPLOAD
// Adds a cloudinary widget for uploading images.
// To function, this component requires the following script to be initialized,
// (currently being handled in _document.tsx) -
// <script
//    src="https://widget.cloudinary.com/v2.0/global/all.js"
//    type="text/javascript"
//  />
//
// For additional reference, see https://cloudinary.com/documentation/upload_widget_reference

//************************************************************************ /

import { useEffect, useState } from "react";
import { Button } from "@dreambigger/design-system";
import { Row, Col, FormInstance, Form, Input, Spin } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

export interface CloudinaryUploadProps {
  fieldName: string;
  form: FormInstance<any>;
  className?: string;
  cloudName?: string;
  apiKey?: string;
  uploadPreset?: string;
  required?: boolean;
  clientAllowedFormats?: string[];
}

const CloudinaryUpload = ({
  fieldName,
  form,
  className = "ph-4 mv-6",
  cloudName,
  apiKey,
  uploadPreset,
  required,
  clientAllowedFormats,
}: CloudinaryUploadProps) => {
  // State for storing successfully uploaded urls
  const [imgLoaded, setImgLoaded] = useState(false);
  const [showSpin, setShowSpin] = useState(true);
  const cloudinaryHeight = "175px";

  // Render a spinner when the component first loads, giving time to check the response and get the imgUrl.
  useEffect(() => {
    setTimeout(() => {
      setShowSpin(false);
      form.getFieldValue(fieldName) && setImgLoaded(true);
    }, 300);
  }, []);

  useEffect(() => {
    // Create id "upload_widget"
    // TODO: Make configurable in the future.
    const myWidget = (window as any).cloudinary.createUploadWidget(
      {
        cloudName:
          cloudName || process.env.NEXT_PUBLIC_CLOUDINARY_DEFAULT_CLOUD_NAME,
        apiKey: apiKey || process.env.NEXT_PUBLIC_CLOUDINARY_DEFAULT_API_KEY,
        uploadPreset:
          uploadPreset ||
          process.env.NEXT_PUBLIC_CLOUDINARY_DEFAULT_UPLOAD_PRESET,
        multiple: false,
        clientAllowedFormats: clientAllowedFormats || [
          "jpeg",
          "jpg",
          "bmp",
          "tif",
          "tiff",
          "gif",
          "png",
          "eps",
          "raw",
          "cr2",
          "nef",
          "orf",
          "sr2",
        ],
      },
      (error: any, result: any) => {
        if (!error && result?.event === "success") {
          form.setFieldsValue({
            [fieldName]: result.info.secure_url,
          });
          setImgLoaded(true);
        }
      }
    );
    const uploadWidget = document.getElementById("upload_widget");
    // Upon creating the widget, add a "click" event listener.
    if (uploadWidget) {
      uploadWidget.addEventListener(
        "click",
        () => {
          myWidget.open();
        },
        false
      );
    }
  }, [imgLoaded, showSpin]);

  const handleReset = () => {
    form.setFieldsValue({
      [fieldName]: undefined,
    });
    setImgLoaded(false);
  };

  if (showSpin) {
    return (
      <Row>
        <Col xs={24} className={className}>
          <div
            className="flex items-center justify-center br-5 s-4 w-100"
            style={{ height: cloudinaryHeight, backgroundColor: "lightgray" }}
          >
            <Spin size="large" />
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      {/* Display an upload button or an equally-sized preview of the uploaded image */}
      {imgLoaded ? (
        <Col xs={24} className={className}>
          <img
            src={form.getFieldValue(fieldName)}
            className="s-4 w-100 br-5"
            style={{ height: cloudinaryHeight, objectFit: "cover" }}
          />
          <div
            className="darkenPrimaryBkd subtleLift pointer bn flex items-center justify-center br-50 fwb absolute"
            style={{
              top: "10px",
              right: "30px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              width: "30px",
              height: "30px",
            }}
            onClick={handleReset}
          >
            X
          </div>
        </Col>
      ) : (
        <Col xs={24} className={className}>
          <Button
            type="primary"
            id="upload_widget"
            className="flex items-center subtleLift textPrimary br-5
          subtleLift w-100 s-1"
            style={{ height: cloudinaryHeight }}
          >
            <CloudUploadOutlined className="w-100 f-10" />
          </Button>
        </Col>
      )}
      {/* This input is hidden from the user and filled when an image is uploaded. */}
      <Form.Item
        name={fieldName}
        validateTrigger={""}
        style={{ marginTop: "-60px" }}
        rules={[
          {
            required: required,
            message: "Please upload an image and try again",
          },
        ]}
      >
        <Input className="dn" />
      </Form.Item>
    </Row>
  );
};

export default CloudinaryUpload;
