import { useState, useEffect, useMemo, useCallback } from "react";

import { message, Form } from "@dreambigger/design-system";
import { AtomicStepAssets } from "@dreambigger/shared/src/types";

import StepWrapper from "./step-wrapper";
import { useApplication } from "../api";
import { StepProps } from "../pages/flows/[flowId]";
import { CheckCircleFilled, Loading3QuartersOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styles from "./steps.module.scss";
import { useRouter } from "next/router";
import { useSegment } from "@dreambigger/shared/src/hooks";

// The events returned to the Iframe handler originating from the Atomic Transact widget
enum AtomicTransactMessageEvent {
  INTERACTION = "atomic-transact-interaction",
  CLOSE = "atomic-transact-close",
}

// Atomic Transact Event Payloads for the Interaction events
enum AtomicTransactInteractionPayloadName {
  VIEWED_TASK_COMPLETED_PAGE = "Viewed Task Completed Page",
  CLICKED_EXTERNAL_LOGIN_RECOVERY_LINK = "Clicked External Login Recovery Link From Login Help Page",
}

const DEFAULT_ERROR_MESSAGE =
  "There was a problem loading this page. Please contact support.";

const TASK_ID_KEY = "atomicTaskId";
const TASK_STATUS_KEY = "atomicTaskStatus";

const spinnerIcon = <Loading3QuartersOutlined spin />;

export default function Atomic({ flow, step, brand, progress }: StepProps) {
  const router = useRouter();
  const segment = useSegment();

  const assets = step.assets as AtomicStepAssets;

  // State for managing whether the submit button is enabled.
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [webViewUrl, setWebViewUrl] = useState<string | null>(null);

  const [form] = Form.useForm();

  const applicationHelper = useApplication(flow.financialInstitution.id);

  if (!assets.accountDataSlug) {
    message.error(DEFAULT_ERROR_MESSAGE);
    console.error("Initial Data Slug is missing");
  }

  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  // Get the initial data for the Atomic widget  (if it exists)
  useEffect(() => {
    if (!form || !application) {
      return;
    }

    const initialValues = form.getFieldsValue([TASK_ID_KEY, TASK_STATUS_KEY]);

    // If the user has already completed this step, prefill the form with the task details and set the disabled state to true
    if (
      initialValues &&
      initialValues[TASK_ID_KEY] &&
      initialValues[TASK_STATUS_KEY] &&
      initialValues[TASK_STATUS_KEY] === "completed"
    ) {
      form.setFieldsValue({
        [TASK_ID_KEY]: initialValues[TASK_ID_KEY],
        [TASK_STATUS_KEY]: initialValues[TASK_STATUS_KEY],
      });
      setDisabled(true);
    } else {
      // Otherwise, reset the form and set the disabled state to false
      form.resetFields();
      setDisabled(false);
    }
  }, [form, application, disabled]);

  // Retrieve the initial data to be used in the Atomic widget
  const initialAccountData = useMemo(() => {
    if (!application || !assets.accountDataSlug) {
      return;
    }

    const data = applicationHelper.findResponseByApplicationId(
      application.id,
      assets.accountDataSlug
    );

    if (!data) {
      console.error("Initial Data unable to be found.");
    }

    return data;
  }, [applicationHelper, flow, application, assets.accountDataSlug]);

  // Fetch the Atomic web view url
  useEffect(() => {
    if (
      !initialAccountData ||
      !initialAccountData.fields ||
      !application ||
      !application.id ||
      disabled
    ) {
      return;
    }

    async function fetchAtomicSessionUrl() {
      if (!application || !initialAccountData || webViewUrl) {
        return;
      }

      const accounts = [
        {
          routingNumber: initialAccountData?.fields?.[
            assets?.routingNumberFieldKey ?? ""
          ] as string,
          accountNumber: initialAccountData?.fields?.[
            assets?.accountNumberFieldKey ?? ""
          ] as string,
          title: initialAccountData?.fields?.[
            assets?.accountTitleFieldKey ?? ""
          ] as string,
          accountType: initialAccountData?.fields?.[
            assets?.accountTypeFieldKey ?? ""
          ] as string,
        },
      ];

      try {
        const response = await applicationHelper.getAtomicSessionUrl({
          applicationId: application.id,
          accounts,
          atomicConfigurationUuid: assets.atomicConfigurationUuid!,
        });

        const url = response.data?.redirectUri;

        setWebViewUrl(url);
      } catch (error) {
        message.error(DEFAULT_ERROR_MESSAGE);
        console.error(error);
      }

      setLoading(false);
    }

    fetchAtomicSessionUrl();
  }, [
    application,
    assets.atomicConfigurationUuid,
    webViewUrl,
    initialAccountData,
  ]);

  // Handle events from iframe
  const handleIframeMessage = useCallback(
    (event: MessageEvent) => {
      const messageData = event.data;

      if (messageData?.event === AtomicTransactMessageEvent.INTERACTION) {
        // If the atomic transact widget returns an interaction event, handle based on the payload name
        const { payload = {} } = messageData;

        switch (payload.name) {
          case AtomicTransactInteractionPayloadName.VIEWED_TASK_COMPLETED_PAGE: {
            if (
              payload?.value?.state === "completed" &&
              payload?.value?.taskId
            ) {
              // If the task is completed, update the application with the task details
              const taskData = {
                [TASK_ID_KEY]: payload.value.taskId,
                [TASK_STATUS_KEY]: payload.value.state,
              };

              // auto submit form on task completion
              form.setFieldsValue(taskData);
              form.submit();
            }
            break;
          }
          case AtomicTransactInteractionPayloadName.CLICKED_EXTERNAL_LOGIN_RECOVERY_LINK: {
            if (payload?.value?.url) {
              window.open(payload.value.url, "_blank");
            }
            break;
          }
        }
      } else if (messageData?.event === AtomicTransactMessageEvent.CLOSE) {
        // If user wants to exit the widget, send them to the previous step in the flow
        handleAtomicTransactExit();
      }
    },
    [form]
  );

  // Add event listener for iframe messages
  useEffect(() => {
    window.addEventListener("message", handleIframeMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [handleIframeMessage]);

  // Returns the user to the previous step if they click any of the exit buttons while engaging
  // with the Atomic Transact widget
  const handleAtomicTransactExit = useCallback(() => {
    router.push({
      query: {
        ...router.query,
        stepSlug: assets.previousSlug,
      },
    });

    segment.track({
      action: "Exit Atomic Transact",
      label: `Back - ${step.slug}`,
    });
  }, [router, assets.previousSlug, step.slug]);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    disabled: !disabled,
    form,
    // Prefills hidden form on refresh (this is a hack for step-wrapper's inputHandle function)
    handleInput: () => {},
  };

  // ----- FINAL RENDER ------

  return (
    <StepWrapper {...wrapperProps}>
      <Form.Item name={TASK_ID_KEY} style={{ display: "none" }} />

      <Form.Item name={TASK_STATUS_KEY} style={{ display: "none" }} />

      {assets.helpText && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.helpText ?? "",
            }}
          />
        </div>
      )}

      {assets.termsText && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.termsText ?? "",
            }}
          />
        </div>
      )}

      {loading && <Spin indicator={spinnerIcon}></Spin>}

      {/* User has not completed this step, and is about to go through Atomic webview */}
      {!loading && webViewUrl && !disabled && (
        <iframe
          id="atomicIFrameContainer"
          src={webViewUrl}
          style={{ width: "100%", height: "100vh" }}
        />
      )}

      {/* User has already completed this step. */}
      {!loading && disabled && (
        <div
          className="flex items-center f-6 md_f-7 mb-4"
          style={{ color: "green" }}
        >
          <CheckCircleFilled />
          <p className="ml-2 mb-0">Transaction Completed, please proceed.</p>
        </div>
      )}
    </StepWrapper>
  );
}
