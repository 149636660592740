// TODO: Replace styles from module.scss with classes
// from design system / Ant responsive layout components (Row, Col etc)

import { useEffect } from "react";
import Image from "next/image";
import styles from "./PromoSection.module.scss";
import { notification } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";

export interface PromoSectionProps {
  heroImage?: string;
  heroText?: string;
  heroLogo?: string;
  showMobileNotification?: boolean;
  heroImageBlockHtml?: string;
  heroTextBlockHtml?: string;
}

export default function PromoSection({
  heroImage = "/images/720x630.webp",
  heroText = "",
  heroLogo,
  showMobileNotification = true,
  heroImageBlockHtml,
  heroTextBlockHtml,
}: PromoSectionProps) {
  // * MOBILE ONLY - On load, open a notification message displaying the heroText that would
  //   normally be displayed on desktop if it has never been displayed or has changed from previous step.
  //   This defaults to true but can be set to be false in step-layout.tsx based on step type
  useEffect(() => {
    // Function to open notification
    const openNotification = () => {
      notification.open({
        duration: 0,
        className: `${styles.promotionNotification} bg-cta s-7 ba b-darkPrimary br-2`,
        placement: "bottomLeft",
        style: { padding: "0px 14px", bottom: 0 },
        closeIcon: (
          <CloseCircleTwoTone
            className="white f-6"
            twoToneColor={"#000000a90"}
          />
        ),
        message: (
          <p
            style={{ marginTop: "revert", marginBottom: "revert" }}
            className="f-2 textSecondary lh-1"
            dangerouslySetInnerHTML={{
              __html: heroText,
            }}
          />
        ),
      });
    };

    // Check if mobile notification should be shown
    if (showMobileNotification && heroText && window.innerWidth < 960) {
      openNotification();
    }

    // Cleanup function to run when the component unmounts or conditions change
    return () => {
      notification.destroy(); // This clears all notifications
    };
  }, [heroText, showMobileNotification]); // Dependencies for effect

  return (
    <div
      className={`${styles.promoSection} w-100 h-100 min-h-100vh bg-cta`}
      style={{ overflow: "hidden" }}
    >
      {heroImageBlockHtml ? (
        <div
          dangerouslySetInnerHTML={{ __html: heroImageBlockHtml }}
          style={{ height: "70vh" }}
        />
      ) : (
        <div className="relative" style={{ height: "70vh" }}>
          <Image
            src={heroImage}
            alt={heroText}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            priority={true}
          />
        </div>
      )}
      {heroTextBlockHtml ? (
        <div
          className="w-100 h-100"
          dangerouslySetInnerHTML={{ __html: heroTextBlockHtml }}
        />
      ) : (
        <>
          <div className={`${styles.logoBox} tc bg-primary`}>
            {heroLogo && (
              <img className="w-auto mt-8" src={heroLogo} alt="logo" />
            )}
          </div>

          <div
            className={`${styles.text} textSecondary f-4 xl_f-5 pt-8 lh-2 pl-3`}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: heroText,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
