import { AxiosInstance } from "axios";
import { TokenHelper } from "../../types";

const basePath = "/financial-persons";

// All the paths for this api domain.
const paths = {
  verify: () => basePath + "/verify",
};

class PersonApi {
  api: AxiosInstance;
  tokenHelper: TokenHelper;

  constructor(api: AxiosInstance, tokenHelper: TokenHelper) {
    this.api = api;
    this.tokenHelper = tokenHelper;
  }

  // verify financial person.
  // POST /financial-persons/verify
  verify = ({ ssnLast4, birthday }: { ssnLast4: string; birthday: string }) => {
    return this.api.post<{
      id: string;
      firstName?: string;
      middleName?: string;
      lastName?: string;
      email?: string;
      cellPhone?: string;
    }>(
      paths.verify(),
      {
        ssnLast4,
        birthday,
      },
      {
        headers: {
          Authorization: this.tokenHelper.formatAuthHeader(this.authToken),
        },
      }
    );
  };

  get authToken() {
    return this.tokenHelper.getJwtToken();
  }
}

export default PersonApi;
