export interface ResponseStepFieldProps {
  title: string;
  value: any;
  applyBackground: boolean;
}

const ResponseStepField = ({
  title,
  value,
  applyBackground,
}: ResponseStepFieldProps) => {
  return (
    <div
      key={title}
      className="pv-1"
      style={{
        backgroundColor: applyBackground ? "#f0f5f5" : "",
      }}
    >
      <p className="mb-0 fwb f-1 fwt pl-2 lh-1" style={{ color: "gray" }}>
        {title}
      </p>
      <p className="mb-0 pl-2 lh-2">{value}</p>
    </div>
  );
};

export default ResponseStepField;
