import { isValidPhoneNumber } from "libphonenumber-js";

//****************************/
// EMAIL
//****************************/
export const isValidEmail = (inputValue: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(inputValue);
};

//****************************/
// PHONE
//****************************/
export { isValidPhoneNumber };

//****************************/
// AUTOCOMPLETE
//****************************/
export const isValidAutoComplete = (inputValue: string, options: any) => {
  if (!inputValue || !options) return false;
  for (const option of options) {
    if (option.value === inputValue) return true;
  }
  return false;
};

//****************************/
// SSN
//****************************/
// Check if the provided ssn is made up of only one number (111-11-1111)
const includesAllSameNumbers = (ssn: string) => {
  // Only run check if the masked input has been completely filled out(no "_" in last string)
  if (ssn[ssn.length - 1] === "_") {
    return;
  }
  // Search SSN for two unique numbers. If found, return false (not all numbers are the same)
  const ssnFormatted = ssn.replaceAll("-", "");
  return /^(.)\1+$/.test(ssnFormatted);
};

// Checks if provided ssn number is valid
export const isValidSsn = (ssn: string) => {
  // Bypass SSN validation if set to true
  if (process.env.NEXT_PUBLIC_BYPASS_SSN_INPUT_VALIDATION === "true") {
    return true;
  }
  // Step 1 - Check that all the numbers in the SSN are not the same. If they are, return invalid (false).
  if (includesAllSameNumbers(ssn)) return false;
  // Step 2 - Run the ssn through a regex validator
  const ssnRegex = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
  return ssnRegex.test(ssn);
};

//****************************/
// ROUTING NUMBER VALIDATION
//****************************/

//routing number validation
export const isValidRoutingNumber = (routingNumber: string) => {
  // Check if the routing number has exactly 9 digits
  if (routingNumber.length !== 9) {
    return false;
  }

  // Convert the routing number string to an array of digits
  const routingDigits = routingNumber.split("").map(Number);

  // Calculate the check digit using the correct algorithm
  const checkDigit =
    3 * (routingDigits[0] + routingDigits[3] + routingDigits[6]) +
    7 * (routingDigits[1] + routingDigits[4] + routingDigits[7]) +
    (routingDigits[2] + routingDigits[5] + routingDigits[8]);

  // If the check digit is not 0 and divisible by 10, the routing number is valid
  return checkDigit !== 0 && checkDigit % 10 === 0;
};

//****************************/
// Other
//****************************/
export const includesUnderscore = (inputValue: string) => {
  if (inputValue.includes("_")) return true;
};

export const isValidRegex = (inputValue?: string, regex?: string) => {
  if (!inputValue || !regex) return true;
  const regexPattern = new RegExp(regex);
  return regexPattern.test(inputValue);
};
