import { useState, useRef, useEffect, useCallback } from "react";
import { Col, Row, Spin, Tooltip } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Modal, Typography } from "@dreambigger/design-system/src/components";
import { Flow } from "@dreambigger/shared/src/types";
import { useSegment } from "@dreambigger/shared/src/hooks";
import useAuthCallback from "../../hooks/use-auth-callback";
import { AuthApi } from "@dreambigger/shared/src/api/acquire";
import { api } from "@dreambigger/shared/src/api/acquire";
import getFingerprint from "@dreambigger/shared/src/utils/getFingerprint";
const { Link } = Typography;

const authApi = new AuthApi(api);

const spinnerIcon = <Loading3QuartersOutlined style={{ fontSize: 24 }} spin />;

export interface CodeVerificationProps {
  title?: string;
  headline?: string;
  description?: string;
  secondaryCta?: string;
  visible?: boolean;
  flow: Flow;
  keyByLoginMode: string;
  loginId: string;
  userId: string;
  onSecondaryCtaClick?: () => void;
  onCancel?: () => void;
  onSuccess: () => void;
}
const isValidInput = (value: string) =>
  value.length === 0 || /^[0-9]{1}$/.test(value);

export default function CodeVerification({
  headline,
  description,
  secondaryCta,
  title,
  visible,
  flow,
  keyByLoginMode,
  loginId,
  userId,
  onSecondaryCtaClick,
  onCancel,
  onSuccess,
}: CodeVerificationProps) {
  const [code1, setCode1] = useState("");
  const code1Ref = useRef<HTMLInputElement>(null);
  const [code2, setCode2] = useState("");
  const code2Ref = useRef<HTMLInputElement>(null);
  const [code3, setCode3] = useState("");
  const code3Ref = useRef<HTMLInputElement>(null);
  const [code4, setCode4] = useState("");
  const code4Ref = useRef<HTMLInputElement>(null);
  const [code5, setCode5] = useState("");
  const code5Ref = useRef<HTMLInputElement>(null);
  const [code6, setCode6] = useState("");
  const code6Ref = useRef<HTMLInputElement>(null);
  const segment = useSegment();
  const [invalidCode, setInvalidCode] = useState(false);
  const [loading, setLoading] = useState(false);

  // handle OTP verification
  const handleAuthentication = useAuthCallback(flow, keyByLoginMode);

  const onCodeChange = useCallback(
    (codeValue: any) => {
      segment.track({
        action: "Authentication",
        label: "OTP Entered",
        properties: {
          [keyByLoginMode]: loginId,
          flowId: flow.id,
        },
      });

      // Begin verification process (spinner will display on slow connections)
      setLoading(true);
      // Reset invalidCode flag
      // setInvalidCode(false);

      getFingerprint()
        .then((visitorId) =>
          authApi.verifyOtp({
            userId,
            codeValue: codeValue,
            userType: "acquire",
            deviceFingerprint: visitorId,
          })
        )
        .then(({ data }) => {
          handleAuthentication(data.jwt, "OTP Verified");

          // Close modal, update state, and go to next step
          setLoading(false);
          onSuccess();
          return;
        })
        .catch(() => {
          // If the code entered was invalid, stop "verifyingCode" (loading animation)
          // and flag the CodeVerification component that an invalidCode was entered.
          segment.track({
            action: "Authentication",
            label: "OTP Rejected",
            properties: {
              [keyByLoginMode]: loginId,
              flowId: flow.id,
            },
          });
          setLoading(false);
          setInvalidCode(true);
          return;
        });
    },
    [userId]
  );

  const codeCssClasses = "br-1 h-8 w-8 mh-1 tc b-gray-6 ba f-5 pointer";

  // Storing refs in an array so that we can call them by number using array position.
  const inputs = [code1Ref, code2Ref, code3Ref, code4Ref, code5Ref, code6Ref];
  // ^ Doing the same for state-setting functions associated with the inputs.
  const setCodeArray = [
    setCode1,
    setCode2,
    setCode3,
    setCode4,
    setCode5,
    setCode6,
  ];

  // As code numbers are entered, check if a full code is provided.
  // If a full code has been entered, submit it for verification.
  useEffect(() => {
    const fullCode = `${code1}${code2}${code3}${code4}${code5}${code6}`;
    if (fullCode.length === 6) {
      onCodeChange(fullCode);
    }
  }, [code1, code2, code3, code4, code5, code6]);

  // If user tries to paste a code into any of the boxes, enter it into the boxes.
  const handlePaste = (e: any) => {
    // Prevent normal paste behavior
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
    // If code is not 6 characters long, immediately reject.
    if (text.length !== 6) {
      clearInputs();
      setInvalidCode(true);
      return;
    }
    // Loop through text one character at a time. If a valid char, fill the corresponding box.
    // As soon as one invalid character is found, reject.
    let i = 0;
    for (const c of text) {
      const setCode = setCodeArray[i];
      if (!isValidInput(c)) {
        clearInputs();
        setInvalidCode(true);
        return;
      }
      setCode(c);
      i++;
    }
  };

  const handleKeyDown = (inputNum: number, event: KeyboardEvent) => {
    const key = event.key;
    const selected = inputNum - 1;
    const setCode = setCodeArray[selected];

    switch (key) {
      // Navigate keys using arrow buttons
      case "ArrowRight":
      case "ArrowUp":
        if (inputNum < 6) {
          inputs[selected + 1].current?.focus();
        }
        break;

      case "ArrowLeft":
      case "ArrowDown":
        if (inputNum > 1) {
          inputs[selected - 1].current?.focus();
        }
        break;

      // Upon hitting delete, remove value and move back one space.
      case "Backspace":
      case "Delete":
        setCode("");
        if (inputNum > 1) {
          inputs[selected - 1].current?.focus();
        }
        break;

      // For all other keys, if acceptable input, update state and advance to the next input.
      default:
        if (!isValidInput(key)) {
          return;
        }
        setCode(key);
        if (inputNum < 6) {
          inputs[selected + 1].current?.focus();
        }
        return;
    }
  };

  // Reset state for all inputs and set focus on first input.
  const clearInputs = () => {
    setCode1("");
    setCode2("");
    setCode3("");
    setCode4("");
    setCode5("");
    setCode6("");
    code1Ref.current?.focus();
  };

  useEffect(() => {
    if (invalidCode) {
      clearInputs();
      setTimeout(() => {
        setInvalidCode(false);
      }, 3000);
    }
  }, [invalidCode]);

  //WHEN MODAL BECOMES VISIBLE - Clear inputs and focus cursor on first input.
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        clearInputs();
      }, 100); //Brief delay while animation finishes
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      title={title}
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
      keyboard={!!onCancel}
      closable={!!onCancel}
    >
      <Row justify="center">
        <Col xs={24} sm={14}>
          {loading ? (
            <div className="pv-9 mv-9 flex items-center justify-center">
              <Spin indicator={spinnerIcon} />
            </div>
          ) : (
            <div className="flex flex-column items-center">
              {/* Headline */}
              <div className="lh-3 f-5 fwb pt-4 mt-5 tc black">{headline}</div>
              {/* Description */}
              <div className="gray-7 f-4 tc mt-3">{description}</div>
              {/* 2FA Input Boxes - Display "Invalid Code" briefly as tooltip when incorrect */}
              <Tooltip
                overlay={<span>Sorry! Invalid code.</span>}
                open={invalidCode}
                placement="bottomLeft"
              >
                <div className="mt-5 justify-center flex f-5">
                  <input
                    value={code1}
                    type="number"
                    pattern="\d*"
                    className={codeCssClasses}
                    onKeyDown={(e) =>
                      handleKeyDown(1, e as unknown as KeyboardEvent)
                    }
                    ref={code1Ref}
                    style={{ caretColor: "transparent" }}
                    onPaste={handlePaste}
                  />
                  <input
                    value={code2}
                    type="number"
                    pattern="\d*"
                    className={codeCssClasses}
                    onKeyDown={(e) =>
                      handleKeyDown(2, e as unknown as KeyboardEvent)
                    }
                    ref={code2Ref}
                    style={{ caretColor: "transparent" }}
                    onPaste={handlePaste}
                  />
                  <input
                    value={code3}
                    type="number"
                    pattern="\d*"
                    className={codeCssClasses}
                    onKeyDown={(e) =>
                      handleKeyDown(3, e as unknown as KeyboardEvent)
                    }
                    ref={code3Ref}
                    style={{ caretColor: "transparent" }}
                    onPaste={handlePaste}
                  />
                  <input
                    value={code4}
                    type="number"
                    pattern="\d*"
                    className={codeCssClasses}
                    onKeyDown={(e) =>
                      handleKeyDown(4, e as unknown as KeyboardEvent)
                    }
                    ref={code4Ref}
                    style={{ caretColor: "transparent" }}
                    onPaste={handlePaste}
                  />
                  <input
                    value={code5}
                    type="number"
                    pattern="\d*"
                    className={codeCssClasses}
                    onKeyDown={(e) =>
                      handleKeyDown(5, e as unknown as KeyboardEvent)
                    }
                    ref={code5Ref}
                    style={{ caretColor: "transparent" }}
                    onPaste={handlePaste}
                  />
                  <input
                    value={code6}
                    type="number"
                    pattern="\d*"
                    className={codeCssClasses}
                    onKeyDown={(e) =>
                      handleKeyDown(6, e as unknown as KeyboardEvent)
                    }
                    ref={code6Ref}
                    style={{ caretColor: "transparent" }}
                    onPaste={handlePaste}
                  />
                </div>
              </Tooltip>
              {/* Error Message */}
              {/* Secondary CTA Link (Normally - "Change email") */}
              {onSecondaryCtaClick && (
                <Link
                  onClick={() => {
                    onSecondaryCtaClick();
                    segment.track({
                      action: "Link Click",
                      label: "Secondary Cta",
                    });
                  }}
                >
                  <div className="tc gray-8 fwb f-1 mb-5 pt-5 pb-5 underline">
                    {secondaryCta}
                  </div>
                </Link>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Modal>
  );
}
