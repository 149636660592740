import { useEffect } from "react";
import Modal from "react-modal";
import { vouchedOnReady, unmountVouch } from "../../utils/vouched-onload";
import { Button } from "@dreambigger/design-system/src/components";
import useScript from "../../api/use-script";
import { useRef } from "react";
import {
  FinancialBrand,
  OnboardingApplication,
} from "@dreambigger/shared/src/types";
import { CameraFilled } from "@ant-design/icons";

export interface VouchedIDVProps {
  showModal: boolean;
  setShowModal: any;
  setVerificationResult: any;
  brand: FinancialBrand;
  deviceHandoff?: boolean;
  config?: any;
  application?: OnboardingApplication;
}

export interface VouchedResponse {
  errors: string[];
  id: string;
  result: {
    success: boolean;
  };
}

export default function VouchedIDV({
  showModal,
  setShowModal,
  setVerificationResult,
  brand,
  deviceHandoff,
  config,
  application,
}: VouchedIDVProps) {
  // Eventually, replace this with onReady in <Script> tag below when we upgrade to Next 12.4
  const status = useScript("https://static.vouched.id/widget/vouched-2.0.0.js");
  const idvRef = useRef(null);

  const handleExit = () => {
    unmountVouch(idvRef, config);
    setTimeout(() => setShowModal(false, 300));
  };

  // Allow modal to be closed by hitting the escape button.
  // Minor bug to be resolved: This currently only works after moving past the first screen.
  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.key === "Escape") {
        handleExit();
      }
    };
    if (showModal) {
      document.addEventListener("keydown", handleEscape);
    } else {
      document.removeEventListener("keydown", handleEscape);
    }
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [showModal, handleExit]);

  // Do not load modal until script has been loaded.
  if (status !== "ready") {
    return <></>;
  }

  return (
    <Modal
      isOpen={showModal}
      className="mh-auto h-100 md_w-70 lg_w-40 xxl_w-25 flex flex-column items-center justify-start pb-8"
      style={{
        content: {
          backgroundColor: "white",
          borderRadius: "36px",
          overflowY: "scroll",
          overflowX: "hidden",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgb(0,0,0,.6)",
        },
      }}
    >
      {/* Modal Header */}
      <div className="flex justify-between items-center bg-primary w-100">
        <h3 className="f-6 mb-0 textPrimary pl-4 fwb usn">
          <CameraFilled /> ID Verification
        </h3>
        <Button
          type="primary"
          className="lift f-6 br-0 pl-3 pr-4 pb-0 h-auto"
          onClick={handleExit}
        >
          X
        </Button>
      </div>
      {/* Modal Content - Vouched */}
      <div className="w-100 h-100">
        {/* This div is targeted by the vouched script. */}
        <div className="h-100 mh-auto" id="vouched-element" ref={idvRef}>
          {setTimeout(
            () =>
              idvRef.current &&
              vouchedOnReady({
                setVerificationResult,
                brand,
                deviceHandoff,
                application,
                config,
              }),
            100
          ) && <></>}
        </div>
      </div>
    </Modal>
  );
}
