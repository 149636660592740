import {
  CustomFieldConfig,
  ResponseFields,
} from "@dreambigger/shared/src/types";
import { FormInstance } from "antd";
import {
  hasEmptyRequiredFields,
  isInvalidAutoComplete,
  isInvalidPhoneNumber,
  isInvalidMaskedInput,
  isInvalidSsn,
} from "./customFieldDisableChecks";
import { Dispatch, SetStateAction } from "react";

export interface CustomFieldArgs {
  values?: ResponseFields;
  customFields?: CustomFieldConfig[];
  form: FormInstance<any>;
  allowRepeats?: boolean;
  setDisabled: Dispatch<SetStateAction<boolean>>;
}

export const checkDisabledOnCustomFields = ({
  values,
  customFields,
  form,
  allowRepeats,
  setDisabled,
}: CustomFieldArgs) => {
  if (!customFields) {
    return;
  }
  // Check antd's validator log to see if there are any active error/warning messages displayed to the user.
  const errorMonitoring = form.getFieldsError();
  for (const possibleError of errorMonitoring) {
    if (possibleError.errors[0] || possibleError.warnings[0]) {
      // Confirm that there is still an error. If so, disable the button.
      form.validateFields([possibleError.name]);
      setTimeout(() => {
        const isThereStillAnError =
          form.getFieldError(possibleError.name).length > 0;
        setDisabled(isThereStillAnError);
        return;
      }, 40);
    }
  }

  // If there are no values, check if there are any required fields.
  if (!values) {
    const areThereNoRequiredFields = customFields.some(
      (field) => !!field.fieldRequired
    );
    setDisabled(areThereNoRequiredFields);
    return;
  }
  // Compare the values provided with the rules defined in each field's configuration.
  for (let valueName in values) {
    // If allowedRepeats is enabled, modify the name so it can be compared to the fieldKey.
    const lookupName = allowRepeats
      ? valueName.replace(/__\d+$/, "")
      : valueName;
    for (const field of customFields) {
      if (field.fieldKey === lookupName) {
        // Check if field is required empty.
        if (
          hasEmptyRequiredFields(field, values, valueName) ||
          isInvalidAutoComplete(field, values, valueName) ||
          isInvalidPhoneNumber(field, values, valueName) ||
          isInvalidMaskedInput(field, values, valueName) ||
          isInvalidSsn(field, values, valueName)
        ) {
          setDisabled(true);
          return;
        }
      }
    }
  }
  setDisabled(false);
};
