import { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";
import { AutoComplete, Input, Form, Select } from "@dreambigger/design-system";
import { default as NhtsaApi } from "@dreambigger/shared/src/api/external/nhtsa";
import { DefaultOptionType } from "antd/lib/select";
import { noTryAsync } from "no-try";

// Set the maxCount to one so user's can't spam click message alerts.
message.config({ maxCount: 1 });

// Initialize the NHTSA API
const nhtsaApi = new NhtsaApi();

// The car makes we will support in the dropdown
const carMakes = [
  "AMC",
  "ACURA",
  "ALFA ROMEO",
  "ASTON MARTIN",
  "AUDI",
  "BMW",
  "BENTLEY",
  "BUGATTI",
  "BUICK",
  "CADILLAC",
  "CHEVROLET",
  "CHRYSLER",
  "DAEWOO",
  "DATSUN",
  "DELOREAN",
  "DODGE",
  "EAGLE",
  "FIAT",
  "FERRARI",
  "FISKER",
  "FORD",
  "GMC",
  "GENESIS",
  "GEO",
  "HUMMER",
  "HONDA",
  "HYUNDAI",
  "INFINITI",
  "ISUZU",
  "JAGUAR",
  "JEEP",
  "KARMA",
  "KIA",
  "LAMBORGHINI",
  "LAND ROVER",
  "LEXUS",
  "LINCOLN",
  "LOTUS",
  "LUCID",
  "MAZDA",
  "MINI",
  "MASERATI",
  "MAYBACH",
  "MCLAREN",
  "MERCEDES-BENZ",
  "MERCURY",
  "MITSUBISHI",
  "NISSAN",
  "OLDSMOBILE",
  "PLYMOUTH",
  "POLESTAR",
  "PONTIAC",
  "PORSCHE",
  "RAM",
  "RIVIAN",
  "ROLLS ROYCE",
  "SRT",
  "SAAB",
  "SATURN",
  "SCION",
  "SMART",
  "SUBARU",
  "SUZUKI",
  "TESLA",
  "TOYOTA",
  "VOLKSWAGEN",
  "VOLVO",
  "YUGO",
];

/**
 * Retrieve the year range we want to display in the "year" dropdown
 * Example: If current year is 2022
 *  - upperBound would be 2023 (currentYear + 1)
 *  - lowerBound would be 2003 (upperBound - 20)
 *
 * Values to display would be all years from 2003 to 2023.
 */
const getYears = () => {
  const currentYear = new Date().getFullYear();
  const upperBound = currentYear + 1;
  const lowerBound = upperBound - 20;
  return Array.from({ length: upperBound - lowerBound + 1 }, (_, index) => {
    return {
      label: String(index + lowerBound),
      value: String(index + lowerBound),
    };
  });
};

// The keys for items in our form.
const FORM_KEYS = {
  year: "year",
  make: "make",
  model: "model",
  vin: "vin",
};

export default function Car({ flow, step, brand, progress }: StepProps) {
  const [form] = Form.useForm();

  // State for the next button
  const [disabled, setDisabled] = useState(true);

  // State to keep track of current models to display based on make.
  const [models, setModels] = useState<DefaultOptionType[]>([]);

  // State to keep track of if there is a vin error
  const [vinError, setVinError] = useState(false);

  // Clear model dropdown + state.
  const clearModels = () => {
    form.setFieldsValue({ model: null });
    setModels([]);
  };

  // Clear model state.
  const resetModelsState = () => {
    if (models.length > 0) {
      setModels([]);
    }
  };

  // Retrieve models for the make and year selected in the dropdown.
  const getModels = async () => {
    const { make, year } = form.getFieldsValue(true);

    // If make + year are present, get the models.
    if (make && year) {
      // Get car models for vehicles of type "car", "truck" and "mpv (Multi passenger vehicle)"
      const [carsError, carsResponse] = await noTryAsync(() =>
        Promise.all([
          nhtsaApi.getModelsForMakeByYearAndVehicleType({
            make,
            year,
            vehicleType: "car",
          }),
          nhtsaApi.getModelsForMakeByYearAndVehicleType({
            make,
            year,
            vehicleType: "truck",
          }),
          nhtsaApi.getModelsForMakeByYearAndVehicleType({
            make,
            year,
            vehicleType: "mpv",
          }),
        ])
      );

      if (carsError) {
        console.error("getModels::Error", carsError);
        clearModels();
        return;
      }

      const vehicles = carsResponse
        ? [
            ...carsResponse[0].data.Results,
            ...carsResponse[1].data.Results,
            ...carsResponse[2].data.Results,
          ]
        : [];

      if (vehicles.length > 0) {
        const { model } = form.getFieldsValue(true);

        // Clear the current model input if it doesn't exist in the model results array.
        if (
          model &&
          !vehicles.map((model) => model.Model_Name).includes(model)
        ) {
          form.setFieldsValue({ model: null });
        }

        setModels(
          vehicles
            .map((model) => {
              return {
                label: `${model.Model_Name}`,
                value: `${model.Model_Name}`,
              };
            })
            .sort((modelOne, modelTwo) =>
              modelOne.value < modelTwo.value ? -1 : modelOne > modelTwo ? 1 : 0
            )
        );
      } else {
        message.info("No models found for this make and year.");
        clearModels();
      }
    }

    validateFields();
  };

  // Populate models on page load if values are present in the form already.
  useEffect(() => {
    const { make, year } = form.getFieldsValue(true);

    if (make && year) {
      getModels();
    }
  }, []);

  // Used to check if all required fields are filled in.
  const validateFields = () => {
    // Store entered vin # (if applicable)
    const vinField = form.getFieldsValue(["vin"]);
    const vin = vinField.vin;

    // If there was an existing vin error but the vin field has now been cleared, clear the error (it is an optional input)
    if (vinError && !vin) {
      setVinError(false);
    }

    // if there is still an existing vin error, immediately disable submit button and return.
    if (vinError) {
      setDisabled(true);
      return;
    }

    // If vin has been entered, ensure that it is 17 characters.
    if (vin && vin.length > 0 && vin.length < 17) {
      setDisabled(true);
      return;
    }

    // Next, check that ALL 3 other field values are filled in.
    const values = form.getFieldsValue(["make", "year", "model"]);

    // Disable button if all values are not filled in.
    setDisabled(!Object.values(values).every(Boolean));
  };

  // Make sure all required fields are filled in before allowing the user to move on.
  const handleInput = () => validateFields();

  // Get car's model, make, and year via VIN.
  const decodeVin = async () => {
    const { vin } = form.getFieldsValue(true);

    if (vin && vin.length === 17) {
      const [error, vinResponse] = await noTryAsync(() =>
        nhtsaApi.decodeVin(vin)
      );

      if (error) {
        console.error("decodeVin::Error", error);
        return;
      }

      // Check that a model was accurately returned. If not, an incorrect vin was entered.
      const modelResponse = vinResponse?.data.Results.find(
        (result) => result.Variable === "Model"
      )?.Value;

      if (!modelResponse) {
        // Flag error and return
        setDisabled(true);
        setVinError(true);
        return;
      }

      // Update form fields with the response from the API (including the modelResponse already found above).
      const updatedValues = {
        [FORM_KEYS.make]: vinResponse.data.Results.find(
          (result) => result.Variable === "Make"
        )?.Value,
        [FORM_KEYS.model]: modelResponse,
        [FORM_KEYS.year]: vinResponse.data.Results.find(
          (result) => result.Variable === "Model Year"
        )?.Value,
      };

      // Auto fill the fields based on the response from the VIN Api.
      form.setFieldsValue(updatedValues);

      // Clear vin errors (if applicable)
      setVinError(false);

      // Populate the models dropdown if possible.
      getModels();
    }

    validateFields();
  };

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col xs={24} md={16}>
          <span>
            <label>
              <span>
                VIN
                <span className="fsi f-2 ml-3">
                  Optional - Autofills remaining fields
                </span>
              </span>
            </label>
            {vinError && (
              <small className="warning fsi db">
                No vehicle found with that VIN. Please try again or delete and
                fill in remaining fields manually.
              </small>
            )}
            <Form.Item name={FORM_KEYS.vin}>
              <Input
                maxLength={17}
                placeholder={"Enter your car's VIN"}
                onChange={decodeVin}
                className="ao-bl-input"
              />
            </Form.Item>
          </span>

          <span>
            <label>Year</label>
            <Form.Item name={FORM_KEYS.year}>
              <Select
                options={getYears()}
                placeholder="Please select/enter your car's year"
                onSelect={getModels}
                onChange={resetModelsState}
                className="ao-bl-select"
              />
            </Form.Item>
          </span>

          <span>
            <label>Make</label>
            <Form.Item name={FORM_KEYS.make}>
              <AutoComplete
                options={carMakes.map((car) => {
                  return { label: `${car}`, value: `${car}` };
                })}
                placeholder="Please select/enter your car's make"
                onSelect={getModels}
                onChange={resetModelsState}
                className="ao-bl-select"
              />
            </Form.Item>
          </span>

          <span>
            <label>Model</label>
            <Form.Item name={FORM_KEYS.model}>
              <AutoComplete
                options={models}
                placeholder="Please select/enter your car's model"
                className="ao-bl-select"
              />
            </Form.Item>
          </span>
        </Col>
      </Row>
    </StepWrapper>
  );
}
