import { useCallback } from "react";
import { message } from "antd";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Form } from "@dreambigger/design-system/src/components";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { IntentType } from "../../types";
export interface FormWrapperProps {
  children: React.ReactNode;
  onValuesChange: (changedValues: any, values: any) => void;
  returnUrl: string;
  intentType: IntentType;
}

export default function FormWrapper({
  children,
  onValuesChange,
  returnUrl,
  intentType,
}: FormWrapperProps) {
  const [paymentForm] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const segment = useSegment();

  const selectStripeHanlder = useCallback(() => {
    if (!stripe) return;
    switch (intentType) {
      case "payment":
        return stripe.confirmPayment;
      case "setup":
        return stripe.confirmSetup;
    }
  }, [stripe, intentType]);

  const handleSubmit = useCallback(() => {
    segment.track({ action: "Button Click", label: "Pay" });
    const handlePayment = selectStripeHanlder();

    // Stripe.js has not yet loaded.
    if (!stripe || !elements || !handlePayment) {
      message.error(
        "Payment gateway still loading. Please try again after a few minutes."
      );
      return;
    }

    handlePayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    })
      .then((result) => {
        if (result?.error) {
          message.error(result.error.message);
          elements.getElement("payment")?.focus();
        }
      })
      .catch(() => message.error("Error processing payment"));
  }, [stripe, elements, returnUrl, segment]);

  return (
    <Form
      form={paymentForm}
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
    >
      {children}
    </Form>
  );
}
