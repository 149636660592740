import { useEffect, useMemo, useState, useCallback } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import {
  Stripe,
  loadStripe,
  VerificationSessionResult,
} from "@stripe/stripe-js";
import { Spin } from "@dreambigger/design-system/src/components";
import { message } from "antd";
import { ArrowRightOutlined, CheckCircleFilled } from "@ant-design/icons";
import * as Sentry from "@sentry/nextjs";
import { useStripe } from "../../api";

interface StripeIdentityFormItemProps {
  value?: string;
  onChange?: (value?: string) => void;
  publicKey?: string;
  financialInstitutionUuid: string;
  configurationUuid?: string;
  entityUuid?: string;
  entityType?: string;
  ctaText?: string;
  successText?: string;
}

const spinnerIcon = <Loading3QuartersOutlined spin />;

const StripeIdentityFormItem = ({
  value,
  onChange,
  publicKey,
  financialInstitutionUuid,
  configurationUuid,
  entityUuid,
  entityType,
  ctaText,
  successText,
}: StripeIdentityFormItemProps) => {
  const stripeHelper = useStripe(financialInstitutionUuid);

  const stripePromise = useMemo(
    () => loadStripe(publicKey || process.env.NEXT_PUBLIC_STRIPE_KEY || ""),
    [publicKey]
  );

  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    stripePromise.then(setStripe);
  }, [stripePromise, setStripe]);

  const [verificationSessionId, setVerificationSessionId] =
    useState<string | undefined>(value);

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(verificationSessionId);
    }
  }, [verificationSessionId]);

  const handleClick = useCallback(
    async (event: any) => {
      // Block native event handling.
      event.preventDefault();

      if (!configurationUuid) {
        message.error("IDV configuration missing");
        return;
      }

      if (!stripe) {
        // Stripe.js has not loaded yet. Make sure to disable
        // the button until Stripe.js has loaded.
        return;
      }

      const {
        data: { id, clientSecret },
      } = await stripeHelper.createIdentityVerificationSession(
        configurationUuid,
        entityUuid,
        entityType
      );

      // Show the verification modal.
      const { error }: VerificationSessionResult = await stripe.verifyIdentity(
        clientSecret
      );

      if (error) {
        Sentry.captureException(error);
        setVerificationSessionId(undefined);
      } else {
        setVerificationSessionId(id);
      }
    },
    [stripe]
  );

  return stripe ? (
    <div className="flex items-center justify-center">
      {verificationSessionId ? (
        <div
          className={`flex items-center primary fwb f-3`}
          style={{ borderBottom: "solid transparent 1px" }}
        >
          <p className="mv-auto mr-2 tc ttu">{successText || "Submitted"}</p>
          <CheckCircleFilled />
        </div>
      ) : (
        <a onClick={handleClick}>
          <div
            className={`flex items-center primary fwb f-3 borderUnderline`}
            style={{ borderBottom: "solid transparent 1px" }}
          >
            <p className="mv-auto mr-2 tc ttu">{ctaText || "Verify Me"}</p>
            <ArrowRightOutlined />
          </div>
        </a>
      )}
    </div>
  ) : (
    <div>
      <Spin indicator={spinnerIcon} />
    </div>
  );
};

export default StripeIdentityFormItem;
