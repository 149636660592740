import { useState, useEffect, useCallback, useMemo } from "react";
import { Collapse } from "antd";
import {
  CategoryFilter,
  CategoryValidation,
  ResponseFields,
  ShareCategory,
  ShareProduct,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import { message, Form } from "@dreambigger/design-system";
import StepWrapper from "./step-wrapper";
import { ShareProductsStepAssets } from "@dreambigger/shared/src/types";
import { ProductCheckbox } from "../components";
import { api } from "@dreambigger/shared/src/api/acquire";
import "@dreambigger/design-system";
import ShareApi from "../api/shares";
import { useApplication } from "../api";
import { Conditional } from "@dreambigger/shared/src/utils";

// Initialize the various api's we need for this step.
const shareApi = new ShareApi(api);

export default function StepProducts({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const assets: ShareProductsStepAssets = step.assets;
  const { radioSelection, categoryValidation } = assets;

  // State for managing whether the submit button is enabled.
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [pCategoryData, setPCategoryData] = useState<ShareCategory[]>([]);
  const [activePanelKey, setActivePanelKey] = useState<string[] | string>([]);
  const [resetAllExcept, setResetAllExcept] = useState("");
  const [categoryCounts, setCategoryCounts] = useState<Record<string, number>>(
    {}
  );
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  // Reset the category counts when the step/flow changes.
  useEffect(() => {
    setCategoryCounts({});
  }, [step, flow]);

  // When using category validation, disable the next button until all minimums have been satisfied.
  useEffect(() => {
    if (!categoryValidation) return;
    checkSubmitDisabled();
  }, [categoryCounts]);

  // Set Products to be initially opened or closed. This can be defined in the config at the specific category level or across the whole step.
  useEffect(() => {
    const initialActivePanelKeys = pCategoryData
      .filter((category) => {
        // For each category, look up the corresponding validation in the step assets if it exists.
        const correspondingValidation = categoryValidation?.find(
          (validation) => validation.slug === category.slug
        );
        // if collapse behavior is set at the category level, use that
        if (
          correspondingValidation?.setCollapse !== undefined &&
          [true, false].includes(correspondingValidation.setCollapse)
        ) {
          return !correspondingValidation.setCollapse;
        }
        // if collapse behavior is set at the asset level, use that
        if (
          assets?.setCollapse !== undefined &&
          [true, false].includes(assets.setCollapse)
        ) {
          return !assets.setCollapse;
        }
        // by default, stay open
        return true;
      })
      .map((category) => category.uuid);

    setActivePanelKey(initialActivePanelKeys);
  }, [assets.setCollapse, pCategoryData, categoryValidation]);

  const [form] = Form.useForm();

  // Load products using API
  useEffect(() => {
    if (!application) return;
    setProcessing(true);
    shareApi
      .getProductsByCategory(flow.financialInstitution.id, application.id)
      .then(({ data }) => {
        const sortedData = data.map((pCategory: ShareCategory) => ({
          ...pCategory,
          products: pCategory.products?.sort(
            (a: ShareProduct, b: ShareProduct) =>
              a.title.localeCompare(b.title, undefined, { numeric: true })
          ),
        }));
        setProcessing(false);
        setPCategoryData(sortedData);
      })
      .catch((err) => {
        console.error(err);
        message.error(
          "There was a problem loading this page. Please contact support."
        );
      });
  }, [flow, step, application]);

  // Show or hide panel when clicked
  function handlePanelChange(keys: string | string[]) {
    setActivePanelKey(keys);
  }

  const handleProductChange = (
    value: boolean,
    productSlug: string,
    categorySlug: string
  ) => {
    if (value === false) {
      setCategoryCounts((prevCounts) => ({
        ...prevCounts,
        [categorySlug]: (prevCounts[categorySlug] || 1) - 1,
      }));
    } else if (form.getFieldValue(productSlug)) {
      // Update the category count
      setCategoryCounts((prevCounts) => ({
        ...prevCounts,
        [categorySlug]: (prevCounts[categorySlug] || 0) + 1,
      }));
    }
    // If category validation is enabled, end the function here and call disable check
    // from useEffect in next render cycle. Otherwise, check now.
    if (categoryValidation) {
      return;
    }
    checkSubmitDisabled();
  };

  const checkSubmitDisabled = () => {
    // Check that at least one product is selected
    const values = form.getFieldsValue();
    const someSelected = Object.values(values).some((v) => v === true);
    if (!someSelected) {
      setDisabled(true);
      return;
    }

    // Additionally, if categoryValidation is enabled, check that all minimums have been satisfied.
    if (categoryValidation) {
      for (const category of categoryValidation) {
        if (!category.slug || !category.min) continue;
        if (categoryCounts[category.slug] < category.min) {
          setDisabled(true);
          return;
        }
      }
    }
    setDisabled(false);
    return;
  };

  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    checkSubmitDisabled();

    return initialValues;
  }, []);

  // If Category Validation has been added to the config for this, check if the category should be filtered out.
  const isValidCategory = (categoryName: string) => {
    if (categoryValidation) {
      return categoryValidation.some((category) =>
        category.slug?.includes(categoryName)
      );
    }
    return true;
  };

  // If maximum number of products is set, only allow currently checked products to be unchecked.
  const preventMoreSelections = (productSlug: string, categorySlug: string) => {
    // We never need to prevent additional selections if there is no category validation.
    if (!categoryValidation) {
      return false;
    }
    // Search array for selected category
    const category = categoryValidation.find((searchedCategory) =>
      searchedCategory.slug?.includes(categorySlug)
    );
    // Guard clause for if category is not found
    if (!category) {
      return false;
    }
    // Prevent button from being clicked if the max number of products has been selected
    // and the product is not already selected.
    if (
      categoryCounts[categorySlug] === category.max &&
      form.getFieldValue(productSlug) === false
    ) {
      return true;
    }
    // Otherwise, allow the button to be clicked.
    return false;
  };

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handlePrefill,
    handleInput: () => {}, // hack to fix prefill on refresh
    disabled,
    processing,
  };

  // ----- FINAL RENDER ------

  return (
    <StepWrapper {...wrapperProps}>
      <Collapse
        bordered={false}
        activeKey={activePanelKey}
        ghost
        onChange={handlePanelChange}
      >
        {pCategoryData &&
          pCategoryData
            .filter((pCategory: ShareCategory) => {
              const filter = assets.categoryValidation?.find(
                (category: CategoryValidation) =>
                  category.slug === pCategory.slug
              )?.filter;
              if (!filter?.length) {
                return true;
              }
              // Filter by AND conditions - set up by an array of arrays in config.
              if (Array.isArray(filter[0])) {
                return filter[0].every(({ slug, fieldKey, fieldValue }) => {
                  const savedResponse = application?.responses.find(
                    (response) => response.slug === slug
                  );
                  const fields = savedResponse?.fields || {};

                  return Conditional.evaluate({
                    conditions: { [fieldKey]: fieldValue },
                    data: fields,
                  });
                });
              } else {
                // Filter by OR conditions - set up by a single array of objects.
                return (filter as CategoryFilter[]).some(
                  ({ slug, fieldKey, fieldValue }) => {
                    const savedResponse = application?.responses.find(
                      (response) => response.slug === slug
                    );
                    const fields = savedResponse?.fields || {};

                    return Conditional.evaluate({
                      conditions: { [fieldKey]: fieldValue },
                      data: fields,
                    });
                  }
                );
              }
            })
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((pCategory: ShareCategory) => {
              // Guard against empty product categories (no products)
              if (
                !pCategory.products ||
                pCategory.products.length === 0 ||
                !isValidCategory(pCategory.slug)
              ) {
                return null;
              }
              return (
                <>
                  <Collapse.Panel
                    key={pCategory.uuid}
                    header={
                      <h3 className="f-4 fwt fwb f-4 ttu textPrimary bg-primary w-100 pl-2 br-2 s-3 mb-0">
                        {pCategory.title}{" "}
                      </h3>
                    }
                    className="ao-collapse-panel"
                    forceRender
                  >
                    <div className="mb-2">
                      {pCategory.products &&
                        pCategory.products
                          .sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((product: ShareProduct) => (
                            <div key={product.uuid}>
                              <ProductCheckbox
                                key={product.title}
                                product={product}
                                accentColor={brand.primaryColor}
                                form={form}
                                onChange={(newValue) =>
                                  handleProductChange(
                                    newValue,
                                    product.slug,
                                    pCategory.slug
                                  )
                                }
                                radioSelection={radioSelection}
                                setResetAllExcept={setResetAllExcept}
                                resetAllExcept={resetAllExcept}
                                disabled={preventMoreSelections(
                                  product.slug,
                                  pCategory.slug
                                )}
                              />
                            </div>
                          ))}
                    </div>
                  </Collapse.Panel>
                </>
              );
            })}
      </Collapse>
    </StepWrapper>
  );
}
