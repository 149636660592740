import { useMemo } from "react";
import { Row, Col } from "antd";
import { Form } from "@dreambigger/design-system/src/components";
import {
  Step,
  ResponseStep,
  ReviewStepAssets,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { useRouter } from "next/router";
import { useApplication } from "../api";
import { StepReviewCard } from "../components";

export default function Review({ step, brand, flow, progress }: StepProps) {
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  const disabled = false;
  const [form] = Form.useForm();
  const router = useRouter();
  // Track analytics with Segment
  const segment = useSegment();
  const { flowId, stepSlug } = router.query;

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
  };

  const steps = flow.skins[0].steps;

  const assets: ReviewStepAssets = step.assets;

  // List of filtered keys for use in the filterKey function below.
  // const keyFilterList = ["lastName", "firstName"];
  const keyFilterList: string[] = assets?.keyFilterList || [];

  // If there is no application passed into this step, return an error.
  if (!application) {
    return (
      <>
        There was an error loading your application summary. Please contact
        support.
      </>
    );
  }

  const handleBack = () => {
    router.push({
      query: { 
        ...router.query,
        flowId, 
        stepSlug: step.assets.previousSlug 
      },
    });
    segment.track({
      action: "Button Click",
      label: `Back - ${stepSlug as string}`,
    });
  };

  const searchForResponse = (responses: any, slug: string) => {
    for (var i = 0; i < responses.length; i++) {
      if (responses[i].slug === slug) {
        return responses[i];
      }
    }
    return null;
  };

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col className="w-100">
          {/* STEP HEADERS displayed over primary color background - Start, Name, Etc */}
          {steps.map((step: Step, i: any) => {
            let response: ResponseStep = searchForResponse(
              application.responses,
              step.slug
            );

            // Add the application ID as the first item of the start step for the review screen.
            if (step.slug === "start") {
              response.fields = {
                applicationId: application.id,
                ...response.fields,
              };
            }
            return (
              <StepReviewCard
                key={step.slug}
                flowId={flow.id}
                step={step}
                response={response}
                i={i}
                keyFilterList={keyFilterList}
              />
            );
          })}
        </Col>
        {/* CTA BOX - "Ready to submit?" Asks user if he/she wants to edit responses. */}
        {!assets.hideReadyText && (
          <Col className="flex flex-column bt b-lightPrimary w-100 pt-6 mt-4">
            <span className="f-5 fwt">Ready to submit?</span>
            <span>
              If you need to make changes, use the{" "}
              <span
                className="br-1 pa-1 darkenPrimaryBkd textPrimary pointer "
                style={{ backgroundColor: "#a2a2a2" }}
                onClick={handleBack}
              >
                <ArrowLeftOutlined /> back button
              </span>{" "}
              to return to previous steps.
            </span>
          </Col>
        )}
        {/* OPTIONAL DISCLAIMER TEXT - "By clicking submit..." */}
        {(assets.disclaimerTitle || assets.disclaimerText) && (
          <Col className="mt-4">
            {assets.disclaimerTitle && (
              <h5
                className="fwt"
                dangerouslySetInnerHTML={{
                  __html: assets.disclaimerTitle,
                }}
              />
            )}
            {assets.disclaimerText && (
              <p
                className="mb-0 f-2 lh-1 pl-2 bl"
                style={{ borderColor: "lightgray" }}
                dangerouslySetInnerHTML={{
                  __html: assets.disclaimerText,
                }}
              />
            )}
          </Col>
        )}
      </Row>
    </StepWrapper>
  );
}
