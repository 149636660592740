export const defaultStatesAbbreviated = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const defaultStates = [
  {
    label: "Alaska",
    value: "Alaska",
  },
  {
    label: "Alabama",
    value: "Alabama",
  },
  {
    label: "Arkansas",
    value: "Arkansas",
  },
  {
    label: "Arizona",
    value: "Arizona",
  },
  {
    label: "California",
    value: "California",
  },
  {
    label: "Colorado",
    value: "Colorado",
  },
  {
    label: "Connecticut",
    value: "Connecticut",
  },
  {
    label: "Dist. Of Columbia",
    value: "Dist. Of Columbia",
  },
  {
    label: "Delaware",
    value: "Delaware",
  },
  {
    label: "Florida",
    value: "Florida",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Hawaii",
    value: "Hawaii",
  },
  {
    label: "Iowa",
    value: "Iowa",
  },
  {
    label: "Idaho",
    value: "Idaho",
  },
  {
    label: "Illinois",
    value: "Illinois",
  },
  {
    label: "Indiana",
    value: "Indiana",
  },
  {
    label: "Kansas",
    value: "Kansas",
  },
  {
    label: "Kentucky",
    value: "Kentucky",
  },
  {
    label: "Louisiana",
    value: "Louisiana",
  },
  {
    label: "Massachusetts",
    value: "Massachusetts",
  },
  {
    label: "Maryland",
    value: "Maryland",
  },
  {
    label: "Maine",
    value: "Maine",
  },
  {
    label: "Michigan",
    value: "Michigan",
  },
  {
    label: "Minnesota",
    value: "Minnesota",
  },
  {
    label: "Missouri",
    value: "Missouri",
  },
  {
    label: "Mississippi",
    value: "Mississippi",
  },
  {
    label: "Montana",
    value: "Montana",
  },
  {
    label: "North Carolina",
    value: "North Carolina",
  },
  {
    label: "North Dakota",
    value: "North Dakota",
  },
  {
    label: "Nebraska",
    value: "Nebraska",
  },
  {
    label: "New Hampshire",
    value: "New Hampshire",
  },
  {
    label: "New Jersey",
    value: "New Jersey",
  },
  {
    label: "New Mexico",
    value: "New Mexico",
  },
  {
    label: "Nevada",
    value: "Nevada",
  },
  {
    label: "New York",
    value: "New York",
  },
  {
    label: "Ohio",
    value: "Ohio",
  },
  {
    label: "Oklahoma",
    value: "Oklahoma",
  },
  {
    label: "Oregon",
    value: "Oregon",
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania",
  },
  {
    label: "Rhode Island",
    value: "Rhode Island",
  },
  {
    label: "South Carolina",
    value: "South Carolina",
  },
  {
    label: "South Dakota",
    value: "South Dakota",
  },
  {
    label: "Tennessee",
    value: "Tennessee",
  },
  {
    label: "Texas",
    value: "Texas",
  },
  {
    label: "Utah",
    value: "Utah",
  },
  {
    label: "Virginia",
    value: "Virginia",
  },
  {
    label: "Vermont",
    value: "Vermont",
  },
  {
    label: "Washington",
    value: "Washington",
  },
  {
    label: "Wisconsin",
    value: "Wisconsin",
  },
  {
    label: "West Virginia",
    value: "West Virginia",
  },
  {
    label: "Wyoming",
    value: "Wyoming",
  },
];
