import { Divider } from "antd";
import { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import { CSSTransition } from "react-transition-group";
import { Button } from "@dreambigger/design-system/src/components";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ModalProduct } from "@dreambigger/shared/src/types";

export interface ProductModalProps {
  products?: ModalProduct[];
  modalTitle?: string;
  modalSubtitle?: string;
  accentColor?: string;
  loadDelay?: number;
}

export default function ProductsSliderModal({
  products = [],
  modalTitle,
  modalSubtitle,
  accentColor,
  loadDelay = 0,
}: ProductModalProps) {
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [showModal, setShowModal] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const ctaButtonClasses = "f-5 h-7 md_f-6 md_h-8 s-2 lift";

  // Only pass products into this modal that contain ALL necessary configurations.
  const filteredProducts = useMemo(
    () =>
      products.filter((product) => {
        return (
          product.title &&
          product.subtitle &&
          product.content &&
          product.imageUrl &&
          product.url
        );
      }),
    [products]
  );

  // Initial delay before component loads
  useEffect(() => {
    setTimeout(() => setIsReady(true), loadDelay);
  }, []);

  // Store the active filtered product and destructure
  const product = filteredProducts[currentProductIndex];
  const { url, title, subtitle, content, imageUrl } = product;

  // Click through the products, looping around when necessary.
  const handlePrevClick = () => {
    setCurrentProductIndex(
      currentProductIndex === 0 ? products.length - 1 : currentProductIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentProductIndex(
      currentProductIndex === products.length - 1 ? 0 : currentProductIndex + 1
    );
  };

  // Guard againts no products passed in.
  if (!products) return <></>;

  // If modal is not yet ready (becauase of loadDelay prop)
  // or there are no correctly configured products to pass in, render nothing.
  return !isReady || filteredProducts.length === 0 ? (
    <></>
  ) : (
    <Modal
      isOpen={showModal}
      style={{
        content: {
          backgroundColor: "white",
          borderRadius: "36px",
          margin: "12vh 4vw",
          border: "solid 3px " + accentColor,
          padding: "0px",
          display: "flex",
          alignItems: "stretch",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgb(0,0,0,.6)",
        },
      }}
    >
      {/* Image Left Column - Only show on non-mobile */}
      <div
        className="dn md_db w-50"
        style={{
          background: `linear-gradient(to right, ${accentColor} 0%, ${accentColor} 60%, transparent 100%)`,
        }}
      >
        <a href={url}>
          <img
            src={imageUrl}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </a>
      </div>
      {/* Text Right Column - Full-width on non-mobile */}
      <div className="lg_w-50 pa-5">
        <div className="h-70" style={{ maxHeight: "70%", overflow: "scroll" }}>
          {/* Modal Header */}
          <div className="mb-6">
            <h1
              style={{ color: accentColor }}
              className="f-6 sm_f-7 xxl_f-8 lh-1 fwt mb-1"
            >
              {modalTitle}
            </h1>
            <h2 className="mb-0 fsi f-4 xxl_f-5">{modalSubtitle}</h2>
          </div>
          {/* Featured Product */}
          <CSSTransition in={true} timeout={2000} classNames="fade">
            <div style={{ flex: 1 }}>
              {/* Title */}
              <h1
                style={{ color: accentColor }}
                className="lh-1 f-8 xl_f-9 xxl_f-10 fwt mb-0"
              >
                {title}
              </h1>
              {/* Subtitle */}
              <h2 className="mb-0 f-5 xl_f-6 xxl_f-9 fwb lh-2">{subtitle}</h2>
              {/* Content */}
              <p className="mb-0 f-4 xl_f-5 xxl_f-7 mt-4 lh-2">{content}</p>
            </div>
          </CSSTransition>
        </div>
        {/* CTA - Navigation Buttons and Thumbnails*/}
        <div className="flex mt-4 sm_mt-0">
          <Button
            type="primary"
            onClick={() => setShowModal(false)}
            className={ctaButtonClasses}
          >
            Maybe Later
          </Button>
          <a href={url}>
            <Button type="primary" className={`${ctaButtonClasses} ml-2`}>
              Let&apos;s go!
            </Button>
          </a>
        </div>
        <Divider className="mv-4" />
        <div className="flex">
          <Button
            type="primary"
            onClick={handlePrevClick}
            className="self-center mr-2 br-100 ph-1 pv-1 h-6 w-6 s-2 lift"
          >
            <LeftOutlined />
          </Button>
          <Button
            type="primary"
            onClick={handleNextClick}
            className="self-center br-100 ph-1 pv-1 h-6 w-6 s-2 lift"
          >
            <RightOutlined />
          </Button>
          <p
            style={{ color: accentColor }}
            className="self-center ml-4 mb-0 fwb f-5"
          >
            View More Products
          </p>
        </div>
        {/* CTA - Thumbnails (display a filled box for active product, empty box for rest)*/}
        <div style={{ display: "flex", marginTop: "16px" }}>
          {products.map((_product, index) => (
            <div
              key={index}
              onClick={() => setCurrentProductIndex(index)}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "4px",
                border: "1px solid" + accentColor,
                backgroundColor:
                  index === currentProductIndex ? accentColor : "transparent",
                cursor: "pointer",
              }}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}
