// HTML Step
// * This step is used to render custom HTML content in the flow using dangerousHTML passed in from the config.
// * It does not save any data to the application and the next button is always enabled.

import React from "react";
import { Row, Col } from "antd";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";
import { HTMLStepAssets } from "@dreambigger/shared/src/types";
import { Form } from "@dreambigger/design-system";

export default function StepHTML({ flow, step, brand, progress }: StepProps) {
  const assets: HTMLStepAssets = step.assets;
  const [form] = Form.useForm();
  const disabled = false; // Disabled is also false for HTML steps

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
  };
  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        {/* Render Custom HTML */}
        {assets.html && (
          <Col xs={24} dangerouslySetInnerHTML={{ __html: assets.html }} />
        )}
        {/* OPTIONAL DISCLAIMER TEXT - "By clicking submit..." */}
        {(assets.disclaimerTitle || assets.disclaimerText) && (
          <Col className="mt-4">
            {assets.disclaimerTitle && (
              <h3
                className="fwt"
                dangerouslySetInnerHTML={{
                  __html: assets.disclaimerTitle,
                }}
              />
            )}
            {assets.disclaimerText && (
              <p
                className="f-4 gray-8 mb-0 lh-3"
                dangerouslySetInnerHTML={{
                  __html: assets.disclaimerText,
                }}
              />
            )}
          </Col>
        )}
      </Row>
    </StepWrapper>
  );
}
