import { ShareProduct, ShareProductRate } from "@dreambigger/shared/src/types";

export const SLIDER_DEFAULT_MIN = 1;
export const SLIDER_DEFAULT_MAX = 100000;

export const calculateFloorAndCeiling = ({
  products,
}: {
  products: ShareProduct[];
}): {
  floor: number;
  ceiling: number;
} => {
  let floor: number | undefined = undefined;
  let rateMinimumBalance: number | undefined = undefined;
  let ceiling: number | undefined = undefined;

  // first, attempt to set the floor based on minimumOpeningDeposit
  products.forEach(({ minimumOpeningDeposit, rates }) => {
    if (minimumOpeningDeposit !== undefined && minimumOpeningDeposit !== null) {
      // set to the lowest minimum opening deposit
      floor =
        floor === undefined
          ? minimumOpeningDeposit
          : Math.min(floor, minimumOpeningDeposit);
    }
    rates?.forEach(({ minimumBalance, maximumBalance }) => {
      if (minimumBalance !== undefined && minimumBalance !== null) {
        // set to the lowest rate minimum balance
        rateMinimumBalance =
          rateMinimumBalance === undefined
            ? minimumBalance
            : Math.min(rateMinimumBalance, minimumBalance);
      }
      if (maximumBalance !== undefined && maximumBalance !== null) {
        // set to the highest rate maximum balance
        ceiling =
          ceiling === undefined
            ? maximumBalance
            : Math.max(ceiling, maximumBalance);
      }
    });
  });

  return {
    // if floor was set by the min. opening deposit, increase it if the rate min. is higher
    // if floor was not set by the min. opening deposit, set to the rate min. or 1
    floor: floor
      ? Math.max(floor, rateMinimumBalance || 0)
      : rateMinimumBalance || SLIDER_DEFAULT_MIN,
    ceiling: ceiling || SLIDER_DEFAULT_MAX,
  };
};

export const calculateRatesAndAmount = ({
  principal,
  product: {
    rates,
    maturityPeriod,
    maturityPeriodUnits = "months",
    compoundFrequency,
  },
}: {
  principal: number;
  product: ShareProduct;
}):
  | {
      interestRatePercent: number;
      apyPercent: number;
      termAmount: number;
    }
  | undefined => {
  if (!rates) {
    // missing rates
    return;
  }
  if (!maturityPeriod) {
    // missing maturity period
    return;
  }

  const compoundFrequencyDefaulted = compoundFrequency || 360; // default = daily

  // pick the rate schedule with the best rate given the principal
  const bestRateSchedule: ShareProductRate = rates.reduce(
    (previous, current) => {
      if (
        (current.minimumBalance === null ||
          current.minimumBalance === undefined ||
          principal >= current.minimumBalance) &&
        (current.maximumBalance === null ||
          current.maximumBalance === undefined ||
          principal <= current.maximumBalance)
      ) {
        return (current.interestRatePercent || 0) >=
          (previous.interestRatePercent || 0)
          ? current
          : previous;
      }
      return previous;
    },
    {
      uuid: "initial",
      interestRatePercent: 0,
    }
  );

  // no rate found
  if (bestRateSchedule.uuid === "initial") {
    return;
  }

  // the rate will not be zero based on the reducer above
  const interestRatePercent = bestRateSchedule.interestRatePercent || 0;

  // calculate the term amount
  const termAmount =
    principal *
    Math.pow(
      1 + interestRatePercent / 100 / compoundFrequencyDefaulted,
      compoundFrequencyDefaulted *
        (maturityPeriod / (maturityPeriodUnits === "days" ? 365 : 12))
    );

  // calculate the APY
  const apyPercent =
    bestRateSchedule.apyPercent ||
    (Math.pow(
      1 + interestRatePercent / 100 / compoundFrequencyDefaulted,
      compoundFrequencyDefaulted
    ) -
      1) *
      100;

  return {
    interestRatePercent,
    apyPercent,
    termAmount,
  };
};
