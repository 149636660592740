import { AxiosInstance } from "axios";
import { TokenHelper } from "../types";

const basePath = "/stripe";

// All the paths for this api domain.
export const stripePaths = {
  createPaymentIntent: () => `${basePath}/payment-intents`,
};

class StripeApi {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  // create a payment intent
  // POST /payment-intents
  createPaymentIntent = (
    paymentIntentConfigurationId: string | undefined,
    subscriptionConfigurationId: string | undefined,
    setupIntentConfigurationId: string | undefined,
    entityUuid: string,
    entityType: string,
    tokenHelper: TokenHelper,
    amount?: number,
    targetEntityId?: string,
    targetEntityType?: string,
    subscriptionTitle?: string,
    subscriptionDescription?: string
  ) => {
    const token = tokenHelper.getJwtToken();
    return this.api.post<{
      id: string;
      clientSecret: string;
    }>(
      stripePaths.createPaymentIntent(),
      {
        paymentIntentConfigurationId,
        subscriptionConfigurationId,
        setupIntentConfigurationId,
        entity: {
          id: entityUuid,
          type: entityType,
        },
        amount,
        targetEntity: {
          id: targetEntityId,
          type: targetEntityType,
        },
        subscription: {
          title: subscriptionTitle,
          description: subscriptionDescription,
        },
      },
      {
        headers: {
          Authorization: tokenHelper.formatAuthHeader(token),
        },
      }
    );
  };
}

export default StripeApi;
