import { Row, Col } from "antd";
import { DollarCircleOutlined } from "@ant-design/icons";
import {
  InputNumber,
  Input,
  Form,
} from "@dreambigger/design-system/src/components";
import { AdditionalIncomeStepAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";

export const monthlyIncomeSourceKey = "monthlyIncomeSource";
export const additionalMonthlyIncomeKey = "additionalMonthlyIncome";

export default function AdditionalIncome({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const assets: AdditionalIncomeStepAssets = step.assets;
  const [form] = Form.useForm();

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled: false,
  };

  const labelCssClasses = "f-3 gray-9 mb-2 lh-1";

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col xs={24} md={16}>
          <label className={labelCssClasses}>
            {assets.monthlyIncomeSourceTitle}
          </label>
          <Form.Item name={monthlyIncomeSourceKey} required>
            <Input placeholder={assets.monthlyIncomeSourcePlaceholder} />
          </Form.Item>
          <label className={labelCssClasses}>
            {assets.additionalMonthlyIncomeTitle}
          </label>
          <Form.Item name={additionalMonthlyIncomeKey} required>
            <InputNumber
              className="w-100 mb-0"
              min={0}
              placeholder={assets.additionalMonthlyIncomePlaceholder}
              stringMode={true}
              step="0.01"
              precision={2} //Rounds to nearest second decimal place.
              prefix={<DollarCircleOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>
    </StepWrapper>
  );
}
