import { useAuthToken, useSegment } from "@dreambigger/shared/src/hooks";
import { Flow } from "@dreambigger/shared/src/types";
import { LocalStorage } from "@dreambigger/shared/src/utils";
import { useCallback, useMemo } from "react";
import { keyByLoginModeHash } from "../pages/flows/[flowId]";

export default function useAuthCallback(
  flow?: Flow,
  keyByLoginMode = keyByLoginModeHash.email
) {
  const segment = useSegment();
  const tokenHelper = useAuthToken(flow?.financialInstitution.id);
  const localStorage = useMemo(
    () => new LocalStorage(flow?.financialInstitution.id),
    [flow?.financialInstitution.id]
  );

  return useCallback(
    (jwt: string, segmentEventLabel: string) => {
      // Save token and userId to local storage
      tokenHelper.setJwtToken(jwt);

      const userAttributes = tokenHelper.decodeJWT(jwt)?.user?.attributes;
      // can't think of why this wouldn't be, but TS don't know that
      if (userAttributes?.loginId) {
        localStorage.setItem(keyByLoginMode, userAttributes?.loginId);
      }

      // Update user id on segment
      // Include traits sent before auth
      // Segment does not include traits sent before auth if user id / email changes
      const user = tokenHelper.decodeJWT(jwt)?.user;
      segment.identify({
        userId: user?.id,
        traits: {
          [keyByLoginMode]: userAttributes?.loginId,
          financialInstitutionId: flow?.financialInstitution.id,
          financialInstitutionName: flow?.financialInstitution.name,
          mostRecentFlowId: flow?.id,
        },
      });
      if (!flow) {
        return;
      }
      segment.group({
        groupId: flow.financialInstitution.id,
        traits: {
          name: flow.financialInstitution.name,
        },
      });

      segment.track({
        action: "Authentication",
        label: segmentEventLabel,
        properties: {
          [keyByLoginMode]: userAttributes?.loginId,
          flowId: flow.id,
        },
      });
    },
    [tokenHelper, keyByLoginMode, flow, segment, localStorage]
  );
}
