/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FinancialBrand,
  OnboardingApplication,
} from "@dreambigger/shared/src/types";

const defaultConfig = {
  appId: process.env.NEXT_PUBLIC_VOUCHED_PUBLIC_KEY,
  sandbox: true,
  liveness: "distance",
  id: "camera",
  font: "proxima-nova",
  includeBackId: true,
  showUploadFirst: false,
  crossDeviceQRCode: false,
  // includeBarcode: true,
  content: {
    startCompanyInstructions:
      "Welcome! This process takes just a minute. Please have your government-issued ID handy.",
    progressIndicatorVouching: "verifying",
    cameraButtonLabelContinueSelfie: "Continue",
    cameraTopBackIdDirections:
      "Please turn the ID around and get a picture of the back.",
  },

  // have the user confirm information
  userConfirmation: {
    confirmImages: true,
  },

  // callback during initialization of the web app
  onInit: ({ token, job }: any) => {},

  // callback when a user submits a photo
  onSubmit: ({ stage, attempts, job }: any) => {},

  // callback executed after attempt to find camera device
  onCamera: ({ hasCamera, hasPermission }: any) => {},

  // callback when there are changes to the Camera DOM element
  onCameraEvent: (cameraEvent: any) => {},

  // callback when a reverification job is complete
  onReverify: (job: any) => {},

  // callback when a survey is submitted, (per customer basis)
  onSurveyDone: (job: any) => {},

  // callback when user confirms data and photos
  onConfirm: (userConfirmEvent: any) => {},
};

type UserDetails = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
};

// Search the application for relevant details for vouched's ID identity comparisons.
const getUserDetails = (
  application: OnboardingApplication
): UserDetails | undefined => {
  if (!application) return;
  let keysToSearchFor = ["firstName", "lastName"];
  // Define empty object to hold data
  let userDetails: any = {};
  // Search through the responses in the application and the available keys in each response.
  // If a key is found, add it along with its value to the userDetails object.
  for (const response of application.responses) {
    const keys = Object.keys(response.fields);
    for (const key of keys) {
      if (keysToSearchFor.includes(key)) {
        userDetails[key] = response.fields[key];
      }
    }
  }
  return userDetails;
};

export interface VouchedProps {
  setVerificationResult: any;
  brand: FinancialBrand;
  deviceHandoff?: boolean;
  application?: OnboardingApplication;
  config?: any;
}
export const vouchedOnReady = ({
  setVerificationResult,
  brand,
  deviceHandoff,
  application,
  config,
}: VouchedProps) => {
  const vouched = (<any>window)["Vouched"]({
    ...defaultConfig,
    ...config,
    // Function to send data back to parent component
    onDone: (job: any) => {
      setVerificationResult(job);
    },
    // Pass in colors and logo from brand
    theme: {
      name: "avant",
      logo: {
        src: brand?.primaryLogo,
        style: { "max-width": 150, "margin-bottom": 10 },
      },
      baseColor: brand?.primaryColor,
    },
    // If deviceHandoff is enabled, load the correct vouched configuration
    crossDevice: deviceHandoff,
    crossDeviceSMS: deviceHandoff,
    crossDeviceShowoff: deviceHandoff,
    enableGeoLocation: true,
    properties: application && [
      {
        name: "onboarding_application_id",
        value: application.id,
      },
    ],
    // Optional verification items to compare against ID if they have been captured in flow
    verification: application && getUserDetails(application),
    callbackURL:
      application &&
      `${process.env.NEXT_PUBLIC_ACQUIRE_API_URL}/onboarding-applications/${application.id}/vouched/job-results`,
  });
  vouched.mount("#vouched-element");
};

export const unmountVouch = (ref: any, config: any) => {
  const vouched = (<any>window)["Vouched"]({ ...defaultConfig, ...config });
  ref.current && vouched.unmount("#vouched-element");
};
