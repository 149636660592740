/**
 * Add a slug and double underscore infront of an object's original keyname
 * Example: prefixFieldsWithSlug(fields: { a: 1, b: 2 }, slug: "start") would become
 * { start__a: 1, start__b: 2 }
 */
export const prefixFieldsWithSlug = ({
  fields,
  slug,
}: {
  fields: Record<string, any>;
  slug: string;
}) => {
  return Object.fromEntries(
    Object.entries(fields).map(([key, value]) => [`${slug}__${key}`, value])
  );
};
