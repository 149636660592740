import {
  Slider,
  InputNumber,
} from "@dreambigger/design-system/src/components/atoms";

export interface SliderWithDisplayInstance {
  id?: string; // Id, value, and onChange are all received automatically by wrapping this component with antd form.item.
  value?: number;
  onChange?: any;
  min: number;
  max: number;
  title?: string;
  textColor?: string;
  sliderColor?: string;
}

const SliderWithDisplay = ({
  id,
  value,
  onChange,
  min,
  max,
  title,
  textColor,
  sliderColor,
}: SliderWithDisplayInstance) => {
  return (
    <div className="flex flex-column w-100 ph-4 pt-6">
      {title && (
        <div className="mh-auto fwt" style={{ color: textColor }}>
          {title}
        </div>
      )}
      <div className="flex justify-center" style={{ transform: "scale(1.2)" }}>
        <div style={{ color: textColor }} className="f-5 fwt pr-1 pt-6">
          $
        </div>
        <InputNumber
          id={id}
          bordered={false}
          min={Number(min)}
          max={Number(max)}
          precision={0} //Rounds to nearest second decimal place.
          className="ao-slider-input inline f-10 lift borderUnderline br-4 ml-2 mb-2"
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          value={value || min}
          onChange={onChange}
          controls={false}
          style={{ width: "275px", color: textColor }}
        />
      </div>
      <Slider
        min={Number(min)}
        max={Number(max)}
        defaultValue={value}
        onChange={onChange}
        value={value}
        tooltip={{ open: false }}
        styles={{
          track: { backgroundColor: sliderColor },
          handle: { backgroundColor: sliderColor },
        }}
        className="mh-auto w-40 mb-4"
        style={{ transform: "scale(2.4)" }}
      />
      <div className="flex justify-between">
        <p className="gray-7">${Number(min).toLocaleString()}</p>
        <p className="gray-7">${Number(max).toLocaleString()}</p>
      </div>
    </div>
  );
};

export default SliderWithDisplay;
