import { useState, useCallback, useEffect } from "react";
import { isValidPhoneNumber } from "libphonenumber-js"; //https://www.npmjs.com/package/libphonenumber-js
import { Row, Col } from "antd";
import { Form, Input } from "@dreambigger/design-system/src/components";
import {
  AdditionalContactStepAssets,
  ResponseFields,
} from "@dreambigger/shared/src/types";
import { emailKey, phoneKey, StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";
import { formatAsNationalPhoneNum } from "../utils/textFormatting";
import { isValidEmail } from "../utils/validators";
import { MaskedInput } from "antd-mask-input";

export default function AdditionalContact({
  step,
  brand,
  flow,
  progress,
}: StepProps) {
  const [disabled, setDisabled] = useState(true);
  const assets: AdditionalContactStepAssets = step.assets;
  const [form] = Form.useForm();
  const [confirmationEmail, setConfirmationEmail] = useState("");

  useEffect(() => {
    // Retrieve the current form field values
    const formValues = form.getFieldsValue();

    // Validate input immediately with the updated confirmationEmail
    handleInput({}, formValues);
  }, [confirmationEmail]);

  const keyByLoginModeHash = {
    sms: emailKey,
    email: phoneKey,
  };

  const keyByLoginMode =
    keyByLoginModeHash[flow.financialInstitution.loginMode];

  const checkValidInput = (values: ResponseFields) => {
    //CASE 1 - AN EMAIL WAS USED TO LOG IN
    if (flow.financialInstitution.loginMode === "email") {
      const phoneNumber = "1 " + values[keyByLoginMode];
      return isValidPhoneNumber(phoneNumber, "US"); //Will only accept US area codes
    }

    //CASE 2 - A PHONE NUMBER WAS USED TO LOG IN
    if (flow.financialInstitution.loginMode === "sms") {
      const email = String(values[keyByLoginMode]);
      if (email.length === confirmationEmail.length) {
        form.validateFields();
      }
      return isValidEmail(email) && email === confirmationEmail;
    }
    return false;
  };

  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    const input = values[keyByLoginMode];
    const inputIsValid = checkValidInput(values);
    //Disable if there isn't an input and there needs to be one OR there is an input and it is invalid. Else, enable!
    setDisabled(
      !!(!input && assets.additionalContactRequired) ||
        !!(input && !inputIsValid)
    );
  };

  // Upon load, prefill inputs with stored responses.
  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    // Define modifiedValues const to be returned in place of initialValues.
    const modifiedValues: ResponseFields = {};

    for (const field in initialValues) {
      modifiedValues[field] = formatAsNationalPhoneNum(
        initialValues[field].toLocaleString()
      );
    }

    return modifiedValues;
  }, []);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handlePrefill,
    handleInput,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col span="24" lg={12}>
          <label>{assets.additionalContactTitle}</label>
          {flow.financialInstitution.loginMode === "email" ? (
            <Form.Item
              name={keyByLoginMode}
              valuePropName={"value"}
              rules={[
                () => ({
                  // Use antd's validator to get value of field
                  validator(_, value) {
                    if (
                      value && // the field isn't empty...
                      !isValidPhoneNumber(value.toString(), "US") && // ...and if it isn't a valid phone number.
                      value.slice(-1) !== "_" // ...and it is filled out (the last character isn't a "_")
                    ) {
                      return Promise.reject(
                        new Error(
                          "Invalid number. Please try again or use a new number."
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <MaskedInput
                className="mt-2 ao-bl-input"
                placeholder={assets.additionalContactPlaceholder}
                mask="(000) 000-0000"
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item name={keyByLoginMode}>
                <Input
                  className="mt-2 ao-bl-input"
                  placeholder={assets.additionalContactPlaceholder}
                  type="email"
                />
              </Form.Item>
              <Form.Item
                name="confirmationEmail"
                rules={[
                  { required: true, message: "Please confirm your email." },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue(keyByLoginMode) === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The two emails do not match.")
                      );
                    },
                  }),
                ]}
              >
                <label>
                  {assets.emailConfirmationTitle || "Please confirm your email"}
                </label>
                <Input
                  className=" ao-bl-input"
                  placeholder={
                    assets.emailConfirmationPlaceholder ||
                    "Enter your email again"
                  }
                  type="email"
                  value={confirmationEmail}
                  onChange={(e) => {
                    const newConfirmationEmail = e.target.value;

                    // Update confirmationEmail state
                    setConfirmationEmail(newConfirmationEmail);

                    // Update Form value immediately
                    form.setFieldsValue({
                      confirmationEmail: newConfirmationEmail,
                    });
                  }}
                />
              </Form.Item>
            </>
          )}
        </Col>
      </Row>
    </StepWrapper>
  );
}
