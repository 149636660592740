import { PlaidStepAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import PlaidAuth from "./plaid-auth";
import PlaidIncome from "./plaid-income";

export default function Plaid({ flow, step, brand, progress }: StepProps) {
  const assets: PlaidStepAssets = step.assets;

  const stepProps = {
    flow,
    step,
    brand,
    progress,
  };
  switch (assets.mode) {
    case "income":
      return <PlaidIncome {...stepProps} />;
    default:
      return <PlaidAuth {...stepProps} />;
  }
}
