import { Flow, StepAssets } from "@dreambigger/shared/src/types";
import { useCallback, useMemo } from "react";
import { changeStepForward } from "../utils/changeStepForward";

export default function useNextStep(
  assets: StepAssets,
  applicationHelper: any,
  flow: Flow
) {
  const nextSlugMatchStepResponse = useMemo(() => {
    if (
      typeof assets.nextSlug === "object" &&
      "matchStepSlug" in assets.nextSlug
    ) {
      return applicationHelper.findResponse(
        flow.id,
        "draft",
        assets.nextSlug.matchStepSlug
      );
    }
  }, [assets, applicationHelper, flow]);

  return useCallback(
    (inputValues: any) => {
      if (nextSlugMatchStepResponse) {
        changeStepForward(assets.nextSlug, nextSlugMatchStepResponse.fields);
        return;
      }
      changeStepForward(assets.nextSlug, inputValues);
    },
    [assets, applicationHelper, flow]
  );
}
