import { useMemo, useState, useCallback, useEffect } from "react";
import { parseFullName } from "parse-full-name";
import { Row, Col, message } from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
  Input,
  Button,
  Form,
  Checkbox,
} from "@dreambigger/design-system/src/components";
import { NameStepAssets } from "@dreambigger/shared/src/types";
import { StepContentSection } from "../components";
import styles from "./steps.module.scss";
import { StepProps } from "../pages/flows/[flowId]";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { useApplication } from "../api";
import { changeStepForward } from "../utils/changeStepForward";
import { prefixFieldsWithSlug } from "../utils/prefixStepSlug";

export const nameKey = "name";
export const firstNameKey = "firstName";
export const lastNameKey = "lastName";
export const fullNameKey = "fullName";
export const termsKey = "terms";

export default function Name({ flow, step, brand, progress }: StepProps) {
  const [disabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const { slug, type } = step;
  const assets: NameStepAssets = step.assets;
  const [form] = Form.useForm();
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  const response = useMemo(
    () => applicationHelper.findResponse(flow.id, "draft", slug),
    [applicationHelper, flow, step]
  );
  const prefillValues = useMemo(() => {
    return response
      ? {
          [nameKey]:
            response.fields[fullNameKey] ||
            `${response.fields[firstNameKey]} ${response.fields[lastNameKey]}`,
          [termsKey]: response.fields[termsKey],
        }
      : undefined;
  }, [response]);
  const segment = useSegment();

  const handleInput = useCallback((_: any, values: any) => {
    // Disable submit button if name or term keys are empty.
    setDisabled(!(values[nameKey] && values[termsKey]));
  }, []);

  const handleNext = useCallback(
    (values: any) => {
      // Guard against users not filling in required fields.
      if (!values[nameKey] || !values[termsKey]) {
        message.error("Please provide full name and agree to terms.");
        return;
      }

      // Parse name and pass to segment. Note: In rare cases, like a user imputting a name like "Di Simmons",
      // parseFullName will not record a first name and assume it is all part of a last name.
      // For highest accuracy, use fullNameKey in reporting.
      const fullName = parseFullName(values[nameKey]);

      const name = {
        [firstNameKey]: fullName.first,
        [lastNameKey]: fullName.last,
        [fullNameKey]: values[nameKey],
      };

      // Prefix the keys we send to segment with the Step Slug followed by double underscore.
      if (!application) {
        message.error("Unable to find application");
        return;
      }

      setSaving(true);
      applicationHelper
        .update(application.id, {
          slug,
          type,
          fields: {
            [firstNameKey]: fullName.first || "",
            [lastNameKey]: fullName.last || "",
            [fullNameKey]: values[nameKey],
            [termsKey]: values[termsKey],
          },
        })
        .then(() => {
          changeStepForward(assets.nextSlug, values);
        })
        .finally(() => {
          setSaving(false);
          // Track with segment
          segment.track({
            action: "Button Click",
            label: `Submit - ${step.slug}`,
            properties: prefixFieldsWithSlug({ fields: name, slug: step.slug }),
          });

          segment.identify({
            traits: name,
          });
        });
    },
    [application, step]
  );

  // On load, reset form fields and prefill with whatever values have already been entered into the DB, if any.
  // Values are passed into the form because of paramater initialValues={prefillValues} in <Form>.
  useEffect(() => {
    form.resetFields();
    if (!prefillValues) {
      return;
    }
    handleInput(prefillValues, prefillValues);
  }, [prefillValues]);

  return (
    <StepContentSection
      brand={brand}
      stepProgress={progress}
      title={assets.title ?? ""}
      description={assets.description ?? ""}
      previousSlug={assets.previousSlug}
      hideProgressBar={assets.hideProgressBar}
    >
      <Form
        form={form}
        onFinish={handleNext}
        initialValues={prefillValues}
        onValuesChange={handleInput}
      >
        <Row>
          <Col span="24" lg={12}>
            <label>{assets.nameTitle}</label>
            <Form.Item name={nameKey}>
              <Input
                placeholder={assets.namePlaceholder}
                className="ao-bl-input"
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="mt-6 flex bt pt-4 b-lightPrimary">
          <div className="pr-3">
            <Form.Item name={termsKey} valuePropName="checked">
              <Checkbox className="ao-lg-checkbox" />
            </Form.Item>
          </div>
          <div>
            <label>{assets.termsTitle}</label>
            <p
              className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
              dangerouslySetInnerHTML={{
                __html: assets.termsText ?? "",
              }}
            />
          </div>
        </div>

        <Button
          type="primary"
          htmlType="submit"
          loading={saving}
          disabled={disabled}
          className="s-2 lift h-7 ph-5"
        >
          {assets.submitButtonText} <RightOutlined />
        </Button>
      </Form>
    </StepContentSection>
  );
}
