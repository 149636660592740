import { useState, useEffect } from "react";
import { CheckCircleFilled, ClockCircleOutlined } from "@ant-design/icons";
import styles from "./ProgressModal.module.scss";

export interface ProgressModalProps {
  initialText: string;
  completionText: string;
  onFinish?: () => void;
  delayInterval?: number;
  accentColor?: string;
  showModal?: boolean;
  setShowModal?: (showModal: boolean) => void;
}

const ProgressModal = ({
  initialText,
  completionText,
  onFinish,
  delayInterval = 3000,
  accentColor,
  showModal,
  setShowModal,
}: ProgressModalProps) => {
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(initialText);

  // Upon modal load, start a timer that will increment the progress bar until it reaches 100%.
  useEffect(() => {
    if (!showModal) {
      return;
    }
    const adjustedDelayInterval = delayInterval / 1000;
    const incrementAmount = 100 / adjustedDelayInterval;
    let interval: any = null;
    if (progress < 100) {
      interval = setInterval(() => {
        setProgress(progress + incrementAmount);
      }, 1000);
    } else {
      // Wait a second for the animation to finish and adjust set the title, triggering onFinish or the modal to close.
      setTimeout(() => {
        setTitle(completionText);
        if (onFinish) {
          onFinish();
        }
        setShowModal && setShowModal(false);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [progress, showModal]);

  // Set progress bar width based on progress state.
  const progressBarStyle = {
    width: `${progress}%`,
    backgroundColor: accentColor,
    height: "10px",
    transition: "width 1s ease-in-out",
  };

  if (!showModal) {
    return <></>;
  }

  // Render ------------------------------------
  return (
    <div className={`${styles.progressModal}`}>
      <div className={`w-90, md_w-50 br-4 tc bg-white pa-6 s-7`}>
        <div className="modal-header">
          <div className="flex justify-center items-center mb-7">
            {/* ICON */}
            {title === completionText ? (
              <CheckCircleFilled
                className="mr-2"
                style={{ color: "green", fontSize: "28px" }}
              />
            ) : (
              <ClockCircleOutlined
                className="mr-2"
                style={{ color: accentColor, fontSize: "32px" }}
              />
            )}
            {/* HEADER */}
            <h2
              className="mv-auto"
              style={{
                color: title === completionText ? "green" : "black",
              }}
            >
              {title}
            </h2>
          </div>
        </div>
        {/* PROGRESS BAR */}
        <div className={`${styles.modalContent}`}>
          <div className="progress-bar" style={progressBarStyle} />
        </div>
      </div>
    </div>
  );
};

export default ProgressModal;
