// Search an object for keys with the value: {function: "string"}. Convert this value into an actual function.
// Example:
//   panelRender: {function: "() => null"}
//   becomes
//   panelRender: () => null
export const convertFunctionStrings = (
  obj: Record<string, any>
): Record<string, any> => {
  const convert = (value: any): any => {
    if (typeof value === "object" && value !== null) {
      if ("function" in value && typeof value["function"] === "string") {
        try {
          // Convert the string to a function and return the function itself
          return new Function(`return ${value["function"]}`)();
        } catch (error) {
          console.error(
            `Failed to convert string to function: ${value["function"]}`,
            error
          );
        }
      } else {
        // Recursively apply conversion to nested objects
        for (const key in value) {
          if (Object.prototype.hasOwnProperty.call(value, key)) {
            value[key] = convert(value[key]);
          }
        }
      }
    }
    return value;
  };

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = convert(obj[key]);
    }
  }

  return obj;
};
