import { useMemo } from "react";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import FormWrapper from "./form-wrapper";
import { IntentType } from "../../types";
export interface StripePaymentFormProps {
  children: React.ReactNode;
  onValuesChange: (changedValues: any, values: any) => void;
  clientSecret: string;
  intentType: IntentType;
  returnUrl: string;
  appearance?: StripeElementsOptions["appearance"];
  stripeAccount?: string;
}

const spinnerIcon = <Loading3QuartersOutlined spin />;

export default function StripePaymentForm({
  children,
  onValuesChange,
  clientSecret,
  intentType,
  returnUrl,
  appearance,
  stripeAccount,
}: StripePaymentFormProps) {
  //use Stripe connect account if available
  const stripePromise = useMemo(
    () =>
      stripeAccount
        ? loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || "", {
            stripeAccount,
          })
        : loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || ""),
    [stripeAccount]
  );

  return clientSecret ? (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance,
      }}
    >
      <FormWrapper
        onValuesChange={onValuesChange}
        returnUrl={returnUrl}
        intentType={intentType}
      >
        {children}
      </FormWrapper>
    </Elements>
  ) : (
    <div>
      <Spin indicator={spinnerIcon} />
    </div>
  );
}
