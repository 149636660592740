import { useEffect, useMemo } from "react";
import { Row, Spin } from "antd";
import { StepContentSection } from "../components";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { StepAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import { useApplication } from "../api";
import { useSegment } from "@dreambigger/shared/src/hooks";
import useNextStep from "../hooks/use-next-step";

const spinnerIcon = <Loading3QuartersOutlined spin />;

export default function Transition({ flow, step, brand, progress }: StepProps) {
  const segment = useSegment();
  const assets: StepAssets = step.assets;
  const { slug, type } = step;
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow.id]
  );
  const nextStep = useNextStep(assets, applicationHelper, flow);

  //transition to next step once application is available
  useEffect(() => {
    if (!application) {
      return;
    }
    // Track redirect in segment
    segment.track({
      action: "Redirect",
      label: `Transition - ${slug}`,
    });
    nextStep(undefined);
  }, [application, nextStep]);

  return (
    <StepContentSection
      brand={brand}
      stepProgress={progress}
      title={assets.title || "Processing..."}
      description={
        assets.description ||
        "Please do not navigate away or close this window."
      }
      assets={assets}
      stepType={type}
    >
      {/* While processing, render a spinner */}
      <Row>{<Spin indicator={spinnerIcon} />}</Row>
    </StepContentSection>
  );
}
