import { useState, useCallback, useEffect, useMemo } from "react";
import { Form } from "@dreambigger/design-system/src/components";
import { PlaidIDVStepAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import styles from "./steps.module.scss";
import StepWrapper from "./step-wrapper";
import { useApplication, usePlaid } from "../api";
import { Button } from "@dreambigger/design-system/src/components";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CameraFilled,
} from "@ant-design/icons";

import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnEvent,
} from "react-plaid-link";

const linkSessionIdKey = "linkSessionId";
const statusKey = "status";

enum Status {
  Pass = "pass",
  Fail = "fail",
}

const sessionEventToStatus: Record<string, string> = {
  IDENTITY_VERIFICATION_PASS_SESSION: "pass",
  IDENTITY_VERIFICATION_FAIL_SESSION: "fail",
};

export default function PlaidIdv({ flow, step, brand, progress }: StepProps) {
  const assets: PlaidIDVStepAssets = step.assets;
  const {
    templateUuid,
    helpText1,
    helpText2,
    termsText,
    ctaText,
    errorText,
    allowContinueOnError,
    maskError,
  } = assets;

  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  const previouslySavedStatus = applicationHelper.findResponseField(
    flow.id,
    "draft",
    step.slug,
    "status"
  );

  // state
  const [token, setToken] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [linkSessionId, setLinkSessionId] = useState<string | undefined>();
  const [status, setStatus] = useState<string | undefined>();

  // Plaid IDV callbacks
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (
      _publicToken: string,
      { link_session_id: newLinkSessionId }: PlaidLinkOnSuccessMetadata
    ) => {
      form.setFieldsValue({ [linkSessionIdKey]: newLinkSessionId });
      setLinkSessionId(newLinkSessionId);
    },
    []
  );

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName: string) => {
    const newStatus = sessionEventToStatus[eventName];
    if (newStatus) {
      form.setFieldsValue({ [statusKey]: newStatus });
      setStatus(newStatus);
    }
  }, []);

  // custom hooks
  const [form] = Form.useForm();
  const plaidHelper = usePlaid(flow.financialInstitution.id);
  const { open: launchPlaid, ready: plaidIsReady } = usePlaidLink({
    token,
    onSuccess,
    onEvent,
  });

  // initialize state from saved values
  useEffect(() => {
    const initialValues = form.getFieldsValue();
    setLinkSessionId(initialValues[linkSessionIdKey]);
    setStatus(initialValues[statusKey]);
  }, [form]);

  // triggered by both onSuccess and onEvent handlers, as well as init code
  useEffect(() => {
    if (
      (linkSessionId && status === Status.Pass) ||
      (status === Status.Fail && allowContinueOnError)
    ) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
  }, [linkSessionId, status]);

  // initialize plaid link token
  useEffect(() => {
    // get link_token from your server when component mounts
    const createLinkToken = async () => {
      setProcessing(true);

      if (!application || !templateUuid) {
        return;
      }

      const { linkToken } = await plaidHelper.createLinkTokenforIDV({
        requestId: `${application.id}|${step.slug}`,
        templateUuid,
        onboardingApplicationId: application.id,
      });

      if (linkToken) {
        setToken(linkToken);
        setProcessing(false);
      }
    };
    if (!status && !previouslySavedStatus) createLinkToken();
  }, [application]);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
    processing,
  };

  if (!templateUuid) {
    return (
      <p>
        There was an error loading this page. Please contact support to finish
        your application.
      </p>
    );
  }

  return (
    <StepWrapper {...wrapperProps}>
      <Form.Item name={linkSessionIdKey} hidden={true} />
      <Form.Item name={statusKey} hidden={true} />

      {helpText1 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4`}
            dangerouslySetInnerHTML={{
              __html: helpText1 ?? "",
            }}
          />
        </div>
      )}

      <div className={`flex flex-column justify-center items-center pt-4`}>
        {/* If verified display the result. Otherwise give option to load IDV */}
        {status ? (
          // Message displayed on success
          status === Status.Pass || maskError ? (
            <div
              className="flex items-center f-6 md_f-7 mb-4"
              style={{ color: "green" }}
            >
              <CheckCircleFilled />
              <p className="ml-2 mb-0">Verification Completed</p>
            </div>
          ) : (
            // Message displayed on failure.
            <div style={{ color: "red" }}>
              <div className="flex items-center lh-2 mb-4">
                (
                <CloseCircleFilled className="f-7" />)
                <div className="flex flex-column ml-2">
                  <p className="mb-0 f-7">
                    Verification Could Not Be Completed
                  </p>
                  <p className="fwb mb-0">
                    {errorText ||
                      "Please go back to the previous step and verify your ID through another option."}
                  </p>
                </div>
              </div>
            </div>
          )
        ) : (
          <Button
            type="primary"
            className="f-5 h-7 md_f-6 md_h-8 s-2 mb-4 lift"
            onClick={() => launchPlaid()}
            disabled={!plaidIsReady}
          >
            <CameraFilled /> {ctaText ? ctaText : "Verify Using Your Webcam"}
          </Button>
        )}
      </div>

      {helpText2 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: helpText2,
            }}
          />
        </div>
      )}

      {termsText && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: termsText,
            }}
          />
        </div>
      )}
    </StepWrapper>
  );
}
