import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import {
  AddressStepAssets,
  ResponseFields,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";
import {
  Checkbox,
  Input,
  Select,
  Form,
} from "@dreambigger/design-system/src/components";
import { AddressAutocomplete, AddressFields } from "../components";
import styles from "./steps.module.scss";
const { Option } = Select;

export const addressKey = "address";
export const apartmentKey = "apartment";
export const cityKey = "city";
export const stateKey = "state";
export const zipKey = "zip";
export const confirmationKey = "addressConfirmation";

export default function Address({ flow, step, brand, progress }: StepProps) {
  const [disabled, setDisabled] = useState(true);
  const assets: AddressStepAssets = step.assets;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [flow, step]);

  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    setDisabled(
      !(
        values &&
        values[addressKey] &&
        values[cityKey] &&
        values[stateKey] &&
        values[zipKey]
      )
    );
  };

  const handleAddressSelect = (
    address: string,
    addressFields?: AddressFields,
    isPopulated = false
  ) => {
    const updatedValues = {
      [addressKey]: (isPopulated && addressFields?.address) || address,
      [stateKey]: (isPopulated && addressFields?.state) || "",
      [cityKey]: (isPopulated && addressFields?.locality) || "",
      [zipKey]: (isPopulated && addressFields?.postcode) || "",
    };
    form.setFieldsValue(updatedValues);
    handleInput(updatedValues, form.getFieldsValue(true));
  };

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col xs={24} md={16}>
          <label>{assets.homeAddressTitle}</label>
          <Form.Item name={addressKey} required className="ao-bl-select">
            <AddressAutocomplete
              placeholder={assets.homeAddressPlaceholder}
              onAddressSelect={handleAddressSelect}
              icon={<HomeOutlined />}
              className="ao-bl-select"
            />
          </Form.Item>

          <label>{assets.homeApartmentTitle}</label>
          <Form.Item name={apartmentKey}>
            <Input
              className="ao-bl-input"
              placeholder={assets.homeApartmentPlaceholder}
            />
          </Form.Item>

          <label>{assets.homeCityTitle}</label>
          <Form.Item name={cityKey} required>
            <Input
              placeholder={assets.homeCityPlaceholder}
              className="ao-bl-input"
            />
          </Form.Item>

          <Input.Group>
            <Row gutter={8}>
              <Col span={12}>
                <label>{assets.homeStateTitle}</label>

                <Form.Item name={stateKey}>
                  <Select
                    placeholder={assets.homeStatePlaceholder}
                    showSearch
                    optionFilterProp="children"
                    className="ao-bl-select"
                  >
                    {assets?.allowedHomeStates?.map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <label>{assets.homeZipTitle}</label>
                <Form.Item
                  name={zipKey}
                  validateTrigger={["onSubmit"]}
                  rules={[
                    {
                      pattern: /^\d{5}(?:-\d{4})?$/,

                      message:
                        "Please enter a valid ZIP code (e.g., 12345 or 12345-6789)",
                    },
                  ]}
                >
                  <Input
                    placeholder={assets.homeZipPlaceholder}
                    name="zip"
                    className="ao-bl-input"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>

      {assets.confirmationText && (
        <div className={`mt-6 flex items-center`}>
          <Form.Item
            name={confirmationKey}
            valuePropName="checked"
            className="mr-4 mb-0"
          >
            <Checkbox className="ao-lg-checkbox" />
          </Form.Item>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-0`}
            dangerouslySetInnerHTML={{
              __html: assets.confirmationText,
            }}
          />
        </div>
      )}
    </StepWrapper>
  );
}
