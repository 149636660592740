import { useState, useCallback, useEffect, useMemo } from "react";
import { StepProps } from "../pages/flows/[flowId]";
import {
  ExistingMemberSharesStepAssets,
  ExistingShare,
  ResponseFields,
} from "@dreambigger/shared/src/types";
import { message, Row, Col, Select, Input, Spin } from "antd";
import StepWrapper from "./step-wrapper";
import {
  Form,
  DatePicker,
  MaskedInput,
} from "@dreambigger/design-system/src/components";
import { getPrefillDates } from "../utils/prefill-dates";
import { CoreBankingApi } from "@dreambigger/shared/src/api/acquire";
import { api } from "@dreambigger/shared/src/api/acquire";
import { useAuthToken } from "../passwordless-auth";
import { useApplication } from "../api";
import { Loading3QuartersOutlined } from "@ant-design/icons";

interface GetSharesApiParams {
  coreBankingConfigurationUUID: string;
  birthday?: string;
  tin?: string;
  accountId?: string;
}

const spinnerIcon = <Loading3QuartersOutlined spin />;

const formatShareLabel = (share: ExistingShare): string => {
  const balance =
    share.balance !== undefined && !isNaN(Number(share.balance))
      ? ` (${
          Number(share.balance) < 0
            ? `-$${Math.abs(Number(share.balance)).toFixed(2)}`
            : `$${Number(share.balance).toFixed(2)}`
        })`
      : "";

  return `${share.title}${balance} - Account Number: ${share.accountNumber} - Routing Number: ${share.routingNumber}`;
};

export default function ExistingMemberShares({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const [disabled, setDisabled] = useState(false);
  const [shares, setShares] = useState([]);
  const [loading, setLoading] = useState(true);
  const assets: ExistingMemberSharesStepAssets = step.assets;
  const {
    coreBankingConfigurationUuid,
    dobTitle,
    dobPlaceholder,
    dobConfig,
    ssnTitle,
    ssnPlaceholder,
    sharesTitle,
    sharesPlaceholder,
    verifyErrorMessage,
    credentialsErrorMessage,
    defaultErrorMessage,
    accountIdStepSlug,
    accountIdKey,
  } = assets;
  const [form] = Form.useForm();

  const tokenHelper = useAuthToken(flow.financialInstitution.id);

  // Initialize the various api's we need for this step.
  const coreBankingApi = new CoreBankingApi(api, tokenHelper);

  const applicationHelper = useApplication(flow.financialInstitution.id);

  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  // If we have account the user selected on a previous step to display shares for, we should send the account data to the API.
  const initialAccountData = useMemo(() => {
    if (!accountIdStepSlug) {
      return;
    }

    return applicationHelper.findResponse(flow.id, "draft", accountIdStepSlug);
  }, [applicationHelper, flow]);

  // Display dob and ssn form fields if accountIdStepSlug or accountIdKey are not provided.
  const collectDobSSN = !accountIdStepSlug || !accountIdKey;

  // Run disable check on shares change.
  useEffect(() => {
    handleInput({}, form.getFieldsValue());
  }, [shares]);

  // Set loading state to false once application is loaded
  useEffect(() => {
    if (application) {
      setLoading(false);
    }
  }, [application]);

  // Automatically run handleNext if there is initial account data (gets us to the share selection state of this page)
  useEffect(() => {
    if (initialAccountData) {
      handleNext();
    }
  }, [initialAccountData]);

  const getShares = async () => {
    if (!coreBankingConfigurationUuid) {
      console.error("No core banking configuration was provided.");
      message.error(
        verifyErrorMessage ||
          "There was an error with the system. Please contact suppport."
      );
      return;
    }
    const values = form.getFieldsValue();

    const apiParams = {
      coreBankingConfigurationUUID: coreBankingConfigurationUuid,
    } as GetSharesApiParams;

    // If we have account data, use the account id to get shares. Otherwise use the birthday and ssn to get shares.
    if (initialAccountData && initialAccountData?.fields) {
      apiParams.accountId = initialAccountData?.fields?.[
        accountIdKey || ""
      ] as string;
    } else {
      apiParams.birthday =
        values.birthday && values.birthday.format("YYYY-MM-DD");
      apiParams.tin = values.ssn;
    }
    coreBankingApi
      .getShares(apiParams)
      .then(({ data }) => {
        // If credentials are good but no shares are returned, show an error message.
        if (!data.length) {
          message.error(
            verifyErrorMessage ||
              "You don't have any active accounts. Please try again."
          );
          return;
        }
        // Otherwise, set shares with returned data.
        setShares(data);
      })
      .catch((err) => {
        // Bad credentials
        if (err.response?.status === 404) {
          message.error(
            verifyErrorMessage ||
              "We couldn't find any accounts with that information. Please try again."
          );
          return;
        }
        // Incomplete credentials
        if (err.response?.status === 400) {
          message.error(
            credentialsErrorMessage ||
              "Your credentials are missing or incorrect. Please try again."
          );
          return;
        }
        // Other error (rare)
        console.error(err);
        message.error(
          defaultErrorMessage ||
            "There was an error with the system. Please contact suppport."
        );
      });
  };

  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) return;
    return getPrefillDates(initialValues, ["birthday"]);
  }, []);

  // Overrides the default next button behavior to get the shares.
  const handleNext = () => {
    getShares();
  };

  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    // If the user has selected an account, fill the routing number, title, and type into the hidden inputs.
    if (values.accountNumber) {
      const share: ExistingShare | undefined = shares.find(
        (share: ExistingShare) => share.accountNumber === values.accountNumber
      ) as ExistingShare | undefined;
      form.setFieldsValue({
        ...values,
        routingNumber: share?.routingNumber,
        title: share?.title,
        type: share?.type,
        id: share?.id,
      });
    }
    // Check if the next button should be disabled. If shares have been retrieved, the user must select one.
    if (shares?.length > 0 && !values.accountNumber) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  };

  // Allows the user to change the birthday and ssn and try again.
  const handleReset = () => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      birthday: values.birthday,
      ssn: values.ssn,
      accountNumber: undefined,
      routingNumber: undefined,
      title: undefined,
      type: undefined,
      id: undefined,
    });
    setShares([]);
  };

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
    handlePrefill,
    handleNext: !shares?.length && handleNext, // Override the default next button behavior before an account is selected.
    handleInput,
  };

  return (
    <StepWrapper {...wrapperProps}>
      {loading && !collectDobSSN && <Spin indicator={spinnerIcon}></Spin>}

      <Row>
        <Col xs={24}>
          {/* Validation - Rendered if no shares have been retrieved and we are collecting dob/ssn as login credentials */}
          {collectDobSSN === true && (
            <>
              {shares?.length === 0 && (
                <label>{dobTitle || "Enter Your Birthday"}</label>
              )}
              <Form.Item name="birthday" hidden={shares?.length > 0}>
                <DatePicker
                  placeholder={dobPlaceholder}
                  className="w-40 ao-bl-picker"
                  config={dobConfig}
                />
              </Form.Item>
              {shares?.length === 0 && (
                <label>{ssnTitle || "Enter Your SSN"}</label>
              )}
              <Form.Item
                name="ssn"
                valuePropName="value"
                hidden={shares?.length > 0}
              >
                <MaskedInput
                  style={{ width: "126px" }}
                  mask="000-00-0000"
                  placeholder={ssnPlaceholder}
                  className="ao-bl-input mt-2"
                />
              </Form.Item>
            </>
          )}
          {/* Select Share Dropdown - Rendered after shares have been retrieved */}
          {shares?.length > 0 && (
            <>
              <label>{sharesTitle || "Select Your Account"}</label>
              {collectDobSSN === true && (
                <a className="ml-2" onClick={handleReset}>
                  Reset
                </a>
              )}
              <Form.Item name="accountNumber" valuePropName={"value"}>
                <Select
                  options={shares.map((share: ExistingShare) => ({
                    value: share.accountNumber,
                    label: formatShareLabel(share),
                  }))}
                  placeholder={sharesPlaceholder || "Select account"}
                  className="ao-bl-select"
                />
              </Form.Item>
              {/* The bellow inputs are always hidden and capture additional information about the selecte account. */}
              <Form.Item
                name="routingNumber"
                valuePropName={"value"}
                hidden={true}
              >
                <Input />
              </Form.Item>
              <Form.Item name="title" valuePropName={"value"} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item name="type" valuePropName={"value"} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item name="id" valuePropName={"value"} hidden={true}>
                <Input />
              </Form.Item>
            </>
          )}
        </Col>
      </Row>
    </StepWrapper>
  );
}
