import { ReactNode, useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import { message } from "antd";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentIntentResult, SetupIntentResult } from "@stripe/stripe-js";
import { IntentType } from "../../types";

export interface StatusWrapperProps {
  children?: ReactNode;
  onFinish: (status: string, paymentMethod: string) => void;
  intentType: IntentType;
}

export default function StatusWrapper({
  children,
  onFinish,
  intentType,
}: StatusWrapperProps) {
  const stripe = useStripe();
  const router = useRouter();
  const param =
    intentType === "payment"
      ? "payment_intent_client_secret"
      : "setup_intent_client_secret";
  const handleConfirmation =
    intentType === "payment"
      ? stripe?.retrievePaymentIntent
      : stripe?.retrieveSetupIntent;
  const clientSecret = router.query[param];

  const handleResult = useCallback(
    (result: PaymentIntentResult | SetupIntentResult) => {
      if (result.error) {
        message.error("Unable to verify payment. Please contact support");
        return;
      }
      if (intentType === "payment") {
        const { paymentIntent } = result as PaymentIntentResult;
        if (!paymentIntent) {
          return;
        }
        const {
          status,
          payment_method_types: [paymentMethod],
        } = paymentIntent;
        onFinish(status, paymentMethod);
        return;
      }
      if (intentType === "setup") {
        const { setupIntent } = result as SetupIntentResult;
        if (!setupIntent) {
          return;
        }
        const {
          status,
          payment_method_types: [paymentMethod],
        } = setupIntent;
        onFinish(status, paymentMethod);
        return;
      }
    },
    [intentType, onFinish]
  );

  // handle payment completion redirect from stripe
  useEffect(() => {
    //payment intent secret must exist in query param and stripe must have loaded
    if (!clientSecret || !stripe || !handleConfirmation) {
      return;
    }

    //check payment status and invoke onFinish
    handleConfirmation(clientSecret as string).then(handleResult);
  }, [stripe, clientSecret]);

  return <>{children}</>;
}
