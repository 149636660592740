import { useState, useCallback, useMemo, useEffect } from "react";
import { useRouter } from "next/router";
import { Row, Col, Spin, message } from "antd";
import { PaymentElement } from "@stripe/react-stripe-js";
import { RightOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { PaymentStepAssets } from "@dreambigger/shared/src/types";
import {
  Button,
  Form,
  Checkbox,
} from "@dreambigger/design-system/src/components";
import { StepContentSection, StripePaymentForm } from "../components";
import { StepProps } from "../pages/flows/[flowId]";
import { useApplication } from "../api";
import styles from "../steps/steps.module.scss";
import { useAuthToken } from "../passwordless-auth";
import { default as StripeApi } from "@dreambigger/shared/src/api/stripe";
import { api } from "@dreambigger/shared/src/api/acquire";

export const confirmationKey = "paymentConfirmation";
const spinnerIcon = <Loading3QuartersOutlined spin />;

export const paymentIntentSecretKey = "paymentIntentSecret";
export const paymentIntentIdKey = "paymentIntentId";
export const paymentIntentAmountKey = "paymentIntentAmount";
export const paymentIntentOptInKey = "paymentIntentOptIn";

const stripeApi = new StripeApi(api);

export default function Payment({ flow, step, brand, progress }: StepProps) {
  const [disabled, setDisabled] = useState(true);
  const [elementComplete, setElementComplete] = useState(false);
  const [elementLoading, setElementLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [currentClientSecret, setCurrentClientSecret] =
    useState<string | undefined>();
  const router = useRouter();
  const { slug, type } = step;
  const tokenHelper = useAuthToken(flow.financialInstitution.id);
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  const { flowId } = router.query;
  const assets: PaymentStepAssets = step.assets;
  const {
    paymentAmountSlug,
    paymentAmountFieldKey,
    subscriptionOptInSlug,
    subscriptionOptInFieldKey,
    confirmationText,
    autoConfirmation,
    submitButtonText,
    stripeAccountId,
    nextSlug,
    stripePaymentIntentConfigurationId,
    stripeSubscriptionConfigurationId,
    stripeSetupIntentConfigurationId,
    subscriptionTitleConfiguration,
    subscriptionDescriptionConfiguration,
  } = assets;

  const paymentAmount: number | undefined = useMemo(() => {
    if (!paymentAmountSlug || !paymentAmountFieldKey) {
      return;
    }
    const amount = applicationHelper.findResponseField(
      flow.id,
      "draft",
      paymentAmountSlug,
      paymentAmountFieldKey
    );
    if (!amount) {
      return;
    }
    return Number(amount);
  }, [applicationHelper, flow.id, paymentAmountFieldKey, paymentAmountSlug]);

  const savedAmount: number | undefined = useMemo(() => {
    const amount = applicationHelper.findResponseField(
      flow.id,
      "draft",
      slug,
      paymentIntentAmountKey
    );
    if (!amount) {
      return;
    }
    return Number(amount);
  }, [applicationHelper, flow.id, slug]);

  const subscriptionOptIn: boolean | undefined = useMemo(() => {
    if (!subscriptionOptInSlug || !subscriptionOptInFieldKey) {
      return;
    }
    const optIn = applicationHelper.findResponseField(
      flow.id,
      "draft",
      subscriptionOptInSlug,
      subscriptionOptInFieldKey
    );
    if (!optIn) {
      return;
    }
    return Boolean(optIn);
  }, [
    applicationHelper,
    flow.id,
    subscriptionOptInFieldKey,
    subscriptionOptInSlug,
  ]);

  const savedOptIn: boolean | undefined = useMemo(() => {
    const optIn = applicationHelper.findResponseField(
      flow.id,
      "draft",
      slug,
      paymentIntentOptInKey
    );
    if (!optIn) {
      return;
    }
    return Boolean(optIn);
  }, [applicationHelper, flow.id, slug]);

  const billingDetails = useMemo(() => {
    if (!assets.billingDetails) {
      return;
    }
    const { name, email, phone, address } = assets.billingDetails;
    const firstName = applicationHelper.findResponseField(
      flow.id,
      "draft",
      name?.slug,
      name?.firstNameFieldKey
    );
    const lastName = applicationHelper.findResponseField(
      flow.id,
      "draft",
      name?.slug,
      name?.lastNameFieldKey
    );
    return {
      defaultValues: {
        billingDetails: {
          name: firstName && lastName ? `${firstName} ${lastName}` : "",
          email: String(
            applicationHelper.findResponseField(
              flow.id,
              "draft",
              email?.slug,
              email?.fieldKey
            ) || ""
          ),
          phone: String(
            applicationHelper.findResponseField(
              flow.id,
              "draft",
              phone?.slug,
              phone?.fieldKey
            ) || ""
          ),
          address: {
            line1: String(
              applicationHelper.findResponseField(
                flow.id,
                "draft",
                address?.slug,
                address?.line1FieldKey
              ) || ""
            ),
            line2: String(
              applicationHelper.findResponseField(
                flow.id,
                "draft",
                address?.slug,
                address?.line2FieldKey
              ) || ""
            ),
            city: String(
              applicationHelper.findResponseField(
                flow.id,
                "draft",
                address?.slug,
                address?.cityFieldKey
              ) || ""
            ),
            state: String(
              applicationHelper.findResponseField(
                flow.id,
                "draft",
                address?.slug,
                address?.stateFieldKey
              ) || ""
            ),
            country: "US",
            postal_code: String(
              applicationHelper.findResponseField(
                flow.id,
                "draft",
                address?.slug,
                address?.postalCodeFieldKey
              ) || ""
            ),
          },
        },
      },
    };
  }, [applicationHelper, flow, assets]);

  const subscriptionTitle = useMemo(() => {
    if (!stripeSubscriptionConfigurationId) {
      return;
    }
    if (!subscriptionTitleConfiguration) return flow.skins?.[0]?.title;
    if (subscriptionTitleConfiguration.type === "static") {
      return subscriptionTitleConfiguration.template;
    }
    // TODO implement dynamic subscription title
    return "";
  }, [
    subscriptionTitleConfiguration,
    stripeSubscriptionConfigurationId,
    flow.skins,
  ]);

  const subscriptionDescription = useMemo(() => {
    if (!stripeSubscriptionConfigurationId) {
      return;
    }
    if (!subscriptionDescriptionConfiguration) return flow.skins?.[0]?.title;
    if (subscriptionDescriptionConfiguration.type === "static") {
      return subscriptionDescriptionConfiguration.template;
    }
    // TODO implement dynamic subscription description
    return "";
  }, [
    stripeSubscriptionConfigurationId,
    subscriptionDescriptionConfiguration,
    flow.skins,
  ]);

  const savedClientSecret: string | undefined = useMemo(() => {
    const paymentIntentSecret = applicationHelper.findResponseField(
      flow.id,
      "draft",
      slug,
      paymentIntentSecretKey
    );
    return paymentIntentSecret ? String(paymentIntentSecret) : undefined;
  }, [applicationHelper, flow.id, slug]);

  const handlePaymentInput = useCallback(
    (changedValues: any, values: any) => {
      if (autoConfirmation) {
        setConfirmed(true);
        setDisabled(!elementComplete);
        return;
      }
      setConfirmed(values[confirmationKey]);
      setDisabled(!(values[confirmationKey] && elementComplete));
    },
    [elementComplete, autoConfirmation]
  );

  //generate paymeooiint intent secret and save to application
  useEffect(() => {
    if (!application) {
      return;
    }

    //skip payment intent creation if payment intent has already been created
    //and payment amount or subscription opt in has not changed
    if (
      savedClientSecret &&
      (!paymentAmountFieldKey ||
        !paymentAmountSlug ||
        paymentAmount === savedAmount) &&
      (!subscriptionOptInFieldKey ||
        !subscriptionOptInSlug ||
        subscriptionOptIn === savedOptIn)
    ) {
      setCurrentClientSecret(savedClientSecret);
      return;
    }

    //FUTURE: clean up association with old payment intent, subscription and application
    //if (savedClientSecret) {
    //cancel payment intent, subscription (if any) and clear assocciation with application here
    //}

    stripeApi
      .createPaymentIntent(
        !stripeSubscriptionConfigurationId || !subscriptionOptIn
          ? stripePaymentIntentConfigurationId
          : undefined,
        stripeSubscriptionConfigurationId && subscriptionOptIn
          ? stripeSubscriptionConfigurationId
          : undefined,
        stripeSubscriptionConfigurationId &&
          subscriptionOptIn &&
          stripeSetupIntentConfigurationId
          ? stripeSetupIntentConfigurationId
          : undefined,
        flow.id,
        "Flow",
        tokenHelper,
        paymentAmount,
        application.id,
        "OnboardingApplication",
        subscriptionTitle,
        subscriptionDescription
      )
      .then((res) => res.data)
      .then(({ id, clientSecret }) => {
        applicationHelper.update(application.id, {
          slug,
          type,
          fields: {
            [paymentIntentSecretKey]: clientSecret,
            [paymentIntentIdKey]: id,
            [paymentIntentAmountKey]: paymentAmount || "",
            [paymentIntentOptInKey]: subscriptionOptIn || "",
          },
        });
      })
      .catch(() => message.error("Error initializing payment"));
  }, [
    application,
    savedClientSecret,
    stripePaymentIntentConfigurationId,
    stripeSubscriptionConfigurationId,
    stripeSetupIntentConfigurationId,
    subscriptionOptIn,
    subscriptionOptInFieldKey,
    subscriptionOptInSlug,
    savedOptIn,
    flow.id,
    tokenHelper,
    paymentAmount,
    paymentAmountFieldKey,
    paymentAmountSlug,
    savedAmount,
    applicationHelper,
    slug,
    type,
    subscriptionTitle,
    subscriptionDescription,
  ]);

  if (!applicationHelper || !application) {
    return <Spin indicator={spinnerIcon} />;
  }

  return (
    <StepContentSection
      brand={brand}
      stepProgress={progress}
      title={assets.title ?? ""}
      description={assets.description ?? ""}
      previousSlug={assets.previousSlug}
      hideProgressBar={assets.hideProgressBar}
    >
      {/* Render Stripe once payment intent has been created and saved to application */}
      {savedClientSecret &&
      currentClientSecret &&
      savedClientSecret === currentClientSecret ? (
        <StripePaymentForm
          onValuesChange={handlePaymentInput}
          clientSecret={savedClientSecret}
          intentType="payment"
          returnUrl={`${window.location.origin}/flows/${flowId}?stepSlug=${nextSlug}`}
          appearance={{
            theme: "stripe",
            variables: {
              colorPrimary: brand.primaryColor,
              borderRadius: "5px",
            },
          }}
          stripeAccount={stripeAccountId}
        >
          <Row>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              {elementLoading && (
                <div>
                  <Spin indicator={spinnerIcon} />
                </div>
              )}
              <PaymentElement
                onChange={(e) => {
                  setElementComplete(e.complete);
                  setDisabled(!((autoConfirmation || confirmed) && e.complete));
                }}
                onReady={() => setElementLoading(false)}
                options={billingDetails}
              />
            </Col>
          </Row>
          <div className={`mt-6 flex`}>
            {!autoConfirmation && (
              <div className="pr-3">
                <Form.Item name={confirmationKey} valuePropName="checked">
                  <Checkbox defaultChecked={false} className="ao-lg-checkbox" />
                </Form.Item>
              </div>
            )}
            <div>
              <p
                className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
                dangerouslySetInnerHTML={{
                  __html: confirmationText ?? "",
                }}
              />
            </div>
          </div>
          <div className="mt-6 pt-4 bt b-lightPrimary">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabled}
                className="s-2 lift h-7 ph-5"
              >
                {submitButtonText} <RightOutlined />
              </Button>
            </Form.Item>
          </div>
        </StripePaymentForm>
      ) : (
        <div>
          <Spin indicator={spinnerIcon} />
        </div>
      )}
    </StepContentSection>
  );
}
