import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";
import { Form } from "@dreambigger/design-system";
import { useApplication } from "../api";
import { DocuSignStepAssets } from "@dreambigger/shared/src/types";
import {
  CheckCircleFilled,
  Loading3QuartersOutlined,
  EditFilled,
} from "@ant-design/icons";
import styles from "./steps.module.scss";
import { useRouter } from "next/router";
import { ProgressModal } from "../components";
import { Button } from "@dreambigger/design-system/src/components";

const spinnerIcon = <Loading3QuartersOutlined spin />;

export default function Docusign({ flow, step, brand, progress }: StepProps) {
  const router = useRouter();
  const { event, ...remainingQueryParams } = router.query;
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [refetchApp, setRefetchApp] = useState(false);
  const [docusignValues, setDocusignValues] = useState({
    signingUrl: "",
    envelopeId: "",
    status: "",
  });
  const { slug, type } = step;
  const assets: DocuSignStepAssets = step.assets;

  const applicationHelper = useApplication(flow.financialInstitution.id);

  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  // ---------------------- DOCUSIGN STEP METHODS -----------------------------------------------------
  // Generates a new DocuSign url and envelope for the user.
  const generateEnvelope = async (applicationId: string) => {
    const response = await applicationHelper.generateDocuSignSigningUrl({
      docuSignTemplateUuid: assets.docuSignTemplateUuid!,
      applicationId: applicationId,
      returnUrl: window.location.href,
    });

    const { signingUrl, envelope } = response.data;

    // Save these new details to the application.
    await applicationHelper.update(applicationId, {
      slug,
      type,
      fields: {
        envelopeId: envelope.id,
        status: envelope.status,
        signingUrl: signingUrl,
      },
    });
    // With the application updated, fetch the application again. This time, the signingUrl will be populated.
    setRefetchApp(true);
  };

  // Callback function for advancing user to next step.
  const nextStep = useCallback(() => {
    // Strip docusign query strings included via the redirect
    router.push({
      query: {
        ...remainingQueryParams,
        stepSlug: assets.nextSlug as string,
      },
    });
    return;
  }, [assets]);

  const runDocusign = async (applicationId: string) => {
    // Load application response for this step from database.
    const appResponse = await applicationHelper.findResponseByApplicationId(
      applicationId,
      slug
    );

    // Destructure the response.
    const { envelopeId, status, signingUrl } =
      (appResponse?.fields as Record<string, any>) || {};

    // Update state with the values saved to the database (triggering a re-render with the new values if anything has changed)
    setDocusignValues({
      envelopeId: envelopeId || "",
      status: status || "",
      signingUrl: signingUrl || "",
    });

    // If a docusign signingUrl has not already been saved to the application, generate one along with an envelope to put it in.
    if (!signingUrl && status !== "completed") {
      await generateEnvelope(applicationId).catch((error) => {
        console.error(error);
      });
      return;
    }

    // If the user has been redirected to this page by DocuSign because signing is complete, save the "completed" status to the application.
    // This could theoretically be spoofed by a malicious user, so use caution when using this data for automated approval processes.
    // We intentionally do not query DocuSign for an updated envelope status because DocuSign polling rules limit us to one status query of an envelope per 15 minutes.
    // The save strips the signingUrl from the application because it is no longer needed.
    if (event === "signing_complete" && envelopeId) {
      await applicationHelper.update(applicationId, {
        slug,
        type,
        fields: {
          envelopeId: envelopeId,
          status: "completed",
        },
      });
      // When saving is complete, open <ProgressModal>, which will redirect the user to the next step after a delay to provide uploading time to BoA.
      setShowProgressModal(true);
    }
    return;
  };

  // ---------------------- PROCESS INITIALIZATION --------------------------------------------------
  // Reset all fields. Run DocuSign methods upon application load
  useEffect(() => {
    setDocusignValues({
      signingUrl: "",
      envelopeId: "",
      status: "",
    });
    if (!application?.id) {
      return;
    }
    runDocusign(application.id).catch((error) => {
      console.error(error);
    });
  }, [application, slug, refetchApp]);

  // Set the "disable" button if the submission is not complete
  useEffect(() => {
    setDisabled(docusignValues.status !== "completed");
  }, [docusignValues.status]);

  // ----------------------- RENDER -----------------------------------------------------------------
  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps} handleNext={nextStep}>
      {assets.helpText1 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.helpText1 ?? "",
            }}
          />
        </div>
      )}

      <div className="flex items-center justify-center mb-6">
        <ProgressModal
          initialText={
            assets.modalInitialText ||
            "Please wait while we upload your document..."
          }
          completionText={
            assets.modalCompletionText || "Done! Now redirecting..."
          }
          onFinish={nextStep}
          showModal={showProgressModal}
          accentColor={brand.primaryColor}
          delayInterval={assets.modalDelayInterval || 5000}
        />

        {docusignValues.signingUrl || docusignValues.status === "completed" ? (
          <div
            className={`flex items-center f-6 md_f-7 mv-4`}
            style={{
              borderBottom: "solid transparent 1px",
              color:
                docusignValues.status === "completed" ? "green" : undefined,
            }}
          >
            {docusignValues.status === "completed" ? (
              <>
                <CheckCircleFilled className="mr-2" />
                <p className="mv-auto mr-2 tc ttu">Signature COMPLETE</p>
              </>
            ) : (
              <a href={docusignValues.signingUrl}>
                <Button
                  type="primary"
                  className={`f-5 h-7 md_f-6 md_h-8 s-2 mb-4 lift`}
                >
                  <EditFilled className="mr-2" />
                  {assets.ctaText || "Sign Your Documents"}
                </Button>
              </a>
            )}
          </div>
        ) : (
          <Spin indicator={spinnerIcon}></Spin>
        )}
      </div>

      {assets.helpText2 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.helpText2 ?? "",
            }}
          />
        </div>
      )}

      {assets.termsText && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.termsText ?? "",
            }}
          />
        </div>
      )}
    </StepWrapper>
  );
}
