import { DollarCircleOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { useState, useEffect, useMemo, useCallback } from "react";
import truncate from "truncate";

import { api } from "@dreambigger/shared/src/api/acquire";
import { message, Form, InputNumber } from "@dreambigger/design-system";
import {
  InitialDepositStepAssets,
  ResponseFields,
  ShareCategory,
  ShareProduct,
} from "@dreambigger/shared/src/types";

import StepWrapper from "./step-wrapper";
import { useApplication } from "../api";
import ShareApi from "../api/shares";
import { StepProps } from "../pages/flows/[flowId]";
import { formatNumber } from "../utils/textFormatting";

// Initialize the various api's we need for this step.
const shareApi = new ShareApi(api);

const placeholder = (min?: number | null, max?: number | null) => {
  const hasMin = !!min; // not null or undefined or 0
  const hasMax = max !== null && max !== undefined;
  if (hasMax) {
    max = Math.max(max!, min || 0);
  }
  if (hasMin && hasMax) {
    if (min === max) {
      return `${min.toFixed(2)}`;
    }
    return `${min.toFixed(2)} - ${max!.toFixed(2)}`;
  }
  if (hasMin) {
    return `${min.toFixed(2)} Minimum`;
  }
  if (hasMax) {
    return `${max!.toFixed(2)} Maximum`;
  }
  return "No minimum";
};

export interface DepositFields {
  [x: string]: string;
}

export default function StepInitialDeposit({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const { shareProductStepSlug }: InitialDepositStepAssets = step.assets;

  // State for managing whether the submit button is enabled.
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [categoryData, setCategoryData] = useState<ShareCategory[]>([]);
  const [productData, setProductData] = useState<
    Array<ShareProduct & { category: ShareCategory }>
  >([]);

  const [form] = Form.useForm();

  const totalDepositKey = "totalDeposit";

  const applicationHelper = useApplication(flow.financialInstitution.id);
  const shareProductsStepResponse = useMemo(() => {
    if (!shareProductStepSlug) {
      return;
    }
    return applicationHelper.findResponse(
      flow.id,
      "draft",
      shareProductStepSlug
    );
  }, [applicationHelper, flow, shareProductStepSlug]);

  const checkDisabled = useCallback(() => {
    if (!productData) {
      setDisabled(true);
    }
    // check if there are any products without a valid amount
    const values = form.getFieldsValue();
    const missingData = productData.some(
      (p) =>
        values[p.slug] === undefined ||
        values[p.slug] === null ||
        values[p.slug] < (p.minimumOpeningDeposit || 0) ||
        (p.maximumOpeningDeposit !== null &&
          p.maximumOpeningDeposit !== undefined &&
          values[p.slug] > p.maximumOpeningDeposit)
    );

    setDisabled(missingData);
  }, [form, productData]);

  // Load products using product categories API
  useEffect(() => {
    setProcessing(true);
    shareApi
      .getProductsByCategory(flow.financialInstitution.id)
      .then(({ data }) => {
        setProcessing(false);
        setCategoryData(data);
      })
      .catch((err) => {
        console.error(err);
        message.error(
          "There was a problem loading this page. Please contact support."
        );
      });
  }, [flow]);

  // Filter and load products in scope
  useEffect(() => {
    setProductData(
      categoryData.reduce(
        (
          acc: Array<ShareProduct & { category: ShareCategory }>,
          category: ShareCategory
        ) => {
          return acc.concat(
            category.products
              ?.filter((p) => shareProductsStepResponse?.fields[p.slug])
              .map((p) => ({ ...p, category })) || []
          );
        },
        []
      )
    );
  }, [categoryData, shareProductsStepResponse]);

  useEffect(() => {
    checkDisabled();
  }, [checkDisabled]);

  // As deposit amounts are entered, store the total to a hidden field on the form.
  const calculateTotalDeposit = (values: DepositFields) => {
    let sum = 0;
    for (const product of productData) {
      const value = parseFloat(values[product.slug]);
      if (value) {
        sum += value;
      }
    }
    form.setFieldsValue({ ...values, [totalDepositKey]: sum.toString() }); // Store as string because application expects a string
  };

  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    setDisabled(
      Object.values(values).some((v) => v === undefined || v === null)
    );
    calculateTotalDeposit(values as DepositFields);
  };

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
    processing,
    handleInput,
  };

  // ----- FINAL RENDER ------
  if (!productData.length) {
    return <></>;
  }

  return (
    <StepWrapper {...wrapperProps}>
      {categoryData &&
        productData
          .sort((a, b) =>
            a.category.sortOrder === b.category.sortOrder
              ? a.sortOrder - b.sortOrder
              : a.category.sortOrder - b.category.sortOrder
          )
          .map((product: ShareProduct) => {
            return (
              <div
                key={product.slug}
                className={`ph-5 pv-3 br-4 mb-2 s-2`}
                style={{
                  overflow: "hidden",
                  backgroundColor: "white",
                }}
              >
                <div className="fwb">
                  {/* TITLE */}
                  <div className="f-5 xl_f-6 fwt">{product.title}</div>
                </div>
                {/* DESCRIPTION */}
                {product.description && (
                  <div
                    className="pb-3 f-2 xxl_f-4 lh-3"
                    style={{ color: "gray" }}
                  >
                    {truncate(product.description, 150)}
                  </div>
                )}
                <Col xs={24} sm={16}>
                  <Form.Item name={product.slug} valuePropName={"value"}>
                    <InputNumber
                      id={product.slug}
                      className="w-100 ao-bl-input"
                      min={product.minimumOpeningDeposit || 0}
                      max={
                        product.maximumOpeningDeposit === null ||
                        product.maximumOpeningDeposit === undefined
                          ? Number.MAX_SAFE_INTEGER
                          : Math.max(
                              product.maximumOpeningDeposit,
                              product.minimumOpeningDeposit || 0
                            )
                      }
                      placeholder={placeholder(
                        product.minimumOpeningDeposit,
                        product.maximumOpeningDeposit
                      )}
                      stringMode={true}
                      step="0.01"
                      precision={2} //Rounds to nearest second decimal place.
                      formatter={(value) => (value ? formatNumber(value) : "")}
                      controls={false}
                      prefix={
                        <DollarCircleOutlined className="site-form-item-icon" />
                      }
                    />
                  </Form.Item>
                </Col>
              </div>
            );
          })}
      {/* HIDDEN TOTAL DEPOSIT FIELD - Updates as deposit amounts are entered */}
      <Form.Item name={totalDepositKey} valuePropName={"value"} hidden>
        <InputNumber
          id={totalDepositKey}
          className="w-100 ao-bl-input"
          disabled={true}
          precision={2}
          prefix={<DollarCircleOutlined className="site-form-item-icon" />}
        />
      </Form.Item>
    </StepWrapper>
  );
}
