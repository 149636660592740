import { AxiosInstance } from "axios";
import { ShareCategory } from "@dreambigger/shared/src/types";

// All the paths for this api domain.
export const sharePaths = {
  getProductsByCategory: (financialInstitutionId: string) =>
    `/financial-institutions/${financialInstitutionId}/share-categories`,
  getCategoryById: (financialInstitutionId: string, id: string) =>
    `/financial-institutions/${financialInstitutionId}/share-categories/${id}`,
};

class ShareApi {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  // Get share categories by institution id.
  // Get /financial-institutions/<id>/share-categories
  getProductsByCategory = (
    financialInstitutionId: string,
    onboardingApplicationId?: string
  ) => {
    const queryParams: { [key: string]: string } = {};

    if (onboardingApplicationId) {
      queryParams.onboardingApplicationId = onboardingApplicationId;
    }

    return this.api.get<any>(
      sharePaths.getProductsByCategory(financialInstitutionId),
      {
        params: queryParams,
      }
    );
  };

  // Get share categories by institution id.
  // Get /financial-institutions/<id>/share-categories/<id>
  getCategoryById = (financialInstitutionId: string, id: string) => {
    return this.api.get<ShareCategory>(
      sharePaths.getCategoryById(financialInstitutionId, id)
    );
  };
}

export default ShareApi;
