import React from "react";
import { useCallback, useState, useEffect, useMemo } from "react";
import { Row, Col } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  CustomStepAssets,
  ResponseFields,
  CustomFieldConfig,
  DisplayCondition,
  ResponseStep,
} from "@dreambigger/shared/src/types";
import { Conditional } from "@dreambigger/shared/src/utils";
import { StepProps } from "../pages/flows/[flowId]";
import { Form } from "@dreambigger/design-system";
import StepWrapper from "./step-wrapper";
import { formatAsNationalPhoneNum } from "../utils/textFormatting";
import CustomField from "../components/CustomField";
import { checkDisabledOnCustomFields } from "../utils/customField";
import { Button } from "@dreambigger/design-system/src/components";
import { useApplication } from "../api";
import {
  addressKeySuffix,
  apartmentKeySuffix,
  cityKeySuffix,
  stateKeySuffix,
  zipKeySuffix,
} from "../components/CustomField/CustomField";

export default function Custom({ flow, step, brand, progress }: StepProps) {
  const assets: CustomStepAssets = step.assets;
  const { allowRepeats, maxRepeats } = assets;

  // State for managing whether the submit button is enabled.
  const [disabled, setDisabled] = useState(true);
  const [totalRows, setTotalRows] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [form] = Form.useForm();
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  const response = useMemo(
    () => applicationHelper.findResponse(flow.id, "draft", step.slug),
    [application, flow, step]
  );
  // On receiving fresh data (such as on a step transition), disable the next button and reset the form.
  useEffect(() => {
    setDisabled(false);
    form.resetFields();
    setLoaded(false);
  }, [step, response]);

  // When the totalRows or step changes, render rows.
  useEffect(() => {
    renderRows();
  }, [totalRows, step, application]);

  // Whenever row render is updated, check if submit button should be enabled.
  useEffect(() => {
    // Wait for the form to update before checking disabled.
    setTimeout(() => {
      const values = form.getFieldsValue();
      checkDisabled(values);
      // After the initial rows have been loaded, set loaded to true.
      // This triggers an additional render cycle and will allow handleprefill to work correctly.
    }, 100);
    !loaded && setLoaded(true);
  }, [rows]);

  // Initially display correct number of rows based on response and prefill.
  useEffect(() => {
    // If repeats are not allowed or there is no response, set totalRows to 1.
    if (!allowRepeats || !response) {
      setTotalRows(1);
      return;
    }
    // Otherwise, check for pre-existing rows. Keys will have the suffix of __1, __2, etc.
    // Use regex to find the highest number and set that to the totalRows.
    let totalRows = 0;
    const regex = /__\d+$/;
    for (let key of Object.keys(response?.fields)) {
      if (regex.test(key)) {
        const number = parseInt(key.split("__")[1]);
        if (number > totalRows) {
          totalRows = number;
        }
      }
    }
    setTotalRows(totalRows + 1);
  }, [response]);

  // Check if the next button should be enabled.
  const checkDisabled = (values?: ResponseFields) => {
    checkDisabledOnCustomFields({
      values,
      customFields: assets.customFields,
      form,
      allowRepeats,
      setDisabled,
    });
  };

  // Code that is run every time the form changes.
  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    if (!assets.customFields) {
      return;
    }
    renderRows();
    //Disable if the field is required and there is no value. Else, enable.
    checkDisabled(values);
  };

  // Loads the form with data already saved in the response, modifying data to be in the format expected by the form.
  const handlePrefill = useCallback(
    (initialValues?: ResponseFields) => {
      if (!initialValues) {
        checkDisabled();
        return;
      }

      const modifiedValues: ResponseFields = {};
      for (let i = 0; i < totalRows; i++) {
        assets.customFields?.forEach((field) => {
          // If repeated fields are allowed, map through all rows and prefill the values.
          let fieldKey = field.fieldKey;
          // If repeated fields are allowed, append the row number to the field key.
          if (allowRepeats) {
            fieldKey = `${field.fieldKey}__${i}`;
          }
          if (!initialValues[fieldKey]) {
            return;
          }

          // convert prefill values for date controls to dayjs
          if (field.fieldType === "date") {
            modifiedValues[fieldKey] = dayjs(initialValues[fieldKey] as string);
            // Check if submit button should be enabled.
            // Short delay ensures that this check happens after disabled is set to "true" by default.
            setTimeout(() => {
              checkDisabled(modifiedValues);
            }, 100);
            return;
          }
          // convert prefill values for phone numbers to National Format - (xxx) xxx-xxxx
          if (field.fieldType === "phone") {
            modifiedValues[fieldKey] = formatAsNationalPhoneNum(
              initialValues[fieldKey].toString()
            );
            // Check if submit button should be enabled.
            // Short delay ensures that this check happens after disabled is set to "true" by default.
            setTimeout(() => {
              checkDisabled(modifiedValues);
            }, 100);
            return;
          }
          if (field.fieldType === "address") {
            modifiedValues[fieldKey] = initialValues[fieldKey];
            modifiedValues[`${fieldKey}__${addressKeySuffix}`] =
              initialValues[`${fieldKey}__${addressKeySuffix}`];
            modifiedValues[`${fieldKey}__${apartmentKeySuffix}`] =
              initialValues[`${fieldKey}__${apartmentKeySuffix}`];
            modifiedValues[`${fieldKey}__${cityKeySuffix}`] =
              initialValues[`${fieldKey}__${cityKeySuffix}`];
            modifiedValues[`${fieldKey}__${stateKeySuffix}`] =
              initialValues[`${fieldKey}__${stateKeySuffix}`];
            modifiedValues[`${fieldKey}__${zipKeySuffix}`] =
              initialValues[`${fieldKey}__${zipKeySuffix}`];
            // Check if submit button should be enabled.
            // Short delay ensures that this check happens after disabled is set to "true" by default.
            setTimeout(() => {
              checkDisabled(modifiedValues);
            }, 100);
            return;
          }
          modifiedValues[fieldKey] = initialValues[fieldKey];
        });
      }
      // Check if submit button should be enabled. See above.
      setTimeout(() => {
        checkDisabled(modifiedValues);
      }, 100);
      return modifiedValues;
    },
    [response, loaded]
  );

  // Used with steps that allowRepeat. Add a row.
  const handleAddRow = () => {
    setTotalRows(totalRows + 1);
  };

  // Used with steps that allowRepeat. When a row is deleted, shift all subsequent rows up by one.
  const handleDeleteRow = (deletedRowIndex: number) => {
    const formValues = form.getFieldsValue();
    assets.customFields?.forEach((field: CustomFieldConfig) => {
      for (
        let rowIndex: number = deletedRowIndex;
        rowIndex < totalRows;
        rowIndex++
      ) {
        const fieldName = `${field.fieldKey}__${rowIndex}`;
        if (rowIndex > deletedRowIndex) {
          const newFieldName = `${field.fieldKey}__${rowIndex - 1}`;
          formValues[newFieldName] = formValues[fieldName];
          if (field.fieldType === "address") {
            formValues[`${newFieldName}__${addressKeySuffix}`] =
              formValues[`${fieldName}__${addressKeySuffix}`];
            formValues[`${newFieldName}__${apartmentKeySuffix}`] =
              formValues[`${fieldName}__${apartmentKeySuffix}`];
            formValues[`${newFieldName}__${cityKeySuffix}`] =
              formValues[`${fieldName}__${cityKeySuffix}`];
            formValues[`${newFieldName}__${stateKeySuffix}`] =
              formValues[`${fieldName}__${stateKeySuffix}`];
            formValues[`${newFieldName}__${zipKeySuffix}`] =
              formValues[`${fieldName}__${zipKeySuffix}`];
          }
        }
        formValues[fieldName] = undefined;
        if (field.fieldType === "address") {
          formValues[`${fieldName}__${addressKeySuffix}`] = undefined;
          formValues[`${fieldName}__${apartmentKeySuffix}`] = undefined;
          formValues[`${fieldName}__${cityKeySuffix}`] = undefined;
          formValues[`${fieldName}__${stateKeySuffix}`] = undefined;
          formValues[`${fieldName}__${zipKeySuffix}`] = undefined;
        }
      }
    });
    setTotalRows(totalRows - 1);
    form.setFieldsValue(formValues);
  };

  // Determines if a field should be displayed or hidden.
  // Uses the rules defined in step assets for the custom field and compares to the values in the form.
  const shouldDisplayField = (
    customField: CustomFieldConfig,
    values: ResponseFields,
    nameSuffix?: string
  ) => {
    // HELPER METHODS -----------------------------------------------------
    // * Evaluate a condition. A condition can be based on a value in the response and/or
    //   a value in the UTM parameters.
    const evaluateCondition = (condition: DisplayCondition): boolean => {
      let savedResponse: ResponseStep | undefined;
      if (condition.slug && condition.slug !== step.slug) {
        savedResponse = application?.responses.find(
          (response) => response.slug === condition.slug
        );
      }
      const fields = savedResponse?.fields || values;

      const fieldKey = nameSuffix
        ? `${condition?.field?.key}__${nameSuffix}`
        : condition?.field?.key;

      const utmParams = application?.utmParams;

      // If applicable, evaluate the provided condition from the response data.
      const responseEvaluation = Conditional.evaluate({
        conditions: { [fieldKey]: condition?.field?.value },
        data: fields,
      });

      // If applicable, evaluate the provided condition from the utm parameters.
      const utmParam = condition.utmParam;
      const utmEvaluation =
        utmParams && utmParam?.key && utmParam.value
          ? Conditional.evaluate({
              conditions: { [utmParam?.key]: utmParam?.value },
              data: utmParams,
            })
          : true;

      // Return true if both the response and utm conditions are met.
      return responseEvaluation && utmEvaluation;
    };

    // MAIN METHOD --------------------------------------------------------
    // If no rules are defined, always display the field.
    if (!customField.display) {
      return true;
    }

    // Define the conditions for disiplaying the field using the display config. If no conditions are defined, always display the field.
    const conditions = customField.display;
    if (!conditions || conditions.length === 0) {
      return true;
    }

    // Evaluate condition groups when conditions is an array of arrays.
    return conditions.some((condition) => {
      // Arrays are evaluated as AND conditions. All conditions must be true to display the field.
      if (Array.isArray(condition)) {
        return condition.every(evaluateCondition);
      }
      // Individual objects are evaluated as OR conditions. Only one condition must be true to display the field.
      return evaluateCondition(condition);
    });
  };

  // Render rows based on the totalRows or after data has finished loading.
  const renderRows = () => {
    // Define an empty array to hold the rows to be rendered.
    const rowsList: any = [];
    // On initial load, utilize the response to prefill the form if available. Otherwise, use the current form values.
    const values =
      !loaded && response ? response?.fields : form.getFieldsValue();
    for (let r = 0; r < totalRows; r++) {
      // For each row, check each possible field against the rules in step assets to see if it should be displayed.
      assets.customFields?.forEach((field, index, row) => {
        const fieldsToBeRendered = Object.values(row).filter((rowField) =>
          shouldDisplayField(
            rowField,
            values,
            allowRepeats ? r.toString() : undefined
          )
        );

        // If a field should be shown, then add it to the array of rows to be rendered.
        if (fieldsToBeRendered.includes(field)) {
          rowsList.push(
            <div
              style={
                allowRepeats && totalRows > 1
                  ? {
                      borderLeft: `solid 1px ${brand.primaryColor}`,
                      paddingLeft: "24px",
                    }
                  : {}
              }
              key={`${field.fieldKey}-${r}-${index}`}
            >
              <CustomField
                field={field}
                index={index}
                form={form}
                brand={brand}
                nameSuffix={allowRepeats ? r.toString() : undefined}
                readonly={field.readonly}
              />
            </div>
          );
        }
      });
      // If repeats are allowed, add a delete button and spacing to the beginning of every subsequent row.
      if (allowRepeats && totalRows > 1) {
        rowsList.push(
          <React.Fragment key={`row-${r}`}>
            <div
              style={
                allowRepeats && totalRows > 1
                  ? {
                      borderLeft: `solid 1px ${brand.primaryColor}`,
                      paddingLeft: "24px",
                    }
                  : {}
              }
            >
              <Button
                className="s-2 ph-4 f-2 items-center"
                onClick={() => handleDeleteRow(r)}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </div>
            <div className="mb-8" key={`spacer-${r}`} />
          </React.Fragment>
        );
      }
    }
    // Update the rowsList state with the array of rows to be rendered, triggering a re-render.
    setRows(rowsList);
  };

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    handlePrefill,
    disabled,
  };

  // ----- FINAL RENDER ------
  // Wait for application before rendering
  if (!application) {
    return <></>;
  }

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col xs={24}>{rows}</Col>

        {allowRepeats && (!maxRepeats || totalRows < maxRepeats) && (
          <Button type="dashed" onClick={handleAddRow} icon={<PlusOutlined />}>
            Add
          </Button>
        )}
        {/* OPTIONAL DISCLAIMER TEXT - "By clicking submit..." */}
        {(assets.disclaimerTitle || assets.disclaimerText) && (
          <Col className="mt-4">
            {assets.disclaimerTitle && (
              <h3
                className="fwt"
                dangerouslySetInnerHTML={{
                  __html: assets.disclaimerTitle,
                }}
              />
            )}
            {assets.disclaimerText && (
              <p
                className="f-4 gray-8 mb-0 lh-3"
                dangerouslySetInnerHTML={{
                  __html: assets.disclaimerText,
                }}
              />
            )}
          </Col>
        )}
      </Row>
    </StepWrapper>
  );
}
