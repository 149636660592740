import { useState, useCallback, useMemo, useEffect } from "react";
import { Form } from "@dreambigger/design-system/src/components";
import { VouchedIDVStepAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import styles from "./steps.module.scss";
import StepWrapper from "./step-wrapper";
import { useApplication } from "../api";
import { message } from "antd";
import { changeStepForward } from "../utils/changeStepForward";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { Button } from "@dreambigger/design-system/src/components";
import { VouchedIDV } from "../components";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CameraFilled,
  TabletFilled,
} from "@ant-design/icons";

export interface VouchedResponse {
  errors: string[];
  id: string;
  result: {
    success: boolean;
  };
}

export default function IdvVouched({ flow, step, brand, progress }: StepProps) {
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [deviceHandoff, setDeviceHandoff] = useState<undefined | boolean>();
  const [verificationResult, setVerificationResult] =
    useState<VouchedResponse>();
  const [verificationCompleted, setVerificationCompleted] = useState(false);
  const [form] = Form.useForm();
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  const { slug, type } = step;
  const segment = useSegment();
  const ctaButtonClasses = "f-5 h-7 md_f-6 md_h-8 s-2 mb-4 lift";
  const assets: VouchedIDVStepAssets = step.assets;
  const {
    allowContinueOnError,
    helpText1,
    helpText2,
    nextSlug,
    errorText,
    config,
    desktopButtonText,
    termsText,
    mobileButtonText,
    maskError,
  } = assets;

  // Upon transitioning to step, check if verification has already been completed and load results.
  useEffect(() => {
    if (!application) return;
    let initialData: any;
    for (const response of application.responses) {
      if (response.slug === slug) {
        const { vouchedJobId: id, vouchedVerificationSuccess: success } =
          response.fields;
        initialData = id && { id, result: { success } };
      }
    }
    if (initialData) {
      setVerificationResult(initialData);
      setVerificationCompleted(true);
    }
  }, [application]);

  // Upon verification complete, save to application
  useEffect(() => {
    {
      // If there is no verificationResult, check has not been run. Return.
      if (!verificationResult) {
        return;
      }
      // Save if the verification has not yet been completed.
      !verificationCompleted && handleSave();
      // If verification was successful or the user is allowed to continue on error, allow user to continue.
      if (verificationResult?.result?.success || allowContinueOnError) {
        setDisabled(false);
      }
    }
  }, [verificationResult]);

  const handleSave = () => {
    if (!verificationResult) {
      message.error("Unable to save. Please refresh the page and try again.");
      return;
    }

    let formattedData: any = {
      vouchedJobId: verificationResult?.id,
      vouchedVerificationSuccess: verificationResult?.result?.success,
    };

    if (!application) {
      message.error("Unable to find application");
      return;
    }

    applicationHelper.update(application.id, {
      slug,
      type,
      fields: formattedData,
    });
    setVerificationCompleted(true);
  };

  // Advance to the next step and track analytics.
  const handleNext = () => {
    segment.track({
      action: "Button Click",
      label: `Submit - ${slug}`,
      properties: { IDV: "Successful" },
    });
    nextStep();
  };

  // Callback function for advancing user to next step.
  const nextStep = useCallback(() => {
    changeStepForward(nextSlug);
  }, [assets]);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps} handleNext={handleNext}>
      {helpText1 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4`}
            dangerouslySetInnerHTML={{
              __html: helpText1 ?? "",
            }}
          />
        </div>
      )}

      <div className={`flex flex-column justify-center items-center pt-4`}>
        {/* If verified display the result. Otherwise give option to load IDV */}
        {verificationResult ? (
          // Message displayed on success
          verificationResult.result.success || maskError ? (
            <div
              className="flex items-center f-6 md_f-7 mb-4"
              style={{ color: "green" }}
            >
              <CheckCircleFilled />
              <p className="ml-2 mb-0">Verification Completed</p>
            </div>
          ) : (
            // Message displayed on failure.
            <div style={{ color: "red" }}>
              <div className="flex items-center lh-2 mb-4">
                (
                <CloseCircleFilled className="f-7" />)
                <div className="flex flex-column ml-2">
                  <p className="mb-0 f-7">
                    Verification Could Not Be Completed.
                  </p>
                  <p className="fwb mb-0">
                    {errorText ||
                      "Please go back to the previous step and verify your ID through another option."}
                  </p>
                </div>
              </div>
            </div>
          )
        ) : (
          <>
            <Button
              type="primary"
              onClick={() => {
                setDeviceHandoff(false);
                setShowModal(true);
              }}
              className={`${ctaButtonClasses}`}
            >
              <CameraFilled />{" "}
              {desktopButtonText
                ? desktopButtonText
                : "Verify Using Your Webcam"}
            </Button>

            <Button
              className={`${ctaButtonClasses} dn lg_db`}
              type="primary"
              onClick={() => {
                setDeviceHandoff(true);
                setShowModal(true);
              }}
            >
              <TabletFilled />{" "}
              {mobileButtonText ? mobileButtonText : "Verify Using Your Phone"}
            </Button>
            {deviceHandoff !== undefined && (
              <VouchedIDV
                showModal={showModal}
                setShowModal={setShowModal}
                setVerificationResult={setVerificationResult}
                brand={brand}
                deviceHandoff={deviceHandoff}
                application={application}
                config={config}
              />
            )}
          </>
        )}
      </div>

      {helpText2 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: helpText2,
            }}
          />
        </div>
      )}

      {termsText && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: termsText,
            }}
          />
        </div>
      )}
    </StepWrapper>
  );
}
