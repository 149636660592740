import { useState, useCallback } from "react";
import dayjs from "dayjs";
import { Row, Col, InputNumber, Input } from "antd";
import { Form, DatePicker } from "@dreambigger/design-system/src/components";
import { DollarCircleOutlined } from "@ant-design/icons";
import {
  EmploymentStepAssets,
  ResponseFields,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import StepWrapper from "./step-wrapper";

export const incomeKey = "income";
export const employerKey = "employer";
export const startKey = "start";

export default function Employment({ flow, step, brand, progress }: StepProps) {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const assets: EmploymentStepAssets = step.assets;

  // Upon load, prefill inputs with stored responses.
  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    // Define modifiedValues const to be returned in place of initialValues.
    const modifiedValues: ResponseFields = {};

    for (const field in initialValues) {
      // DatePicker component requires a dayjs object instead of a string, so convert.
      if (field === startKey) {
        modifiedValues[startKey] = dayjs(initialValues[startKey] as string);
      } else {
        modifiedValues[field] = initialValues[field];
      }
    }

    return modifiedValues;
  }, []);

  const handleInput = (
    changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    setDisabled(
      !(
        typeof values[incomeKey] === "number" &&
        values[incomeKey] > 0 &&
        values[employerKey] &&
        values[startKey]
      )
    );
  };

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    handlePrefill,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col xs={24} md={16}>
          <label>{assets.employerTitle}</label>
          <Form.Item name={employerKey}>
            <Input
              placeholder={assets.employerPlaceholder}
              required
              className="ao-bl-input"
            />
          </Form.Item>

          <span>
            <label>{assets.startDateTitle}</label>
            <Form.Item name={startKey}>
              <DatePicker
                placeholder={assets.startDatePlaceholder}
                className="w-100 ao-bl-picker"
              />
            </Form.Item>
          </span>

          <span>
            <label>{assets.monthlyIncomeTitle}</label>
            <Form.Item name={incomeKey}>
              <InputNumber
                className="w-100 mb-0 ao-bl-input"
                min={0}
                placeholder={assets.monthlyIncomePlaceholder}
                stringMode={true}
                step="0.01"
                precision={2} //Rounds to nearest second decimal place.
                prefix={
                  <DollarCircleOutlined className="site-form-item-icon" />
                }
              />
            </Form.Item>
          </span>
        </Col>
      </Row>
    </StepWrapper>
  );
}
