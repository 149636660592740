import { useState, useCallback, useMemo } from "react";
import { Form } from "@dreambigger/design-system/src/components";
import { StripeIdentityFormItem } from "../components";
import {
  GovtIdStepAssets,
  ResponseFields,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import styles from "./steps.module.scss";
import StepWrapper from "./step-wrapper";
import { useApplication } from "../api";

export const stripeKey = "stripeVerificationSessionId";

export default function GovtId({ flow, step, brand, progress }: StepProps) {
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  const assets: GovtIdStepAssets = step.assets;

  const handleInput = (
    changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    setDisabled(!values[stripeKey]);
  };

  // Upon load, prefill inputs with stored responses.
  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    // Define modifiedValues const to be returned in place of initialValues.
    const modifiedValues: ResponseFields = {};

    for (const field in initialValues) {
      modifiedValues[field] = initialValues[field];
    }

    return modifiedValues;
  }, []);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    handlePrefill,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps}>
      {assets.helpText1 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.helpText1 ?? "",
            }}
          />
        </div>
      )}

      <Form.Item name={stripeKey}>
        <StripeIdentityFormItem
          publicKey={assets.identityVerificationServicePublicKey}
          financialInstitutionUuid={flow.financialInstitution.id}
          configurationUuid={
            assets.identityVerificationServiceConfigurationUuid
          }
          entityUuid={application?.id}
          entityType={application?.id && "OnboardingApplication"}
          ctaText={assets.ctaText}
        />
      </Form.Item>

      {assets.helpText2 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.helpText2 ?? "",
            }}
          />
        </div>
      )}

      {assets.termsText && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.termsText ?? "",
            }}
          />
        </div>
      )}
    </StepWrapper>
  );
}
