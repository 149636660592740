import parsePhoneNumber from "libphonenumber-js";

export const convertCamel = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const capitalizeFirstLetter = (text: any) => {
  if (typeof text !== "string") {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatAsCurrency = (value: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat("en-US").format(value);
};

export const hashString = (value: string, minusLength: number = 0) => {
  let hashedString = "";
  for (let i = 0; i < value.length; i++) {
    if (i < value.length - minusLength) {
      hashedString = hashedString + (value[i] !== "-" ? "X" : "-");
    } else {
      hashedString = hashedString + value[i];
    }
  }
  return hashedString;
};

/**
 * Returns the phone number in the national format
 *  example of returned value: +1555-555-5555 => (555) 555-5555
 */
export const formatAsNationalPhoneNum = (phoneNumber?: string) => {
  if (!phoneNumber) return "";
  const parsedNumber = parsePhoneNumber(phoneNumber);

  if (parsedNumber) {
    return parsedNumber.formatNational();
  }

  return phoneNumber;
};
