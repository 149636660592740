import { useCallback, useState, useEffect, useMemo } from "react";
import { Col, Radio, message } from "antd";
import {
  LoanRateAssets,
  ResponseFields,
  RateOption,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import { Form } from "@dreambigger/design-system";
import { SliderWithDisplay } from "../components";
import StepWrapper from "./step-wrapper";
import { useApplication } from "../api";
import { changeStepForward } from "../utils/changeStepForward";
import { useSegment } from "@dreambigger/shared/src/hooks";

export default function StepLoanRate({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const assets: LoanRateAssets = step.assets;

  type PackagedData = {
    loanAmount: number;
    rate: number;
    termInMonths: number;
    fees: number;
    monthlyPayment: string;
    loanKey: number;
  };

  // State for managing whether the submit button is enabled.
  const [loanAmount, setLoanAmount] = useState<number>(assets.min || 5000);
  const [selectedRateOption, setSelectedRateOption] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const { slug, type } = step;
  const { rateOptions, nextSlug } = assets;
  const segment = useSegment();

  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  // Ensure that disabled is always set to default to "true" upon step transition,
  // even when coming from another custom step where the component does not re-render.
  useEffect(() => {
    setDisabled(true);
    checkDisabled();
  }, [step]);

  //sends packaged data to application
  const handleNext = () => {
    if (!application) {
      message.error("Unable to find application");
      return;
    }
    if (!rateOptions) {
      message.error(
        "There was a problem saving this form. Please contact support."
      );
      return;
    }

    const chosenRate = rateOptions[selectedRateOption];
    const packagedData: PackagedData = {
      loanAmount: loanAmount,
      rate: chosenRate.rate,
      termInMonths: chosenRate.termInMonths,
      fees: chosenRate.fees,
      monthlyPayment: monthlyPayment(loanAmount, chosenRate)
        .toFixed(2)
        .toLocaleString(),
      loanKey: selectedRateOption,
    };

    applicationHelper
      .update(application.id, {
        slug,
        type,
        fields: packagedData,
      })
      .then(() => {
        changeStepForward(nextSlug);
      });
    segment.track({
      action: "Button Click",
      label: `Submit - ${slug}`,
      properties: packagedData,
    });
  };

  // In our render for this view, render the custom field based upon the type configured in the json.
  const checkDisabled = () => {
    // If all fields are empty, check if the fields are required.
    // if (!values) {
    //   const checkEmptyFields = assets.customFields.some(
    //     (field) => !!field.fieldRequired
    //   );
    //   setDisabled(checkEmptyFields);
    //   return;
    // }
    // If there are at least some values, check if any required field is still empty.
    // const remainingEmptyFieldsRequired = assets.customFields.some(
    //   (field) => !!field.fieldRequired && !values[field.fieldKey]
    // );
    // if (remainingEmptyFieldsRequired) {
    //   setDisabled(true);
    //   return;
    // }

    // If the code gets here, all conditions have been met and the next button should be enabled.
    setDisabled(false);
  };

  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    if (values.loanAmount) {
      setLoanAmount(Number(values.loanAmount));
    }
    if (values.loanKey) {
      setSelectedRateOption(Number(values.loanKey));
    }

    //Disable if the field is required and there is no value. Else, enable.
    checkDisabled();
  };

  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    // Define modifiedValues const to be returned in place of initialValues.
    // const modifiedValues: ResponseFields = {};

    // for (const field in initialValues) {
    //   modifiedValues[field] = initialValues[field];
    // }

    checkDisabled();

    return initialValues;
  }, []);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    handlePrefill,
    disabled,
    handleNext,
  };

  // ----- RENDER ------

  if (!assets.rateOptions) {
    return (
      <p>
        There was an error loading this page. Please contact support to finish
        your application.
      </p>
    );
  }
  return (
    <StepWrapper {...wrapperProps}>
      <Col xs={24}>
        <Form.Item name="loanAmount">
          <SliderWithDisplay
            min={assets.min || 5000}
            max={assets.max || 100000}
            textColor={brand.primaryColor}
            sliderColor={brand.primaryColor}
          />
        </Form.Item>
        <h2 className="fwt gray-8">Choose Your Rate</h2>
        <Form.Item name="loanKey">
          <Radio.Group className="w-100" defaultValue={0}>
            {assets.rateOptions?.map((rateOption: RateOption, index) => {
              const payment = monthlyPayment(loanAmount, rateOption);
              const totalInterestPayment =
                payment * rateOption.termInMonths -
                loanAmount -
                (rateOption.fees || 0);
              return (
                <div
                  key={rateOption.rate + index}
                  className={`pt-2 ${
                    index !== assets.rateOptions!.length - 1 && "bb"
                  }`}
                  style={{ borderColor: "#bfbfbf" }}
                >
                  {rateOption.callout && (
                    <p className="mb-0 f-5 fwt primary">{rateOption.callout}</p>
                  )}
                  <Radio
                    key={index}
                    value={index} // radio values must always be strings.
                    className="pa-2 ao-lg-radio lightenPrimaryBkd lightenPrimaryBkdChecked w-100 subtleLift br-4 mb-2 checkedFill"
                    onChange={() => setSelectedRateOption(index)}
                  >
                    {/* Payment Per Month */}
                    <div className="f-5">{`${payment.toLocaleString("en-us", {
                      style: "currency",
                      currency: "USD",
                    })} / Month`}</div>
                    {/* Rate: <>% APR - Total Fees $<> - Total Interest Payment $<> */}
                    <div className="f-2 mt-1">
                      {rateOption.rate}% APR - {rateOption.termInMonths} months
                      - Total Fees: ${rateOption.fees} - Total Interest Payment:{" "}
                      {totalInterestPayment.toLocaleString("en-us", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </div>
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </Form.Item>
      </Col>
    </StepWrapper>
  );
}

const monthlyPayment = (
  loanAmount: number,
  { rate, termInMonths: n, fees }: RateOption
) => {
  const P = loanAmount + (fees || 0);
  if (rate === 0) {
    return P / n;
  }
  const r = rate / 100 / 12;
  const rateFactor = (1 + r) ** n;
  return (P * (r * rateFactor)) / (rateFactor - 1);
};
