import Router, { NextRouter } from "next/router";
import { ConditionalNextSlug } from "@dreambigger/shared/src/types/acquire"
import { message } from "antd";
import { Conditional } from "@dreambigger/shared/src/utils";

export const changeStepForward = (nextSlug?: string | ConditionalNextSlug, inputFields?: any) => {
  const router: NextRouter = Router;

  if (!nextSlug) {
    message.error("There was an error moving to the next step. Please contact support.");
    return;
  }

  //CASE 1 -- NEXTSLUG HAS BEEN CONFIGURED AS A STRING
  if (typeof nextSlug === 'string') {
    router.push({
      query: { 
        ...router.query, 
        stepSlug: nextSlug
      },
    });
    return;
  }

  //CASE 2 -- NEXTSLUG HAS BEEN CONFIGURED AS A CONDITIONAL SLUG
  
  //Check for immediate configuration errors
  if (!nextSlug.matchFieldKey || (!inputFields && !nextSlug.defaultSlug)) {
    message.error("There was an error moving to the next step. Please contact support.");
    return; 
  }
    
  const { matchFieldKey, matchFieldList, defaultSlug }  = nextSlug;

  //If no inputFields have been provided, route the user to a defaultSlug.
  if (!inputFields) {
    router.push({
        query: { 
          ...router.query, 
          stepSlug: defaultSlug
        },
      });
      return; 
  }
    
  //Search JSON-configured list (matchFieldList) for a fieldValue that matches the value 
  //passed in from the inputField specifically tagged for Step by matchFieldKey.
  //IE: inputValue[matchFieldKey] "state" might match up with fieldValue "CA" and lead to a slug.
  const matchItem = matchFieldList.find((listItem) =>
  Conditional.evaluate({
    conditions: {
      [listItem.matchFieldKey || matchFieldKey]: listItem.fieldValue,
    },
    data: inputFields,
  })
);
  
  //If there is no match but there is a defaultSlug, route the user there.
  if(!matchItem && defaultSlug) {
    router.push({
      query: { 
        ...router.query, 
        stepSlug: defaultSlug
      },
    });
    return; 
  }

  //If there is no match and no configured defaultSlug, alert the user.
  if(!matchItem) {
    message.error("There was an error moving to the next step. Please contact support.")
    return;
  } 
  
  //Otherwise, there is a match! Route the user to the corresponding slug.
  router.push({
    query: { 
      ...router.query, 
      stepSlug: matchItem.slug
    },
  });
  return;
}


