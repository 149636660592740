import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import Error from "next/error";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import {
  Step,
  Flow,
  Skin,
  OnboardingApplication,
} from "@dreambigger/shared/src/types";
import { LocalStorage } from "@dreambigger/shared/src/utils";
import { changeStepForward } from "../utils/changeStepForward";
import {
  StepAddress,
  StepGovtId,
  StepName,
  StepAdditionalContact,
  StepSSN,
  StepEmployment,
  StepAdditionalIncome,
  StepInitialDeposit,
  StepSuccess,
  StepCustom,
  StepPayment,
  StepPaymentStatus,
  StepPlaid,
  StepPlaidAuth,
  StepPlaidIdv,
  StepPlaidIncome,
  StepCar,
  StepReview,
  StepPeople,
  StepLoanRate,
  StepPocConsolidateDebt,
  StepPocRefinanceDebt,
  StepIdvVouched,
  StepDocusign,
  StepShareProducts,
  StepCdInterest,
  StepExistingMember,
  StepExistingCoreAccounts,
  StepExistingMemberShares,
  StepHTML,
  StepAtomic,
  StepTransition,
} from "../steps";
import { useAuthToken } from "../passwordless-auth";
import { useApplication } from "../api";
import { emailKey, phoneKey, skipAheadKey } from "../pages/flows/[flowId]";

const spinnerIcon = <Loading3QuartersOutlined spin />;

// Get the progress of the current step
const getStepProgress = (currentSkin: Skin, stepSlug: string) => {
  const totalSteps = currentSkin.steps.length + 1;
  const stepIndex =
    currentSkin.steps.findIndex((step) => step.slug === stepSlug) + 1;
  return Math.round((stepIndex / totalSteps) * 100);
};

// Render non-start step components based on type
const getStepElement = (
  step: Step,
  skin: Skin,
  flow: Flow,
  application: OnboardingApplication | undefined
) => {
  const stepProps = {
    step,
    brand: skin.brand,
    flow,
    progress: getStepProgress(skin, step.slug),
    application: application,
  };
  switch (step.type) {
    case "name":
      return <StepName {...stepProps} />;
    case "additional-contact":
      return <StepAdditionalContact {...stepProps} />;
    case "address":
      return <StepAddress {...stepProps} />;
    case "ssn":
      return <StepSSN {...stepProps} />;
    case "idv-vouched":
      return <StepIdvVouched {...stepProps} />;
    case "govt-id":
      return <StepGovtId {...stepProps} />;
    case "employment":
      return <StepEmployment {...stepProps} />;
    case "additional-income":
      return <StepAdditionalIncome {...stepProps} />;
    case "initial-deposit":
      return <StepInitialDeposit {...stepProps} />;
    case "custom":
      return <StepCustom {...stepProps} />;
    case "payment":
      return <StepPayment {...stepProps} />;
    case "payment-status":
      return <StepPaymentStatus {...stepProps} />;
    case "plaid":
      return <StepPlaid {...stepProps} />;
    case "plaid-auth":
      return <StepPlaidAuth {...stepProps} />;
    case "plaid-idv":
      return <StepPlaidIdv {...stepProps} />;
    case "plaid-income":
      return <StepPlaidIncome {...stepProps} />;
    case "car":
      return <StepCar {...stepProps} />;
    case "review":
      return <StepReview {...stepProps} />;
    case "people":
      return <StepPeople {...stepProps} />;
    case "success":
      return <StepSuccess {...stepProps} />;
    case "loan-rate":
      return <StepLoanRate {...stepProps} />;
    case "poc-consolidate-debt":
      return <StepPocConsolidateDebt {...stepProps} />;
    case "poc-refinance-debt":
      return <StepPocRefinanceDebt {...stepProps} />;
    case "docusign":
      return <StepDocusign {...stepProps} />;
    case "share-products":
      return <StepShareProducts {...stepProps} />;
    case "cd-interest":
      return <StepCdInterest {...stepProps} />;
    case "existing-member":
      return <StepExistingMember {...stepProps} />;
    case "existing-core-accounts":
      return <StepExistingCoreAccounts {...stepProps} />;
    case "existing-member-shares":
      return <StepExistingMemberShares {...stepProps} />;
    case "html":
      return <StepHTML {...stepProps} />;
    case "atomic":
      return <StepAtomic {...stepProps} />;
    case "transition":
      return <StepTransition {...stepProps} />;
    default:
      return <Error statusCode={404} />;
  }
};

export type StepRouterProps = {
  step: Step;
  skin: Skin;
  flow: Flow;
  startStep: Step | undefined;
};

export default function StepRouter({
  step,
  skin,
  flow,
  startStep,
}: StepRouterProps) {
  const router = useRouter();
  const { prefillRequestUuid, ...otherQueryParams } = router.query;
  const tokenHelper = useAuthToken(flow.financialInstitution.id);
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const localStorage = useMemo(
    () => new LocalStorage(flow.financialInstitution.id),
    [flow.financialInstitution.id]
  );

  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  // Filter out UTM Params from router query params.
  const utmParams = Object.keys(router.query)
    .filter((key) => key.toLowerCase().startsWith("utm_"))
    .reduce((utmObj: Record<string, any>, key) => {
      utmObj[key] = router.query[key];
      return utmObj;
    }, {});

  // --- CREATE APPLICATION ---
  // Create application on step load if conditions are met.
  useEffect(() => {
    // Wait for startStep to be defined
    if (!startStep) return;
    // Define if step is valid to create application:
    // Must not be success step and must be the first intended step in the workflow (following landing page or without a previousSlug)
    const stepIsValidToCreateApplication =
      step.type !== "success" &&
      (!step.assets.previousSlug ||
        step.assets.previousSlug === startStep.slug);
    // Check that step is valid, that application doesn't already exist, and that the token is not expired before creating application.
    if (
      !stepIsValidToCreateApplication ||
      !applicationHelper.data ||
      application ||
      tokenHelper.jwtIsExpired()
    ) {
      return;
    }
    // Create the application
    const emailField = {
      [emailKey]: localStorage.getItem(emailKey),
    };
    const phoneField = {
      [phoneKey]: localStorage.getItem(phoneKey),
    };

    applicationHelper.create(
      flow.id,
      {
        slug: startStep.slug,
        type: startStep.type,
        fields:
          flow.financialInstitution.loginMode === "email"
            ? emailField
            : phoneField,
      },
      utmParams,
      {
        prefillRequestUuid: Array.isArray(prefillRequestUuid)
          ? prefillRequestUuid[0]
          : prefillRequestUuid,
      }
    );

    //remove prefillRequestUuid from query params if it exists
    router.replace(
      {
        query: {
          ...otherQueryParams,
        },
      },
      {
        query: {
          ...otherQueryParams,
        },
      },
      {
        shallow: true,
      }
    );
  }, [
    applicationHelper.data,
    application,
    step,
    startStep,
    tokenHelper.jwtIsExpired(),
  ]);

  // Close application if draft application exists and prefillRequestUuid is present in the query parameters
  useEffect(() => {
    if (!applicationHelper.data || !application || !prefillRequestUuid) {
      return;
    }
    applicationHelper.close(application);
  }, [applicationHelper.data, application, prefillRequestUuid]);

  // --- SKIP AHEAD UPON LOGIN WITH OTPID ---
  // This process is initiated on start.tsx
  // Only begins if a user tries to log in with an otpId.
  useEffect(() => {
    if (!application) {
      return;
    }
    const skipAhead = localStorage.getItem(skipAheadKey);
    if (skipAhead !== "true") {
      return;
    }
    localStorage.removeItem(skipAheadKey);

    // If lastStepVisited is undefined or the same as the first step or current step, do nothing
    // Otherwise, send the user to their most recently visited slug.
    if (
      !application?.lastVisitedSlug ||
      application.lastVisitedSlug === startStep?.slug ||
      application.lastVisitedSlug === step.slug
    ) {
      return;
    }
    changeStepForward(application?.lastVisitedSlug);
  }, [application, step, startStep]);

  return prefillRequestUuid ? (
    <Spin indicator={spinnerIcon} />
  ) : (
    getStepElement(step, skin, flow, application)
  );
}
