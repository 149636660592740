import { useCallback, useState, useEffect, useMemo } from "react";
import { Radio } from "antd";
import {
  ResponseFields,
  ShareCategory,
  CDAssets,
  ShareProduct,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import { Form } from "@dreambigger/design-system";
import { SliderWithDisplay } from "../components";
import StepWrapper from "./step-wrapper";
import { useApplication } from "../api";
import { changeStepForward } from "../utils/changeStepForward";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { api } from "@dreambigger/shared/src/api/acquire";
import ShareApi from "../api/shares";
import { Col, Spin, message } from "@dreambigger/design-system";
import {
  SLIDER_DEFAULT_MIN,
  SLIDER_DEFAULT_MAX,
  calculateFloorAndCeiling,
  calculateRatesAndAmount,
} from "../utils/cd-interest";
import { capitalizeFirstLetter } from "../utils/textFormatting";

// Initialize the various api's we need for this step.
const shareApi = new ShareApi(api);

export default function StepCdInterest({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  type PackagedData = {
    principal: number;
    shareProductUuid: string;
  };

  // State for managing whether the submit button is enabled.
  const { shareCategoryUuid, nextSlug }: CDAssets = step.assets;
  const [principal, setPrincipal] = useState<number>(SLIDER_DEFAULT_MIN);
  const [shareProductUuid, setShareProductUuid] = useState<string>("");
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const { slug, type } = step;
  const segment = useSegment();

  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  const [productData, setProductData] =
    useState<ShareCategory | undefined>(undefined);
  const [sliderMin, setSliderMin] = useState<number>(SLIDER_DEFAULT_MIN);
  const [sliderMax, setSliderMax] = useState<number>(SLIDER_DEFAULT_MAX);

  // Load products using API
  useEffect(() => {
    if (!shareCategoryUuid) {
      return;
    }

    shareApi
      .getCategoryById(flow.financialInstitution.id, shareCategoryUuid)
      .then(({ data }) => {
        setProductData({
          ...data,
          products: data.products?.sort((a: ShareProduct, b: ShareProduct) =>
            (a.maturityPeriod?.toLocaleString() || a.title).localeCompare(
              b.maturityPeriod?.toLocaleString() || b.title,
              undefined,
              { numeric: true }
            )
          ),
        });
      })
      .catch((err) => {
        console.log(err);
        message.error(
          "There was a problem loading this page. Please contact support."
        );
      });
  }, [flow, shareCategoryUuid]);

  useEffect(() => {
    if (!productData?.products) {
      return;
    }

    const { floor, ceiling } = calculateFloorAndCeiling({
      products: productData.products,
    });
    setSliderMin(floor);
    setSliderMax(ceiling);
    // If there are no initial form values, calculate the initial principal value based on the floor.
    const values = form.getFieldsValue();
    !values.principal && setPrincipal(floor);
  }, [productData]);

  // Ensure that disabled is always set to default to "true" upon step transition,
  // even when coming from another custom step where the component does not re-render.
  useEffect(() => {
    setDisabled(true);
    checkDisabled();
  }, [step]);

  //sends packaged data to application
  const handleNext = () => {
    if (!application) {
      message.error("Unable to find application");
      return;
    }
    if (!productData?.products) {
      message.error(
        "There was a problem saving this form. Please contact support."
      );
      return;
    }

    const packagedData: PackagedData = {
      principal,
      shareProductUuid,
    };

    applicationHelper
      .update(application.id, {
        slug,
        type,
        fields: packagedData,
      })
      .then(() => {
        changeStepForward(nextSlug);
      });

    segment.track({
      action: "Button Click",
      label: `Submit - ${slug}`,
      properties: packagedData,
    });
  };

  // In our render for this view, render the custom field based upon the type configured in the json.
  const checkDisabled = () => {
    setDisabled(!principal || !shareProductUuid || shareProductUuid === "");
  };

  useEffect(() => {
    checkDisabled();
  }, [principal, shareProductUuid]);

  const handleInput = (
    _changedValues: ResponseFields,
    _values: ResponseFields
  ) => {
    //Disable if the field is required and there is no value. Else, enable.
    checkDisabled();
  };

  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    initialValues.principal && setPrincipal(Number(initialValues.principal));
    initialValues.shareProductUuid &&
      setShareProductUuid(initialValues.shareProductUuid.toString());

    return initialValues;
  }, []);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    handlePrefill,
    disabled,
    handleNext,
  };

  // ----- RENDER ------

  if (!productData) {
    return <Spin style={{ width: "100%", marginTop: 150 }} />;
  }

  return (
    <StepWrapper {...wrapperProps}>
      <Col xs={24}>
        <Form.Item name="principal">
          <SliderWithDisplay
            textColor={brand.primaryColor}
            sliderColor={brand.primaryColor}
            min={sliderMin}
            max={sliderMax}
            value={principal}
            onChange={setPrincipal}
          />
        </Form.Item>
        <h2 className="fwt gray-8 f-6 mb-0">Calculate Your Earnings:</h2>
        <Form.Item name="rateKey">
          <Radio.Group className="w-100" defaultValue={shareProductUuid}>
            {productData.products?.map((product, key) => {
              const ratesAndAmount = calculateRatesAndAmount({
                product,
                principal,
              });

              return (
                ratesAndAmount && (
                  <div
                    className={`pt-2 ${
                      key !== (productData.products?.length || 0) - 1 && "bb"
                    }`}
                    style={{ borderColor: "#bfbfbf" }}
                  >
                    <Radio
                      key={product.uuid}
                      value={product.uuid} // radio values must always be strings.
                      className="pa-2 ao-lg-radio lightenPrimaryBkd lightenPrimaryBkdChecked w-100 subtleLift br-4 mb-2 checkedFill"
                      onChange={() => setShareProductUuid(product.uuid)}
                    >
                      {/* Payment Per Month */}
                      <div className="f-5">
                        {product.maturityPeriod && product.maturityPeriodUnits
                          ? `${product.maturityPeriod} ${capitalizeFirstLetter(
                              product.maturityPeriodUnits
                            )}`
                          : product.title}{" "}
                        - End of Term Balance:{" "}
                        {`${ratesAndAmount.termAmount.toLocaleString("en-us", {
                          style: "currency",
                          currency: "USD",
                        })}`}
                      </div>
                      {/* Rate: <>% APR - Total Fees $<> - Total Interest Payment $<> */}
                      <div className="f-2 mt-1">
                        {ratesAndAmount.interestRatePercent.toFixed(2)}% Rate -{" "}
                        {ratesAndAmount.apyPercent.toFixed(2)}% APY - Interest
                        Earned:{" "}
                        {(ratesAndAmount.termAmount - principal).toLocaleString(
                          "en-us",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                      </div>
                    </Radio>
                  </div>
                )
              );
            })}
          </Radio.Group>
        </Form.Item>
      </Col>
    </StepWrapper>
  );
}
