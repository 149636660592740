import { useState, useCallback } from "react";
import { Row, Col } from "antd";
import {
  Checkbox,
  Form,
  DatePicker,
  MaskedInput,
} from "@dreambigger/design-system/src/components";
import { ResponseFields, SsnStepAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import styles from "./steps.module.scss";
import StepWrapper from "./step-wrapper";
import { isValidSsn } from "../utils/validators";
import dayjs from "dayjs";

export const ssnKey = "ssn";
export const birthdayKey = "birthday";
export const confirmationKey = "identityConfirmation";

export default function Ssn({ flow, step, brand, progress }: StepProps) {
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const assets: SsnStepAssets = step.assets;

  const handleInput = (
    _changedValues: ResponseFields,
    values: ResponseFields
  ) => {
    setDisabled(
      !(
        values[ssnKey] &&
        isValidSsn(values[ssnKey].toString()) &&
        values[birthdayKey] &&
        values[confirmationKey]
      )
    );
  };

  // Upon load, prefill inputs with stored responses.
  const handlePrefill = useCallback((initialValues?: ResponseFields) => {
    if (!initialValues) {
      return;
    }

    // Define modifiedValues const to be returned in place of initialValues.
    const modifiedValues: ResponseFields = {};

    for (const field in initialValues) {
      // DatePicker component requires a dayjs object instead of a string, so convert.
      if (field === birthdayKey) {
        modifiedValues[birthdayKey] = dayjs(
          initialValues[birthdayKey] as string
        );
      } else {
        modifiedValues[field] = initialValues[field];
      }
    }

    return modifiedValues;
  }, []);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    handleInput,
    handlePrefill,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps}>
      <Row>
        <Col xs={24} md={16}>
          <label>{assets.ssnTitle}</label>
          <Form.Item
            name={ssnKey}
            valuePropName={"value"}
            // Use antd rules to display error message.
            // * Note: We're currently using our own custom disable logic as opposed to antd's, so this
            // * so this rule violation is ONLY for the error message, not for actually preventing the
            // * form from being submitted.
            rules={[
              () => ({
                // Use antd's validator to get value of field
                validator(_, value) {
                  if (
                    value && // if the field isn't empty...
                    !isValidSsn(value) && // ...and it isn't a valid ssn
                    value.slice(-1) !== "_" // ...and it is filled out (the last character isn't a "_")
                  ) {
                    return Promise.reject(
                      new Error(
                        "Invalid social security number. Please try again."
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <MaskedInput
              placeholder={assets.ssnPlaceholder}
              mask="000-00-0000"
              className="ao-bl-input"
            />
          </Form.Item>

          <label>{assets.dobTitle}</label>
          <Form.Item name={birthdayKey}>
            <DatePicker
              placeholder={assets.dobPlaceholder}
              className="w-100 ao-bl-picker"
            />
          </Form.Item>
        </Col>
      </Row>

      <div className={`mt-6 flex`}>
        <div className="pr-3">
          <Form.Item name={confirmationKey} valuePropName="checked">
            <Checkbox className="ao-lg-checkbox" />
          </Form.Item>
        </div>
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.confirmationText ?? "",
            }}
          />
        </div>
      </div>
    </StepWrapper>
  );
}
