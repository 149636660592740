// TODO: Replace styles from module.scss with classes
// from design system / Ant responsive layout components (Row, Col etc)

import {
  Input,
  Button,
  Form,
  MaskedInput,
  Group,
} from "@dreambigger/design-system/src/components";
import { Row, Col, Divider, message } from "antd";
import Image from "next/image";
import styles from "./start.module.scss";
import { ArrowRightOutlined, RightOutlined } from "@ant-design/icons";
import { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { Header, CodeVerification } from "../components";
import { useRouter } from "next/router";
import {
  Step,
  Flow,
  FinancialBrand,
  StartStepAssets,
} from "@dreambigger/shared/src/types";
import { skipAheadKey, keyByLoginModeHash } from "../pages/flows/[flowId]";
import {
  LocalStorage,
  removeBrowserQueryParam,
} from "@dreambigger/shared/src/utils";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { AuthApi } from "@dreambigger/shared/src/api/acquire";
import { api } from "@dreambigger/shared/src/api/acquire";
import { useAuthToken } from "../passwordless-auth";
import { changeStepForward } from "../utils/changeStepForward";
import { notification } from "antd";
import { CirclesImage } from "@dreambigger/design-system/src/components";
import useAuthCallback from "../hooks/use-auth-callback";
import getFingerprint from "@dreambigger/shared/src/utils/getFingerprint";
import { formatAsNationalPhoneNum } from "../utils/textFormatting";
import { useRecaptcha } from "@dreambigger/shared/src/hooks";
import { FlowRenderOptions } from "../pages/flows/[flowId]";
//import { useApplication, } from "../api";
//import { useMemo } from "react";
//*** The above application objects are not needed while function below is also disabled ***/
const LOGIN_RECAPTCHA_ACTION = "LOGIN";

const authApi = new AuthApi(api);
export type StartStepProps = {
  step: Step;
  brand: FinancialBrand;
  flow: Flow;
  renderOptions: FlowRenderOptions;
};

export default function Start({
  step,
  brand,
  flow,
  renderOptions,
}: StartStepProps) {
  const { hideHeader = false } = renderOptions;
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [loginId, setLoginId] = useState("");
  const [skipAhead, setSkipAhead] = useState(false);
  const router = useRouter();
  const { otpId } = router.query;
  const assets: StartStepAssets = step.assets;
  const localStorage = new LocalStorage(flow.financialInstitution.id);
  const tokenHelper = useAuthToken(flow.financialInstitution.id);
  const [loginForm] = Form.useForm();
  const segment = useSegment();
  const keyByLoginMode =
    keyByLoginModeHash[flow.financialInstitution.loginMode];
  const cred = localStorage.getItem(keyByLoginMode);
  // Format cred as phone number if login mode is sms/phone
  const formattedCred =
    keyByLoginMode === "email" ? cred : formatAsNationalPhoneNum(cred);
  const emailInput = useRef<any>(null);
  const phoneInput = useRef<any>(null);

  // Initialize recaptcha and lazy load the script
  const recaptchaSiteKey = flow.financialInstitution.recaptchaSiteKey;
  const recaptcha = useRecaptcha(recaptchaSiteKey);
  if (recaptchaSiteKey) {
    recaptcha.load();
  }

  const setFocus = useCallback(() => {
    //set focus to email input
    if (flow.financialInstitution.loginMode === "email" && emailInput.current) {
      emailInput.current.focus();
      return;
    }
    //set focus to phone input
    if (phoneInput.current) {
      phoneInput.current.focus();
    }
  }, [emailInput, phoneInput]);
  // const applicationHelper = useApplication(flow.financialInstitution.id);
  // const application = useMemo(() => applicationHelper.find(flow.id, "draft"), [applicationHelper, flow]);
  //*** The above application objects are not needed while function below is also disabled ***/

  // Close any open notifications that may be present from hitting the back button.
  useEffect(() => {
    notification.destroy();
  }, []);

  // If user is trying to log in using an otpID in the url...
  useEffect(() => {
    if (!otpId) {
      return;
    }

    setSkipAhead(true);
    // remove OTP Id from query params
    removeBrowserQueryParam("otpId", router);

    if (flow.authenticationMode === "none") {
      guestLogin(otpId as string);
      return;
    }
    sendOtp(otpId as string);
    return;
  }, [flow, otpId]);

  const nextStep = () => {
    // -- USING OPT LOGIN --
    // If logging in using an otpID that has flagged "skipAhead,"
    // set skipAheadKey in local storage to "true," which will
    // trigger a skip to the user's last completed step at the end of this process.
    if (skipAhead) {
      localStorage.setItem(skipAheadKey, "true");
    }

    // -- USING NORMAL LOGIN --

    //go to the next configured step.
    changeStepForward(assets.nextSlug);
  };

  const sendOtp = useCallback(
    (id: string) => {
      // If valid token exists and otpId hasn't changed, skip otp verification
      if (
        !tokenHelper.jwtIsExpired() &&
        id === localStorage.getItem(keyByLoginMode) &&
        tokenHelper.decodeJWT(tokenHelper.getJwtToken())?.user
          ?.authenticationMode === "otp"
      ) {
        nextStep();
        return;
      }

      // Otherwise, send verification code
      setLoginId(id);

      getFingerprint()
        .then((visitorId) =>
          authApi.sendOtp({
            otpId: id,
            otpType: flow.financialInstitution.loginMode,
            userType: "acquire",
            financialInstitution: {
              id: flow.financialInstitution.id,
              name: flow.financialInstitution.name,
              notificationAssets: flow.financialInstitution.notificationAssets,
            },
            deviceFingerprint: visitorId,
          })
        )
        .then(({ data }) => {
          setUserId(data.user.id);
          setShowVerifyModal(true);
          segment.track({
            action: "Authentication",
            label: "OTP Sent",
            properties: {
              [keyByLoginMode]: id,
              flowId: flow.id,
            },
          });
        })
        .catch((error) => {
          segment.track({
            action: "Authentication",
            label: "OTP Send Failed",
            properties: {
              [keyByLoginMode]: id,
              flowId: flow.id,
              error: error.message,
            },
          });
          console.log(error);
        });
    },
    [nextStep]
  );

  // handle guest login
  const handleAuthentication = useAuthCallback(flow, keyByLoginMode);

  const guestLogin = useCallback(
    (id: string) => {
      // If valid token exists and loginId hasn't changed, skip token generation
      if (
        !tokenHelper.jwtIsExpired() &&
        id === localStorage.getItem(keyByLoginMode)
      ) {
        nextStep();
        return;
      }

      // Otherwise, authenticate as guest
      // If recaptcha is enabled, verify with recaptcha else just capture device fingerprint
      Promise.all(
        recaptchaSiteKey
          ? [getFingerprint(), recaptcha.execute(LOGIN_RECAPTCHA_ACTION)]
          : [getFingerprint()]
      )
        .then(([visitorId, responseToken]) =>
          authApi.authenticateGuest({
            loginId: id,
            loginType: flow.financialInstitution.loginMode,
            financialInstitution: {
              id: flow.financialInstitution.id,
              name: flow.financialInstitution.name,
              notificationAssets: flow.financialInstitution.notificationAssets,
            },
            userType: "acquire",
            deviceFingerprint: visitorId,
            recaptcha: responseToken
              ? {
                  responseToken,
                  action: LOGIN_RECAPTCHA_ACTION,
                }
              : undefined,
          })
        )
        .then(({ data }) => {
          handleAuthentication(data.jwt, "Guest Authenticated");

          // Go to next step
          nextStep();
          return;
        })
        .catch((error) => {
          if (recaptchaSiteKey && error.response?.status === 401) {
            message.error(
              "Access denied. Please try from a different device or network."
            );
          }
          segment.track({
            action: "Authentication",
            label: "Guest Login Failed",
            properties: {
              [keyByLoginMode]: id,
              flowId: flow.id,
              error: error.message,
            },
          });
          console.log(error);
        });
    },
    [nextStep]
  );

  // Submit Button Handle
  const handleNext = useCallback(
    (values: any) => {
      setSkipAhead(false);
      segment.track({ action: "Button Click", label: `Submit - ${step.slug}` });

      if (flow.authenticationMode === "none") {
        guestLogin(values[keyByLoginMode]);
        return;
      }
      sendOtp(values[keyByLoginMode]);
    },
    [sendOtp]
  );

  useEffect(() => {
    //set focus only if modal is not visible
    if (!showVerifyModal) {
      //allow time for step to finish loading
      setTimeout(setFocus, 500);
    }
  }, [showVerifyModal]);

  // ------------------------------------------------------------------------------------------------------------------
  // SUBCOMPONENTS
  // ------------------------------------------------------------------------------------------------------------------

  const loginComponent = useMemo(() => {
    return (
      <Form
        form={loginForm}
        className="mt-5 flex flex-column items-center md_items-start"
        initialValues={{ [keyByLoginMode]: formattedCred }}
        onFinish={handleNext}
      >
        {/* --- Text above input  (EX: "Sign up in less than 5 mins!") */}
        <label
          className="primary fwb f-6 lg_f-4 xl_f-5 xxl_f-6 md_mb-2"
          dangerouslySetInnerHTML={{
            __html: assets.loginTitle ?? "",
          }}
        />
        <Row
          align="bottom"
          className="lg_mb-8 fwb justify-center lg_justify-start"
        >
          {/* --- Input and Submit Button */}
          <Form.Item name={keyByLoginMode} style={{ paddingRight: "8px" }}>
            {flow.financialInstitution.loginMode === "email" ? (
              <Input
                className="br-5 pv-3 mt-3 md_mt-0"
                style={{ borderColor: "black", width: "332px" }}
                placeholder={assets.loginPlaceholder}
                type="email"
                ref={emailInput}
                required
              />
            ) : (
              <MaskedInput
                className="br-5 pa-3 mt-3 md_mt-0"
                style={{ borderColor: "black", width: "317px" }}
                placeholder={assets.loginPlaceholder}
                mask="(000) 000-0000"
                name="phone"
                ref={phoneInput}
              />
            )}
          </Form.Item>

          <div
            className="min-w-20"
            style={{ paddingLeft: "8px", paddingRight: "8px" }}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="lift h-8 ph-6"
              >
                {assets.submitButtonText} <ArrowRightOutlined />
              </Button>
            </Form.Item>
          </div>
        </Row>
      </Form>
    );
  }, [assets, showVerifyModal]);

  // ------------------------------------------------------------------------------------------------------------------
  // TEMPLATES
  // ------------------------------------------------------------------------------------------------------------------

  // --- Default Template
  const defaultTemplate = useMemo(() => {
    return (
      <div className={`${styles.homePage} w-100 h-100 min-h-100vh`}>
        {!hideHeader && <Header brand={brand} />}

        <Row
          className={`${styles.mainContainer} pv-6 sm_pv-10 br-2`}
          style={{
            background: assets.heroBackground
              ? `url(${assets.heroBackground})`
              : `linear-gradient(180deg, rgba(${
                  assets.heroBackgroundColor1 || "249,249,249"
                }) 0%, rgba(${
                  assets.heroBackgroundColor2 || "249,249,249"
                }) 100%)`,
          }}
        >
          <Col xs={24} lg={12}>
            <h1
              className={`lh-1 textSecondary f-8 sm_f-9 fwb mb-2 mt-0`}
              dangerouslySetInnerHTML={{
                __html: assets.title ?? "",
              }}
            />

            <h2
              className={`${styles.subTitle} textSecondary f-6 sm_f-7 fwn lh-1 mb-6`}
              dangerouslySetInnerHTML={{
                __html: assets.subTitle ?? "",
              }}
            />
            <h3
              className={`${styles.description} textSecondary fwn f-4 sm_f-5 lh-2 pb-4`}
              dangerouslySetInnerHTML={{
                __html: assets.description ?? "",
              }}
            />
            <Divider className={styles.divider} />
            <Form
              form={loginForm}
              initialValues={{ [keyByLoginMode]: formattedCred }}
              onFinish={handleNext}
            >
              <label
                className="textSecondary f-5 fwb"
                dangerouslySetInnerHTML={{
                  __html:
                    assets.loginTitle || "Sign up in less than 5 minutes!",
                }}
              />
              <Row gutter={8} className="mt-2 sm_mt-1" align="top">
                <Col xs={24} lg={14} style={{ alignItems: "start" }}>
                  <Form.Item name={keyByLoginMode} style={{ marginBottom: 16 }}>
                    {flow.financialInstitution.loginMode === "email" ? (
                      <Input
                        placeholder={assets.loginPlaceholder}
                        type="email"
                        ref={emailInput}
                        className="br-5 pa-3 mt-0 md_mt-0"
                        style={{ height: 54 }}
                        required
                      />
                    ) : (
                      <MaskedInput
                        placeholder={assets.loginPlaceholder}
                        mask="(000) 000-0000"
                        name="phone"
                        className="br-5 pa-3 mt-0 md_mt-0"
                        ref={phoneInput}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  lg={{
                    flex: "100px",
                  }}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="lift h-8 ph-6 mt-2 md-auto"
                    >
                      {assets.submitButtonText} <RightOutlined />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          {assets.heroImage && (
            <Col xs={24} lg={12} className="lg_pl-8">
              <div
                className={`${styles.heroImage} ${
                  !assets.hideStartImageShadow && "sr-4 sm_sr-7"
                }`}
              >
                <Image
                  src={assets.heroImage}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="top"
                  priority={true}
                  alt={"hero image"}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row className={`${styles.termsContainer}`}>
          <Col xs={20} lg={10}>
            <div className="fwb f-4">{assets.landingTermsTitle} </div>
            <div
              className={`${styles.terms} fwn f-4 lh-4`}
              dangerouslySetInnerHTML={{
                __html: assets.landingTermsText ?? "",
              }}
            />
          </Col>
        </Row>
        <CodeVerification
          title={assets.otpModalHeaderText}
          headline={assets.otpModalTitle}
          description={assets.otpModalText}
          secondaryCta={assets.otpModalChangeIdText}
          visible={showVerifyModal}
          flow={flow}
          keyByLoginMode={keyByLoginMode}
          loginId={loginId}
          userId={userId}
          onCancel={() => setShowVerifyModal(false)}
          onSecondaryCtaClick={() => setShowVerifyModal(false)}
          onSuccess={() => {
            setShowVerifyModal(false);
            nextStep();
          }}
        />
      </div>
    );
  }, [assets, showVerifyModal]);

  // --- Circles Template
  const circlesTemplate = useMemo(() => {
    const default_circles_img =
      "https://res.cloudinary.com/cotribute/image/upload/v1656016218/acquire/template_assets/circle-teal-1.png";

    return (
      <div className={`${styles.homePage} w-100 h-100 min-h-100vh`}>
        {!hideHeader && <Header brand={brand} />}

        <Group
          className={`w-100 pv-8 reverse flex-column-reverse lg_flex-row min-h-60vh lg_mv-4 tc lg_tl pa-4 md_ph-11vw br-2`}
          style={{
            background: assets.heroBackground
              ? `url(${assets.heroBackground})`
              : `linear-gradient(180deg, rgba(${
                  assets.heroBackgroundColor1 || "235, 236, 237"
                }) 0%, rgba(${
                  assets.heroBackgroundColor2 || "235, 236, 237"
                }) 100%)`,
          }}
        >
          {/* LEFT COLUMN */}
          <div className="w-100 lg_w-50">
            <div className="flex flex-column w-100 lg_w-90 lg">
              {/* - Title */}
              <span
                className={`textSecondary mb-5 lh-1 f-8 xl_f-9 xxl_f-10 fwt mt-7 lg_mt-0`}
                dangerouslySetInnerHTML={{
                  __html: assets.title ?? "",
                }}
              ></span>
              {/* - Subtitle */}
              <span
                className={`textSecondary f-5 md_f-7 xl_f-8 xxl_f-9 mb-6 lh-1`}
                dangerouslySetInnerHTML={{
                  __html: assets.subTitle ?? "",
                }}
              />

              {/* - Description */}
              <span
                className={`textSecondary f-4 md_f-5 xl_f-6 xxl_f-7 lh-2`}
                dangerouslySetInnerHTML={{
                  __html: assets.description ?? "",
                }}
              />
            </div>
            {/* - Login Form */}
            <Form
              form={loginForm}
              className="mv-5"
              initialValues={{ [keyByLoginMode]: formattedCred }}
              onFinish={handleNext}
            >
              {/* --- Text above input  (EX: "Sign up in less than 5 mins!") */}
              <label
                className="textSecondary fwb f-6 lg_f-4 xl_f-5 xxl_f-6"
                dangerouslySetInnerHTML={{
                  __html: assets.loginTitle ?? "",
                }}
              />
              <Row
                align="bottom"
                className="mt-1 mb-8 fwb justify-center lg_justify-start"
              >
                {/* --- Input and Submit Button */}
                <Form.Item name={keyByLoginMode} className="mr-1">
                  {flow.financialInstitution.loginMode === "email" ? (
                    <Input
                      className="br-5 pa-3 mt-3 md_mt-0"
                      style={{ borderColor: "black", width: "317px" }}
                      placeholder={assets.loginPlaceholder}
                      type="email"
                      ref={emailInput}
                      required
                    />
                  ) : (
                    <MaskedInput
                      className="br-5 pa-3 mt-3 md_mt-0"
                      placeholder={assets.loginPlaceholder}
                      mask="(000) 000-0000"
                      name="phone"
                      ref={phoneInput}
                    />
                  )}
                </Form.Item>

                <div
                  className="min-w-20"
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="lift h-8 pr-6"
                    >
                      {assets.submitButtonText} <ArrowRightOutlined />
                    </Button>
                  </Form.Item>
                </div>
              </Row>
            </Form>

            {/* - Terms */}
            <Col className="w-100 lg_w-90">
              {/* --- Title (IE: "Terms and Conditions") */}
              <div
                className="textSecondary fwt f-4 xl_f-5 xxl_f-6"
                dangerouslySetInnerHTML={{
                  __html: assets.landingTermsTitle ?? "",
                }}
              />
              {/* --- Description */}
              <div
                className={`${styles.terms} textSecondary f-3 xl_f-4 xxl_f-5`}
                dangerouslySetInnerHTML={{
                  __html: assets.landingTermsText ?? "",
                }}
              />
            </Col>
          </div>

          {/* RIGHT COLUMN */}

          {assets.accentColor1 || assets.accentColor2 ? (
            <CirclesImage
              src={assets.heroImage}
              accentColor1={assets.accentColor1 || brand.primaryColor}
              accentColor2={assets.accentColor2 || brand.secondaryColor}
              className="w-100 lg_w-50 pb-100 lg_pa-0"
              style={{ maxWidth: "750px" }}
            />
          ) : (
            <div className="w-100 lg_w-50">
              <img
                className="w-100"
                style={{ maxWidth: "750px" }}
                src={assets.heroImage || default_circles_img}
              />
            </div>
          )}
        </Group>
        <CodeVerification
          title={assets.otpModalHeaderText}
          headline={assets.otpModalTitle}
          description={assets.otpModalText}
          secondaryCta={assets.otpModalChangeIdText}
          visible={showVerifyModal}
          flow={flow}
          keyByLoginMode={keyByLoginMode}
          loginId={loginId}
          userId={userId}
          onCancel={() => setShowVerifyModal(false)}
          onSecondaryCtaClick={() => setShowVerifyModal(false)}
          onSuccess={() => {
            setShowVerifyModal(false);
            nextStep();
          }}
        />
      </div>
    );
  }, [assets, showVerifyModal]);

  // --- Triangles Template
  const trianglesTemplate = useMemo(() => {
    const default_triangle_img =
      "https://res.cloudinary.com/cotribute/image/upload/v1656016221/acquire/template_assets/triangle-woman-on-laptop.png";
    return (
      <div className={`${styles.homePage} w-100 h-100 min-h-100vh`}>
        {!hideHeader && <Header brand={brand} />}

        {/* GROUP - Colored Background Container */}
        <Group
          className={`w-100 flex-column-reverse lg_flex-row lg_tl lg_pt-8 md_pa-0`}
          style={{
            background: assets.heroBackground
              ? `url(${assets.heroBackground})`
              : `linear-gradient(180deg, rgba(${
                  assets.heroBackgroundColor1 || "249,249,249"
                }) 0%, rgba(${
                  assets.heroBackgroundColor2 || "249,249,249"
                }) 100%)`,
          }}
        >
          {/* LEFT COLUMN - Hidden on mobile */}
          <div className="dn lg_flex lg_w-45">
            <img
              className="w-100 lg_absolute"
              style={{ maxWidth: "40vw", top: "55px", left: "20px" }}
              src={assets.heroImage || default_triangle_img}
            />
          </div>
          {/* RIGHT COLUMN */}
          <div className="lg_w-55">
            <div className="flex flex-column w-100 lg_w-90">
              {/* Text Div */}
              <div className="flex flex-column pl-5 lg_pl-0 pr-5">
                {/* - Title */}
                <h1
                  className={`textSecondary mb-5 lh-1 f-9 xl_f-10 fwt mt-6 sm_mt-7 lg_mt-0`}
                  dangerouslySetInnerHTML={{
                    __html: assets.title ?? "",
                  }}
                />
                {/* - Subtitle */}
                <h2
                  className={`textSecondary f-7 md_f-8 xxl_f-9 lh-1`}
                  dangerouslySetInnerHTML={{
                    __html: assets.subTitle ?? "",
                  }}
                />

                {/* - Description */}
                <span
                  className={`textSecondary f-5 md_f-6 xl_f-6 xxl_f-7 lh-2 mt-4 md_ph-0 tl pt-4 sm_pt-0`}
                  dangerouslySetInnerHTML={{
                    __html: assets.description ?? "",
                  }}
                />
                {/* Login Form */}
                {loginComponent}
              </div>
              {/* - Image (Mobile Only) */}
              <Col
                className="flex flex-column lg_dn w-100 justify-center"
                style={{
                  background: `linear-gradient(180deg, rgba(255, 0, 0 ,0) 0%, rgba(255, 255, 255) 60%)`,
                  top: "10px",
                }}
              >
                <img
                  className="w-100 ph-8"
                  src={assets.heroImage || default_triangle_img}
                />
              </Col>
            </div>
          </div>
        </Group>

        {/* - Terms */}
        <Row className="w-100 flex-row-reverse">
          <div className="w-100 lg_w-55 pv-5 ph-5 lg_pr-10 lg_tl">
            {/* --- Title (IE: "Terms and Conditions") */}
            <div
              className="fwt tc sm_tl f-5 xl_f-5 xxl_f-6"
              dangerouslySetInnerHTML={{
                __html: assets.landingTermsTitle ?? "",
              }}
            />
            {/* --- Description */}
            <div
              className={`${styles.terms} f-3 xl_f-4 xxl_f-5`}
              dangerouslySetInnerHTML={{
                __html: assets.landingTermsText ?? "",
              }}
            />
          </div>
        </Row>
        <CodeVerification
          title={assets.otpModalHeaderText}
          headline={assets.otpModalTitle}
          description={assets.otpModalText}
          secondaryCta={assets.otpModalChangeIdText}
          visible={showVerifyModal}
          flow={flow}
          keyByLoginMode={keyByLoginMode}
          loginId={loginId}
          userId={userId}
          onCancel={() => setShowVerifyModal(false)}
          onSecondaryCtaClick={() => setShowVerifyModal(false)}
          onSuccess={() => {
            setShowVerifyModal(false);
            nextStep();
          }}
        />
      </div>
    );
  }, [assets, showVerifyModal]);

  // --- Squares Template
  const squaresTemplate = useMemo(() => {
    const default_square_img =
      "https://res.cloudinary.com/cotribute/image/upload/v1656016221/acquire/template_assets/square-blue.png";

    return (
      <div className={`${styles.homePage} w-100 h-100 min-h-100vh`}>
        {!hideHeader && <Header brand={brand} />}
        <Group
          className={`w-100 pv-8 flex-column-reverse lg_flex-row min-h-60vh lg_mv-4 tc pa-4 md_ph-6vw lg_pv-8 br-2`}
          style={{
            background: assets.heroBackground
              ? `url(${assets.heroBackground})`
              : `linear-gradient(180deg, rgba(${
                  assets.heroBackgroundColor1 || "235, 236, 237"
                }) 0%, rgba(${
                  assets.heroBackgroundColor2 || "235, 236, 237"
                }) 100%)`,
          }}
        >
          {/* LEFT COLUMN - HIDE ON MOBILE */}
          <div className="dn lg_flex lg_w-50 pr-5">
            <img
              className="w-100 xl_w-90"
              style={{ maxWidth: "900px", objectFit: "scale-down" }}
              src={assets.heroImage || default_square_img}
            />
          </div>
          {/* RIGHT COLUMN */}
          <div className="w-100 lg_w-50">
            <div className="flex flex-column w-100">
              {/* - Title */}
              <span
                className={`textSecondary fwt mb-5 lh-1 f-8 xl_f-9 xxl_f-10 lg_mt-0`}
                dangerouslySetInnerHTML={{
                  __html: assets.title ?? "",
                }}
              ></span>
              {/* - Subtitle */}
              <span
                className={`textSecondary fwb f-6 md_f-7 xl_f-8 xxl_f-9 mb-6 lh-1`}
                dangerouslySetInnerHTML={{
                  __html: assets.subTitle ?? "",
                }}
              />

              {/* - Description */}
              <span
                className={`textSecondary f-5 xl_f-6 xxl_f-7 lh-2`}
                dangerouslySetInnerHTML={{
                  __html: assets.description ?? "",
                }}
              />
            </div>
            {/* - Login Form */}
            <div
              className="br-4 flex justify-center mt-6 lg_mv-8 pv-4 s-6"
              style={{
                backgroundColor: assets.accentColor1 || brand.primaryColor,
              }}
            >
              <Form
                form={loginForm}
                initialValues={{ [keyByLoginMode]: formattedCred }}
                onFinish={handleNext}
              >
                {/* --- Text above input  (EX: "Sign up in less than 5 mins!") */}
                <label
                  className="textSecondary fwb f-6"
                  dangerouslySetInnerHTML={{
                    __html: assets.loginTitle || "",
                  }}
                  style={{ color: "white" }}
                />
                <Row
                  align="bottom"
                  className="fwb justify-center lg_justify-start"
                >
                  {/* --- Input and Submit Button */}
                  <Form.Item
                    name={keyByLoginMode}
                    style={{ paddingLeft: "8px", paddingRight: "8px" }}
                  >
                    {flow.financialInstitution.loginMode === "email" ? (
                      <Input
                        className="br-5 pa-3 mt-3 md_mt-0"
                        style={{ borderColor: "black", width: "300px" }}
                        placeholder={assets.loginPlaceholder}
                        type="email"
                        ref={emailInput}
                        required
                      />
                    ) : (
                      <MaskedInput
                        className="br-5 pa-3 mt-3 md_mt-0"
                        placeholder={assets.loginPlaceholder}
                        mask="(000) 000-0000"
                        name="phone"
                        ref={phoneInput}
                      />
                    )}
                  </Form.Item>

                  <div
                    className="min-w-20"
                    style={{ paddingLeft: "8px", paddingRight: "8px" }}
                  >
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="lift h-8 ph-6"
                        style={{
                          borderColor: `${assets.accentColor2 || "white"}`,
                          backgroundColor: assets.accentColor2 || "black",
                          color: assets.accentColor2
                            ? brand.primaryTextColor
                            : "white",
                        }}
                      >
                        {assets.submitButtonText} <ArrowRightOutlined />
                      </Button>
                    </Form.Item>
                  </div>
                </Row>
              </Form>
            </div>
            {/* Image - Mobile Only */}
            <div className="flex lg_dn justify-center">
              <img
                className="w-100"
                style={{ maxWidth: "700px" }}
                src={
                  "https://res.cloudinary.com/cotribute/image/upload/v1656016221/acquire/template_assets/square-blue.png"
                }
              />
            </div>
            {/* - Terms */}
            <Col className="w-100">
              {/* --- Title (IE: "Terms and Conditions") */}
              <div
                className="fwt f-4 xl_f-5 xxl_f-6 textSecondary"
                dangerouslySetInnerHTML={{
                  __html: assets.landingTermsTitle ?? "",
                }}
              />
              {/* --- Description */}
              <div
                className={`${styles.terms} f-3 xl_f-4 xxl_f-5 textSecondary`}
                dangerouslySetInnerHTML={{
                  __html: assets.landingTermsText ?? "",
                }}
              />
            </Col>
          </div>
        </Group>
        <CodeVerification
          title={assets.otpModalHeaderText}
          headline={assets.otpModalTitle}
          description={assets.otpModalText}
          secondaryCta={assets.otpModalChangeIdText}
          visible={showVerifyModal}
          flow={flow}
          keyByLoginMode={keyByLoginMode}
          loginId={loginId}
          userId={userId}
          onCancel={() => setShowVerifyModal(false)}
          onSecondaryCtaClick={() => setShowVerifyModal(false)}
          onSuccess={() => {
            setShowVerifyModal(false);
            nextStep();
          }}
        />
      </div>
    );
  }, [assets, showVerifyModal]);

  // ------------------------------------------------------------------------------------------------------------------
  // RENDER
  // ------------------------------------------------------------------------------------------------------------------

  switch (assets.template) {
    case "circles":
      return circlesTemplate;
    case "triangles":
      return trianglesTemplate;
    case "squares":
      return squaresTemplate;
    default:
      return defaultTemplate;
  }
}
