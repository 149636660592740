import { useMemo, useState, useCallback, useEffect } from "react";
import { message, Progress } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Button } from "@dreambigger/design-system/src/components";
import { Person, PeopleStepAssets } from "@dreambigger/shared/src/types";
import { StepContentSection, PersonCard } from "../components";
import { StepProps } from "../pages/flows/[flowId]";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { useApplication } from "../api";
import { changeStepForward } from "../utils/changeStepForward";
import styles from "./people.module.scss";
import { convertCamel, capitalizeFirstLetter } from "../utils/textFormatting";
import dayjs from "dayjs";

export default function People({ flow, step, brand, progress }: StepProps) {
  const assets: PeopleStepAssets = step.assets;
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );
  const { slug, type } = step;
  const [isDisabled, setIsDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [peopleData, setPeopleData] = useState<Person[]>([]);
  const [nextId, setNextId] = useState(0);
  const segment = useSegment();

  // On application load, preload peopleData with application data. If there is no data,
  // generate a card for the user instead.
  useEffect(() => {
    if (!application) return;
    let initialData: any;
    for (const response of application.responses) {
      if (response.slug === slug) {
        initialData = response.fields.people;
      }
    }
    // Set initial data for PeopleCards, or automatically create the first card for the user.
    initialData
      ? setPeopleData(initialData)
      : peopleData.length === 0 && addUser();
  }, [application]);

  // adds user to peopleData
  const addUser = () => {
    const userDetails = getUserDetails();
    addPerson({ ...userDetails, isBeneficialOwner: true });
  };

  // Search the application for relevant details for the user's card.
  const getUserDetails = () => {
    if (!application) return;
    const keysToSearchFor = [
      "firstName",
      "middleName",
      "lastName",
      "email",
      "businessApplicantTitle",
      "title",
      "phone",
    ];
    // Define empty object to hold data
    let userDetails: any = {};
    // Search through the responses in the application and the available keys in each response.
    // If a key is found, add it along with its value to the userDetails object.
    for (const response of application.responses) {
      const keys = Object.keys(response.fields);
      for (const key of keys) {
        if (keysToSearchFor.includes(key)) {
          if (key === "businessApplicantTitle") {
            userDetails["title"] = response.fields[key];
          } else {
            userDetails[key] = response.fields[key];
          }
        }
      }
    }
    return userDetails;
  };

  // Check peopleData every time it is updated to:
  // 1. Increment/set the next available ID# for the next person addded to the form.
  // 2. See if the requirements for signatures has been met and enable the "next" button.
  useEffect(() => {
    // Dynamically set what ID should be assigned to the next PersonCard generated (every card needs a unique ID)
    let highestId = 0;
    for (const person of peopleData) {
      if (highestId < person.id) {
        highestId = person.id;
      }
    }
    setNextId(highestId + 1);
    // Check if next button should be enabled.
    setIsDisabled(checkDisabled());
  }, [peopleData]);

  const checkDisabled = () => {
    // Always enable the next button if no requirements have been specified.
    if (!assets.requirements) {
      return false;
    }
    for (const requirement of assets.requirements) {
      // Convert the requirementName into the key format used by the application. Example: "beneficialOwner" => "isBeneficialOwner"
      const key: string = `is${capitalizeFirstLetter(requirement.name)}`;
      let count = 0;
      for (const person of peopleData) {
        if (person[key] === true) {
          count++;
        }
      }
      if (count < requirement.numRequired) {
        return true;
      }
    }
    return false;
  };

  // Search peopleData for the number of people designated for the passed in requirement and return the number.
  const getNumAssigned = (requirementName: string) => {
    // Convert the requirementName into the key format used by the application. Example: "owner" => "isOwner"
    const key: string = `is${capitalizeFirstLetter(requirementName)}`;
    // Check all people on the form if the key is marked "true." If so, increment the count.
    let count = 0;
    for (const person of peopleData) {
      if (person[key] === true) {
        count++;
      }
    }
    return count;
  };

  // When "add person" button is clicked, update peopleData state to create a new card.
  const addPerson = (data?: any) => {
    setPeopleData((prevState) => [...prevState, { id: nextId, ...data }]);
  };

  // Callback function for advancing user to next step.
  const nextStep = useCallback(() => {
    changeStepForward(assets.nextSlug);
  }, [assets]);

  // Submit data - Package the data, save to the application, and advance to the next step.
  const handleNext = () => {
    let formattedData: any = { people: peopleData };
    // If there is a birthday field stored as a dayjs value, format it correctly for the application.
    for (const person of formattedData.people) {
      if (dayjs.isDayjs(person.birthday)) {
        person.birthday = person.birthday
          .utcOffset(0, true)
          .format("YYYY-MM-DD");
      }
    }
    if (!application) {
      message.error("Unable to find application");
      return;
    }

    setSaving(true);

    applicationHelper
      .update(application.id, {
        slug,
        type,
        fields: formattedData,
      })
      .then(() => {
        nextStep();
      })
      .finally(() => setSaving(false));
    segment.track({
      action: "Button Click",
      label: `Submit - ${slug}`,
      properties: formattedData,
    });
  };

  // Guard clause against requirements or fields not being present (should never happen)
  if (!assets.requirements || !assets.fields) {
    return (
      <p>
        There was an error loading the requirements for this application. Please
        contact support to finish your application.
      </p>
    );
  }

  return (
    <>
      <StepContentSection
        brand={brand}
        stepProgress={progress}
        title={assets.title || ""}
        description={assets.description || ""}
        previousSlug={assets.previousSlug}
        assets={assets}
      >
        {/* ------------- REQUIREMENTS/PROGRESS CARD (enabled by default) ------------------ */}
        {!assets?.requirementsDisplay?.hide && (
          <>
            <span>{assets.requirementsDisplay?.text}</span>
            <table
              className={`${styles.requirementsTable} mv-6 br-4 w-100 lg_w-90 bg-white pv-3 ph-4`}
            >
              <tr
                style={{
                  borderColor: brand.stepTitleColor,
                  color: brand.stepTitleColor,
                }}
              >
                <th className="w-45">Signatures Required</th>
                <th className="w-55">Signatures Assigned</th>
              </tr>

              {assets.requirements?.map((requirement) => {
                const numAssigned = getNumAssigned(requirement.name);
                const progressPercent =
                  (numAssigned / requirement.numRequired) * 100;
                return (
                  <tr key={requirement.name}>
                    <td>{convertCamel(requirement.name)}</td>
                    <td className="sm_pr-5">
                      {/* Progress Label: "Designated: X | Still Needed: "X" */}
                      <span className="mb-0 f-1">
                        Assigned:{" "}
                        <span className="fwb primary br pr-2 mr-2">
                          {numAssigned}
                        </span>
                        Still Needed:{" "}
                        <span className="fwb primary">
                          {requirement.numRequired - numAssigned}
                        </span>
                      </span>
                      <Progress
                        percent={progressPercent}
                        status={
                          progressPercent !== 100 ? "exception" : "success"
                        }
                        strokeColor={brand.stepTitleColor}
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </>
        )}
        {/* ------------- PEOPLE CARDS ------------------------------------------------------ */}
        {/****************
         * Render cards based on the people associated with the applications.
         * Users can select what roles to assign to each person (controlling officer, authorized signer, etc)
         *****************/}
        {peopleData.map((person, key) => {
          return (
            <PersonCard
              key={key}
              initialData={person}
              peopleData={peopleData}
              setPeopleData={setPeopleData}
              brand={brand}
              fields={assets.fields!} // Refers to the fields available for collecting information: ("firstName", "ssn", "address", etc.)
              requirements={assets.requirements!} // Refers to the possible signature roles required in the application : ("Owner", "Controlling Officer", "Authorized Signer", etc.)
            />
          );
        })}
        <Button onClick={addPerson}>Add a Person</Button>

        <div className="mt-6 pt-4 bt b-lightPrimary">
          <Button
            type="primary"
            onClick={handleNext}
            loading={saving}
            disabled={isDisabled}
            className="s-2 lift h-7 ph-5"
          >
            {assets.submitButtonText} <RightOutlined />
          </Button>
        </div>
      </StepContentSection>
    </>
  );
}
