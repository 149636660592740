import {
  CustomFieldConfig,
  ResponseFields,
} from "@dreambigger/shared/src/types";
import {
  isValidAutoComplete,
  isValidSsn,
  includesUnderscore,
} from "./validators";
import { isValidPhoneNumber } from "libphonenumber-js";

export const hasEmptyRequiredFields = (
  field: CustomFieldConfig,
  values: ResponseFields,
  valueName: string
) => {
  return (
    field.fieldRequired &&
    !values[valueName] &&
    field.fieldType !== "cloudinary"
  );
};

export const isInvalidAutoComplete = (
  field: CustomFieldConfig,
  values: ResponseFields,
  valueName: string
) => {
  return (
    field.fieldType === "autocomplete" &&
    !field.fieldConfiguration?.autocomplete?.allowFreeInput &&
    values[valueName] &&
    !isValidAutoComplete(values[valueName].toString(), field.fieldOptions)
  );
};

export const isInvalidPhoneNumber = (
  field: CustomFieldConfig,
  values: ResponseFields,
  valueName: string
) => {
  return (
    field.fieldType === "phone" &&
    values[valueName] &&
    !isValidPhoneNumber(values[valueName].toString(), "US")
  );
};

export const isInvalidMaskedInput = (
  field: CustomFieldConfig,
  values: ResponseFields,
  valueName: string
) => {
  return (
    field.fieldType === "maskedInput" &&
    includesUnderscore(values[valueName].toString())
  );
};

export const isInvalidSsn = (
  field: CustomFieldConfig,
  values: ResponseFields,
  valueName: string
) => {
  return (
    field.fieldType === "ssn" &&
    values[valueName] &&
    !isValidSsn(values[valueName].toString())
  );
};
