import { useState, useCallback } from "react";
import { useRouter } from "next/router";
import { Row, Col, message, Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { PaymentStatusStepAssets } from "@dreambigger/shared/src/types";
import { StepContentSection, StripePaymentStatus } from "../components";
import { StepProps } from "../pages/flows/[flowId]";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { changeStepForward } from "../utils/changeStepForward";

const spinnerIcon = <Loading3QuartersOutlined spin />;

export default function PaymentStatus({ step, brand, progress }: StepProps) {
  const [loading, setLoading] = useState(true);
  const segment = useSegment();
  const router = useRouter();
  const assets: PaymentStatusStepAssets = step.assets;

  const handlePaymentFinish = useCallback((status: string) => {
    setLoading(false);
    // https://stripe.com/docs/payments/paymentintents/lifecycle#intent-statuses
    switch (status) {
      case "succeeded":
        segment.track({ action: "Payment", label: "Success" });
        changeStepForward(assets.nextSlug, { status });
        break;
      case "requires_capture":
        segment.track({ action: "Payment", label: "Requires Capture" });
        changeStepForward(assets.nextSlug, { status });
        break;
      case "processing":
        message.info("Your payment is being processed.");
        segment.track({ action: "Payment", label: "Processing" });
        changeStepForward(assets.nextSlug, { status });
        break;
      case "requires_action":
        message.info("Your payment requires additional verification.");
        segment.track({ action: "Payment", label: "Requires Action" });
        changeStepForward(assets.nextSlug, { status });
        break;
      case "requires_payment_method":
        message.error(
          "Payment failed. Please try again with a different method. Reloading form..."
        );
        segment.track({ action: "Payment", label: "Failed" });
        router.push({
          query: {
            ...router.query,
            stepSlug: step.assets.previousSlug,
          },
        });
        break;
      default:
        message.error(
          "Payment failed. Please try again with a different method. Reloading form..."
        );
        segment.track({ action: "Payment", label: "Error" });
        router.push({
          query: {
            ...router.query,
            stepSlug: step.assets.previousSlug,
          },
        });
        break;
    }
  }, []);

  return (
    <StepContentSection
      brand={brand}
      stepProgress={progress}
      title={assets.title ?? ""}
      description={assets.description ?? ""}
      previousSlug={assets.previousSlug}
      hideProgressBar={assets.hideProgressBar}
    >
      <StripePaymentStatus
        onFinish={handlePaymentFinish}
        stripeAccount={assets.stripeAccountId}
      >
        <Row>
          <Col xs={24} md={16}>
            {loading && (
              <div>
                <Spin indicator={spinnerIcon} />
              </div>
            )}
          </Col>
        </Row>
      </StripePaymentStatus>
    </StepContentSection>
  );
}
