import { Row, Col } from "antd";
import { CheckCircleFilled, LeftOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import { Progress, Button } from "@dreambigger/design-system/src/components";
import { ProductsSliderModal } from "../../components";
import { FinancialBrand } from "@dreambigger/shared/src/types";
import styles from "./StepContentSection.module.scss";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { SuccessStepAssets } from "@dreambigger/shared/src/types";
import Confetti from "react-confetti";
import { getIcon } from "@dreambigger/design-system/src/utils";
import animations from "@dreambigger/design-system/src/styles/modules/animations.module.scss";

interface Props {
  children: any;
  brand: FinancialBrand;
  title: string;
  subTitle?: string;
  description: string;
  stepProgress: number;
  previousSlug?: string;
  stepType?: string;
  assets?: SuccessStepAssets;
  hideProgressBar?: boolean;
}

const StepContentSection = ({
  children,
  brand,
  title,
  subTitle,
  description,
  stepProgress,
  previousSlug,
  stepType,
  assets,
  hideProgressBar,
}: Props) => {
  const router = useRouter();
  const { flowId, stepSlug } = router.query;
  const segment = useSegment();

  const handleClick = () => {
    router.push({
      query: {
        ...router.query,
        flowId,
        stepSlug: previousSlug,
      },
    });
    segment.track({
      action: "Button Click",
      label: `Back - ${stepSlug as string}`,
    });
  };

  // For success step, render a unique layout
  // - Initially, the layout will look the same as the previous steps while the application is submitted
  // - When the submission is done (see the useEffect above), the layout will transform and confetti will play.
  if (stepType === "success" && router.query.submission === "complete") {
    return (
      <>
        <div className="min-h-100vh w-100">
          {!assets?.disableConfetti && (
            <Confetti
              recycle={false}
              numberOfPieces={900}
              tweenDuration={12000}
              className="fixed z-0"
            />
          )}
          <div
            className={`${animations.enter_s} flex flex-column justify-center mt-10 pa-4 xxl_pa-6 br-2 ba b-primary w-100 xxl_w-90 mh-auto`}
            style={{ background: "white" }}
          >
            {assets?.icon ? (
              <div
                className={`${
                  !assets.iconColor && "primary"
                } flex flex-column items-center mt-4`}
                style={{ color: assets.iconColor }}
              >
                {getIcon(assets.icon, 6)}
              </div>
            ) : (
              <CheckCircleFilled
                className="mt-4 self-center"
                style={{ color: "#60d936", fontSize: "80px" }}
              />
            )}

            <h1
              className="f-7 md_f-8 xl_f-9 xxl_f-10 fwt mt-6 lh-1 tc"
              style={{ color: brand.stepTitleColor || "black" }}
            >
              {title}
            </h1>
            {subTitle && (
              <h2
                className="f-5 xl_f-6 gray-7 mt-5 tc"
                dangerouslySetInnerHTML={{
                  __html: subTitle ?? "",
                }}
              />
            )}
            <p
              className="f-4 md_mt-6 mt-4 mb-6"
              dangerouslySetInnerHTML={{
                __html: description ?? "",
              }}
            />
            {/* If products have been configured to be shown, display a modal after a specified # of milliseconds. */}
            {assets?.featuredProductsModal?.products && (
              <ProductsSliderModal
                products={assets.featuredProductsModal?.products}
                modalTitle={
                  assets.featuredProductsModal.title ||
                  "Get the most out of your new membership!"
                }
                modalSubtitle={
                  assets.featuredProductsModal.subtitle ||
                  "You're pre-qualified for the following products:"
                }
                accentColor={brand.primaryColor || "black"}
                loadDelay={assets.featuredProductsModal.loadDelay || 4000}
              />
            )}
            {children}
          </div>
        </div>
      </>
    );
  }

  // Default render:
  return (
    <div className={styles.content}>
      {/* Progress Bar */}
      <Row>
        <Col
          className={hideProgressBar ? "" : "lh-2 pt-4 mb-6"}
          span="18"
          lg={12}
        >
          <div className="flex">
            {previousSlug && (
              <Button
                type="primary"
                onClick={handleClick}
                className="self-center mr-4 br-100 ph-2 pv-1 h-6 w-7 s-2 lift"
              >
                <LeftOutlined />
              </Button>
            )}
            {!hideProgressBar && (
              <>
                <span className="mr-2 self-center fwb primary f-4">
                  {stepProgress}%
                </span>
                <Col className="w-100 bl b-primary pl-3">
                  <Progress
                    trailColor="#e3e1e1"
                    strokeColor={brand.primaryColor}
                    percent={stepProgress}
                    showInfo={false}
                  />
                  <small className="fsi">Progress</small>
                </Col>
              </>
            )}
          </div>
        </Col>
      </Row>
      {/* Step Title */}
      <h1
        className="f-7 sm_f-8 fwt mb-0 lh-1 mb-4"
        style={{ color: brand.stepTitleColor || "black" }}
        dangerouslySetInnerHTML={{
          __html: title ?? "",
        }}
      />
      {/* Step Description */}
      <p
        className="f-4 gray-8 mb-5 lh-3"
        dangerouslySetInnerHTML={{
          __html: description ?? "",
        }}
      />
      {children}
    </div>
  );
};

export default StepContentSection;
