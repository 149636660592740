import { ReactNode, useMemo } from "react";
import { useRouter } from "next/router";
import { message } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { StripeStatusWrapper } from "@dreambigger/shared/src/components/stripe-payment-form";

export interface StripePaymentStatusProps {
  children?: ReactNode;
  onFinish: (status: string, paymentMethod: string) => void;
  stripeAccount?: string;
}

export default function StripePaymentStatus({
  children,
  onFinish,
  stripeAccount,
}: StripePaymentStatusProps) {
  const router = useRouter();
  //use Stripe connect account if available
  const stripePromise = useMemo(
    () =>
      stripeAccount
        ? loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || "", {
            stripeAccount,
          })
        : loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || ""),
    [stripeAccount]
  );

  const { payment_intent_client_secret } = router.query;

  if (!payment_intent_client_secret) {
    message.error(
      "Something is wrong with payment configuration. Please contact support"
    );
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: payment_intent_client_secret as string }}
    >
      <StripeStatusWrapper onFinish={onFinish} intentType="payment">
        {children}
      </StripeStatusWrapper>
    </Elements>
  );
}
