import { useState, useCallback, useEffect } from "react";
import { Form } from "@dreambigger/design-system/src/components";
import { Checkbox, Radio } from "antd";
import {
  PlaidAccount,
  PlaidAuthStepAssets,
} from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import styles from "./steps.module.scss";
import StepWrapper from "./step-wrapper";
import { usePlaid } from "../api";
import { Button } from "@dreambigger/design-system/src/components";
import { ArrowRightOutlined } from "@ant-design/icons";

import { usePlaidLink, PlaidLinkOnSuccess } from "react-plaid-link";
import { useRouter } from "next/router";

const accountUuidKey = "financialPlaidAccountUuid";

const getFirstValue = (val: string | string[]) =>
  Array.isArray(val) ? val[0] : val;
export const confirmationKey = "accountConfirmation";

export default function PlaidAuth({ flow, step, brand, progress }: StepProps) {
  const assets: PlaidAuthStepAssets = step.assets;
  const {
    institutionName,
    linkCustomizationName,
    ctaText,
    confirmationText,
    helpText1,
    helpText2,
    termsText,
  } = assets;

  const router = useRouter();
  const { token: tokenParam, received_redirect_uri: receivedRedirectUriParam } =
    router.query;

  // state
  const [token, setToken] = useState<string | null>(null);
  const [receivedRedirectUri, setReceivedRedirectUri] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [accountUuid, setAccountUuid] = useState<string | null>(null);
  const [callbackMode, setCallbackMode] = useState<boolean>(false);
  const [availableAccounts, setAvailableAccounts] = useState<PlaidAccount[]>(
    []
  );

  // callbacks
  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken) => {
    const linkAccount = async () => {
      setProcessing(true);
      const accounts = await plaidHelper.linkAuthAccount({
        publicToken,
      });
      setAccountUuid(accounts?.[0]?.uuid);
      setAvailableAccounts(accounts);
      setProcessing(false);
    };

    setAccountUuid(null);
    linkAccount();
  }, []);

  const handleClick = () => launchPlaid();

  // custom hooks
  const [form] = Form.useForm();
  const plaidHelper = usePlaid(flow.financialInstitution.id);
  const { open: launchPlaid, ready: plaidIsReady } = usePlaidLink({
    token,
    receivedRedirectUri,
    onSuccess,
  });

  // use effect
  useEffect(() => {
    const initialValues = form.getFieldsValue([accountUuidKey]);
    setAccountUuid(initialValues[accountUuidKey]);
  }, [form]);

  const checkDisabled = () => {
    setDisabled(!accountUuid);
    if (!accountUuid) {
      setDisabled(true);
      return;
    }
    if (confirmationText) {
      const checked = form.getFieldValue(confirmationKey);
      if (!checked) {
        setDisabled(true);
        return;
      }
    }
    setDisabled(false);
  };

  useEffect(() => {
    checkDisabled();
  }, [accountUuid]);

  useEffect(() => {
    form.setFieldsValue({ [accountUuidKey]: accountUuid });
  }, [accountUuid, form]);

  useEffect(() => {
    if (tokenParam && receivedRedirectUriParam) {
      // plaid in callback mode
      setCallbackMode(true);
      setToken(getFirstValue(tokenParam));
      setReceivedRedirectUri(
        decodeURIComponent(getFirstValue(receivedRedirectUriParam))
      );
    } else {
      // get link_token from your server when component mounts
      const createLinkToken = async () => {
        setProcessing(true);

        const { linkToken } = await plaidHelper.createLinkToken({
          institutionName: institutionName,
          linkCustomizationName: linkCustomizationName,
          redirectUri: `${window.location.origin}/plaid/oauth/${flow.financialInstitution.id}`,
          options: {
            returnUrl: window.location.href,
          },
        });

        setToken(linkToken);
        setProcessing(false);
      };

      createLinkToken();
    }
  }, []);

  useEffect(() => {
    if (plaidIsReady && callbackMode) {
      launchPlaid();
    }
  }, [plaidIsReady]);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
    processing,
  };

  return (
    <StepWrapper {...wrapperProps}>
      {helpText1 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: helpText1 ?? "",
            }}
          />
        </div>
      )}

      {!processing && availableAccounts?.length > 0 && (
        <Form.Item name={accountUuidKey}>
          <Radio.Group className="w-100" defaultValue={accountUuid}>
            {availableAccounts?.map((availableAccount, key) => {
              return (
                <div
                  key={`container-${availableAccount.uuid}`}
                  className={`pt-2 ${
                    key !== (availableAccounts?.length || 0) - 1 && "bb"
                  }`}
                  style={{ borderColor: "#bfbfbf" }}
                >
                  <Radio
                    key={availableAccount.uuid}
                    value={availableAccount.uuid} // radio values must always be strings.
                    className="pa-2 ao-lg-radio lightenPrimaryBkd lightenPrimaryBkdChecked w-100 subtleLift br-4 mb-2 checkedFill"
                    onChange={() => setAccountUuid(availableAccount.uuid)}
                  >
                    <div className="f-5">
                      {`${availableAccount.item?.institution?.name} - ` ?? ""}
                      {availableAccount.name}
                    </div>
                    {availableAccount.mask && (
                      <div className="f-2 mt-1">
                        Account ending in {availableAccount.mask}
                      </div>
                    )}
                  </Radio>
                </div>
              );
            })}
          </Radio.Group>
        </Form.Item>
      )}

      <Form.Item>
        <Button type="dashed" onClick={handleClick} disabled={!plaidIsReady}>
          {ctaText || "Connect"} <ArrowRightOutlined />
        </Button>
      </Form.Item>

      {helpText2 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: helpText2 ?? "",
            }}
          />
        </div>
      )}
      {confirmationText && (
        <div className="flex items-center">
          <Form.Item
            name={confirmationKey}
            valuePropName="checked"
            className="mr-3 mb-6"
          >
            <Checkbox className="ao-lg-checkbox" onChange={checkDisabled} />
          </Form.Item>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: confirmationText,
            }}
          />
        </div>
      )}
      {termsText && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: termsText ?? "",
            }}
          />
        </div>
      )}
    </StepWrapper>
  );
}
