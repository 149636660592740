import { useEffect, useState, useMemo } from "react";
import { Row, message, Spin } from "antd";
import { StepContentSection } from "../components";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { SuccessStepAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import { useApplication } from "../api";
import { useSegment } from "@dreambigger/shared/src/hooks";
import router from "next/router";
import format from "date-fns/format";

const spinnerIcon = <Loading3QuartersOutlined spin />;

export default function Success({ flow, step, brand, progress }: StepProps) {
  // State variables for saving and submitting application
  const [saving, setSaving] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");

  const segment = useSegment();

  const assets: SuccessStepAssets = step.assets;
  const { slug, type } = step;
  const flowId = flow.id;
  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow, router.query]
  );
  const submissionDate = assets.submissionDateFormat
    ? format(new Date(), assets.submissionDateFormat)
    : new Date().toISOString();

  // If the application has an external redirect url, set it to state.
  // We will lose access to the application once it is submitted
  useEffect(() => {
    if (!application?.externalRedirect) {
      return;
    }
    setRedirectUrl(application.externalRedirect);
  }, [application?.externalRedirect]);

  useEffect(() => {
    // Before attempting to update, check that the application is available,
    // hasn't been submitted and a save isn't in progress.
    if (saving || submitted || !application) {
      return;
    }

    // Update and save the application. While "saving" is flagged, a spinner animation will be rendered.
    setSaving(true);
    applicationHelper
      .update(
        application.id,
        {
          slug,
          type,
          fields: {
            submittedAt: submissionDate,
          },
        },
        true
      )
      .then(() => {
        // set submission status and track in segment
        segment.track({
          action: "App State Change",
          label: "Application Submitted",
        });
        setSubmitted(true);

        // Commenting this out. We should not have client specific code blocks.
        // Can be refactored into a generic trigger later if needed.

        /*
        // NATIONAL VOLUNTEER WEEK ONLY - track submission in Google Tag Manager.
        if (
          flow.googleTagManager?.container?.name === "national-volunteer-week"
        ) {
          // Track the "applicationSubmit" event in GTM.
          const gtmEventName = "applicationSubmit";

          // The data we will want to push with the GTM event.
          let dataLayerAttributes = {};

          // If a thrivent member use the name from the "name" slug step.
          const name = applicationHelper.findResponseByApplicationId(
            application.id,
            "name"
          );

          // If a non-thrivent member use the name from the "name-non-member" slug step.
          const nonMemberName = applicationHelper.findResponseByApplicationId(
            application.id,
            "name-non-member"
          );

          const fullName =
            name?.fields["fullName"] || nonMemberName?.fields["fullName"] || "";

          // Push data needed by Referral Rock to the NVW GTM Container.
          dataLayerAttributes = {
            event: gtmEventName,
            onboardingApplicationId: application.id,
            // Will send userId to referral rock as externalIdentifer via GTM script
            userId: application.financialUserId,
            fullName,
          };

          // Push the event data to Google Tag Manager.
          if (dataLayerAttributes) {
            if (process.env.NEXT_PUBLIC_GTM_DEBUG === "true") {
              console.info(
                "Pushing applicationSubmit event to Google Tag Manager."
              );
            }
            gtm.push(dataLayerAttributes);
          }
        }
        */
      })
      .finally(() => setSaving(false));
  }, [saving, application]);

  //once application is submitted trigger the theme reload (and confetti!)
  useEffect(() => {
    if (!submitted) {
      return;
    }
    router.push({
      query: {
        ...router.query,
        flowId,
        redirectUrl,
        stepSlug: slug,
        submission: "complete",
      },
    });
  }, [submitted]);

  //once theme is reloaded, trigger success message and redirect (if configured)
  useEffect(() => {
    // If submission has gone through, exit and redirect (if redirect is configured). Else, save/update.
    if (router.query.submission !== "complete") {
      return;
    }
    const generateRedirectUrl = () => {
      let url = null;
      if (router.query.redirectUrl) {
        url = Array.isArray(router.query.redirectUrl)
          ? router.query.redirectUrl[0]
          : router.query.redirectUrl;
      } else if (assets.externalRedirect) {
        url = assets.externalRedirect;
      }
      if (!url) {
        return null;
      }
      if (!url.includes("http")) {
        return `https://${url}`;
      }
      return url;
    };

    // Show a submission success message unless confetti is disabled.
    if (!assets.disableConfetti) {
      message.success("Submission Successful!");
    }

    setTimeout(
      () => {
        // Redirect url is set according to the following priority: (1) If a url was specified for this specific application, (2) if url was specified for the step in step assets, (3) none
        const redirectUrl = generateRedirectUrl();
        //using replace to make it harder to navigate back after application is submitted
        redirectUrl && window.location.replace(redirectUrl);

        //Redirect the user after configured delay or default to 5 seconds.
      },
      assets.externalRedirectDelayInMs ? assets.externalRedirectDelayInMs : 5000
    );
  }, [router.query.submission]);

  return (
    <StepContentSection
      brand={brand}
      stepProgress={router.query.submission !== "complete" ? 100 : progress}
      title={
        router.query.submission !== "complete"
          ? "Processing..."
          : assets.title ?? ""
      }
      subTitle={
        router.query.submission !== "complete" ? "" : assets.subTitle ?? ""
      }
      description={
        router.query.submission !== "complete"
          ? "Please do not navigate away or close this window."
          : assets.description ?? ""
      }
      assets={assets}
      stepType={type}
    >
      {/* While saving, render a spinner */}
      <Row>{saving && <Spin indicator={spinnerIcon} />}</Row>
    </StepContentSection>
  );
}
