import { useState, useEffect, useMemo, useCallback } from "react";

import { api } from "@dreambigger/shared/src/api/acquire";
import { message, Form } from "@dreambigger/design-system";
import { ExistingCoreAccountStepAssets } from "@dreambigger/shared/src/types";

import StepWrapper from "./step-wrapper";
import { useApplication } from "../api";
import { StepProps } from "../pages/flows/[flowId]";
import { CheckCircleFilled, Loading3QuartersOutlined } from "@ant-design/icons";
import { CoreBankingApi } from "@dreambigger/shared/src/api/acquire";
import { useAuthToken } from "../passwordless-auth";
import { changeStepForward } from "../utils/changeStepForward";
import { useSegment } from "@dreambigger/shared/src/hooks";
import { prefixFieldsWithSlug } from "../utils/prefixStepSlug";
import { Spin } from "antd";
import { ResponseFields } from "@dreambigger/shared/src/types";
interface Account {
  id: string;
  number: string;
}

const spinnerIcon = <Loading3QuartersOutlined spin />;

export default function StepExistingCoreAccounts({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const {
    textPrefix,
    helpText,
    errorMessage,
    initialPersonDataSlug,
    coreBankingConfigurationUuid,
    nextSlug,
    tinFieldKey,
    birthdayFieldKey,
    continueWhenNoAccountsFound,
    allowNewAccount,
  }: ExistingCoreAccountStepAssets = step.assets;

  const { slug, type } = step;

  const tokenHelper = useAuthToken(flow.financialInstitution.id);

  // Initialize the various api's we need for this step.
  const coreBankingApi = new CoreBankingApi(api, tokenHelper);

  // State for managing whether the submit button is enabled.
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccountId, setSelectedAccountId] =
    useState<string | null>(null);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState<string>();
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  const segment = useSegment();

  const applicationHelper = useApplication(flow.financialInstitution.id);

  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  const initialPersonData = useMemo(() => {
    if (!initialPersonDataSlug) {
      return;
    }

    return applicationHelper.findResponse(
      flow.id,
      "draft",
      initialPersonDataSlug
    );
  }, [applicationHelper, flow]);

  const previousResponse = useMemo(() => {
    return applicationHelper.findResponse(flow.id, "draft", slug);
  }, [applicationHelper, flow]);

  const checkDisabled = useCallback(() => {
    if (!selectedAccountId) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [form, selectedAccountId]);

  const handleItemClick = (account: Account) => {
    setSelectedAccountId(account.id);
    setSelectedAccountNumber(account.number);
  };

  const handleNext = () => {
    if (!application) {
      message.error("Unable to find application");
      return;
    }

    setProcessing(true);

    // Update step with selected account or designate as "newAccount"
    const saveData: ResponseFields = {};

    if (
      selectedAccountId === "newAccount" ||
      (continueWhenNoAccountsFound === true && !accounts.length)
    ) {
      saveData.newAccount = true;
    } else {
      if (!selectedAccountId || !selectedAccountNumber) {
        message.error(
          "There was a problem saving this form. Please contact support."
        );
        return;
      }
      saveData.accountId = selectedAccountId;
      saveData.accountNumber = selectedAccountNumber;
    }

    // Save the response and move on to the next step.
    applicationHelper
      .update(application.id, {
        slug,
        type,
        fields: saveData,
      })
      .then(() => {
        changeStepForward(nextSlug, saveData);
      })
      .finally(() => {
        setProcessing(false);

        const prefixedProperties = prefixFieldsWithSlug({
          fields: saveData,
          slug,
        });

        segment.track({
          action: "Button Click",
          label: `Submit - ${slug}`,
          properties: prefixedProperties,
        });
      });
  };

  // Load accounts from core based on the person's TIN and birthday.
  useEffect(() => {
    if (initialPersonData && initialPersonData.fields) {
      coreBankingApi
        .getAccounts({
          coreBankingConfigurationUUID: coreBankingConfigurationUuid!,
          tin: initialPersonData?.fields?.[tinFieldKey ?? ""] as string,
          birthday: initialPersonData.fields?.[
            birthdayFieldKey ?? ""
          ] as string,
        })
        .then(({ data }) => {
          if (previousResponse && previousResponse?.fields?.accountId) {
            const prefillAccount = data.find(
              (account: Account) =>
                account.id === previousResponse.fields.accountId
            );
            if (prefillAccount) {
              setSelectedAccountId(prefillAccount.id);
              setSelectedAccountNumber(prefillAccount.number);
            }
          }
          setAccounts(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  }, [flow, previousResponse, initialPersonData]);

  // If no accounts are found and the step has been configured to automatically advance, save "newAccount: true" as the response field and move on to the next step.
  useEffect(() => {
    if (!loading && !accounts.length && continueWhenNoAccountsFound === true) {
      handleNext();
    }
  }, [loading]);

  useEffect(() => {
    checkDisabled();
  }, [checkDisabled]);

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
    processing,
    handleNext,
  };

  // ----- FINAL RENDER ------

  return (
    <StepWrapper {...wrapperProps}>
      {(loading || (!accounts.length && continueWhenNoAccountsFound)) && (
        <Spin indicator={spinnerIcon}></Spin>
      )}

      {/* Error message if no accounts are found.*/}
      {!loading && !accounts.length && !continueWhenNoAccountsFound && (
        <div className="flex flex-column gray-8">
          <p className="f-4 lh-4 mb-6">
            {errorMessage ??
              "We were unable to find any existing accounts. If you would like to create a new account, please go back and select the option to create a new account."}
          </p>
        </div>
      )}

      {/* If Enabled - New Account Button */}
      {!loading && accounts.length > 0 && allowNewAccount === true && (
        <div
          key={"newAccount"}
          className={`ph-5 pv-3 br-4 mb-2 s-2 pointer hlight-border-primary subtleLiftNoShadow ${
            selectedAccountId === "newAccount" ? "s-5" : "s-3"
          }`}
          style={{
            overflow: "hidden",
            backgroundColor: "white",
            border:
              selectedAccountId === "newAccount"
                ? `solid ${brand.primaryColor} 1px`
                : "solid lightGray 1px",
          }}
          onClick={() =>
            handleItemClick({ id: "newAccount", number: "newAccount" })
          }
        >
          <div className="fwb">
            {/* ACCOUNT NUMBER */}
            <div className="f-5 xl_f-6 fwt">
              <CheckCircleFilled
                style={{
                  fontSize: "20px",
                  paddingRight: "10px",
                  color:
                    selectedAccountId === "newAccount"
                      ? brand.primaryColor
                      : "transparent",
                }}
              />
              Open New Account
            </div>
          </div>
        </div>
      )}

      {/* List of Existing Accounts */}
      {!loading && accounts.length > 0 && (
        <>
          <p className="f-4 gray-8 lh-4 mb-6`">
            {helpText ??
              "Please choose which account you would like to add additional services to:"}
          </p>
          {accounts.map((account: Account) => {
            return (
              <div
                key={account.id}
                className={`ph-5 pv-3 br-4 mb-2 s-2 pointer hlight-border-primary subtleLiftNoShadow ${
                  account.id === selectedAccountId ? "s-5" : "s-3"
                }`}
                style={{
                  overflow: "hidden",
                  backgroundColor: "white",
                  border:
                    account.id === selectedAccountId
                      ? `solid ${brand.primaryColor} 1px`
                      : "solid lightGray 1px",
                }}
                onClick={() => handleItemClick(account)}
              >
                <div className="fwb">
                  {/* ACCOUNT NUMBER */}
                  <div className="f-5 xl_f-6 fwt">
                    <CheckCircleFilled
                      style={{
                        fontSize: "20px",
                        paddingRight: "10px",
                        color:
                          account.id === selectedAccountId
                            ? brand.primaryColor
                            : "transparent",
                      }}
                    />
                    {textPrefix ?? "Account # "}
                    {account.number}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </StepWrapper>
  );
}
