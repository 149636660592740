import { useState, useCallback, useEffect } from "react";
import { Form } from "@dreambigger/design-system/src/components";
import { PlaidStepAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import styles from "./steps.module.scss";
import StepWrapper from "./step-wrapper";
import { usePlaid } from "../api";
import { Button } from "@dreambigger/design-system/src/components";
import { ArrowRightOutlined } from "@ant-design/icons";

import { usePlaidLink, PlaidLinkOnSuccess } from "react-plaid-link";

export default function PlaidIncome({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const [token, setToken] = useState<string | null>(null);
  const plaidHelper = usePlaid(flow.financialInstitution.id);

  // TODO: Set this to "true" once this is hooked up and functioning correctly. For now, keep skippable.
  const [disabled, setDisabled] = useState(false);

  // get link_token from your server when component mounts
  useEffect(() => {
    const createLinkToken = async () => {
      const response = await plaidHelper.createIncomeLinkToken({
        overrideUserId: assets.overrideUserId,
      });
      const { linkToken } = response.data;
      setToken(linkToken);
    };
    createLinkToken();
  }, []);

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    console.log(publicToken, metadata);
    setDisabled(false);
  }, []);

  const { open: launchPlaid, ready: plaidIsReady } = usePlaidLink({
    token,
    onSuccess,
    // onEvent
    // onExit
  });

  const handleClick = () => launchPlaid();

  const [form] = Form.useForm();

  const assets: PlaidStepAssets = step.assets;

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    disabled,
  };

  return (
    <StepWrapper {...wrapperProps}>
      {assets.helpText1 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.helpText1 ?? "",
            }}
          />
        </div>
      )}

      <Form.Item>
        <Button type="dashed" onClick={handleClick} disabled={!plaidIsReady}>
          {assets.ctaText || "Connect"} <ArrowRightOutlined />
        </Button>
      </Form.Item>

      {assets.helpText2 && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.helpText2 ?? "",
            }}
          />
        </div>
      )}

      {assets.termsText && (
        <div>
          <p
            className={`${styles.textLinks} f-4 gray-8 lh-4 mb-6`}
            dangerouslySetInnerHTML={{
              __html: assets.termsText ?? "",
            }}
          />
        </div>
      )}
    </StepWrapper>
  );
}
