import { useState, useEffect } from "react";
import {
  Button,
  Input,
  MaskedInput,
  Select,
  Form,
  Checkbox,
  DatePicker,
} from "@dreambigger/design-system/src/components";
import {
  FinancialBrand,
  Person,
  SignerRequirement,
  PeopleField,
} from "@dreambigger/shared/src/types";
import { defaultStatesAbbreviated } from "../utils/defaults";
import { Row, Col } from "antd";
import {
  convertCamel,
  capitalizeFirstLetter,
  formatAsNationalPhoneNum,
} from "../utils/textFormatting";
import { HomeOutlined } from "@ant-design/icons";
import { AddressAutocomplete, AddressFields } from "../components";
import {
  isValidSsn,
  isValidPhoneNumber,
  isValidEmail,
} from "../utils/validators";
import dayjs from "dayjs";

export interface PersonCardType {
  initialData: Person;
  peopleData: any;
  setPeopleData: any;
  brand: FinancialBrand;
  fields: PeopleField[];
  requirements: SignerRequirement[];
}

export default function PersonCard({
  initialData,
  peopleData,
  setPeopleData,
  brand,
  fields,
  requirements,
}: PersonCardType) {
  const [data, setData] = useState<Person>();
  const [isEditing, setIsEditing] = useState(!initialData.firstName && true);
  const personId = initialData.id;
  const [form] = Form.useForm();
  const isUser = initialData.id === peopleData[0].id; // The user will always be the first card

  // Load the initial data into this component's data state. This is used for prefilling.
  useEffect(() => {
    // Convert existing birthday field into format that dayjs understands
    if (initialData.birthday) {
      initialData.birthday = dayjs(initialData.birthday as string);
    }
    // Format phone numbers returned from the backend to display into National Format.
    if (initialData.phone) {
      initialData.phone = formatAsNationalPhoneNum(
        initialData.phone.toLocaleString()
      );
    }
    setData(initialData);
    form.setFieldsValue(initialData);
  }, [initialData]);

  // --------------------------------------
  // SAVE PERSON INFO/CARD
  // Turn off editing mode and pass the updated data to the parent component (people.tsx)
  // --------------------------------------
  const handleSave = (data: any) => {
    const currentId = personId;
    if (isEditing) {
      setIsEditing(false);
    }
    // Search array for the id that matches with the person being editted and overwrite it with new data.
    const updatedPeopleData = peopleData.map((person: Person) => {
      // if the id of the person in the array matches the id of the person on this card, then overwrite the original data with the data from the card.
      if (person.id === currentId) {
        return { id: currentId, ...data };
      }
      // ...otherwise, keep the individual as is.
      return person;
    });
    setPeopleData(updatedPeopleData);
  };

  // --------------------------------------
  // CANCEL EDIT
  // Turn off editing mode without making changes. If this is a new card, delete the card instead.
  // --------------------------------------
  const handleCancel = () => {
    if (!initialData.firstName) {
      const currentId = personId;
      const updatedPeopleData = peopleData.filter(
        (person: Person) => person.id !== currentId
      );
      setPeopleData(updatedPeopleData);
      return;
    }
    // Reset the field inputs to whatever initial data they had previously received
    form.setFieldsValue(data);
    setIsEditing(false);
  };

  // --------------------------------------
  // DELETE PERSON INFO/CARD
  // Removes the person representing this card from the parent "peopleData" array
  // --------------------------------------
  const handleDelete = () => {
    const currentId = personId;
    const updatedPeopleData = peopleData.filter(
      (person: Person) => person.id !== currentId
    );
    setPeopleData(updatedPeopleData);
    form.resetFields();
  };

  // --------------------------------------
  // ADDRESS SELECT
  // Allows the user to fill in address details in the form by selecting an address. Based off method in address.tsx
  // --------------------------------------
  const addressKey = "address";
  const apartmentKey = "apartment";
  const cityKey = "city";
  const stateKey = "state";
  const zipKey = "zip";
  const handleAddressSelect = (
    address: string,
    addressFields?: AddressFields,
    isPopulated = false
  ) => {
    const updatedValues = {
      [addressKey]: (isPopulated && addressFields?.address) || address,
      [stateKey]: (isPopulated && addressFields?.state) || "",
      [cityKey]: (isPopulated && addressFields?.locality) || "",
      [zipKey]: (isPopulated && addressFields?.postcode) || "",
    };
    form.setFieldsValue(updatedValues);
    handleInput(updatedValues, updatedValues);
  };

  // --------------------------------------
  // SAVE DATA ON INPUT CHANGE WHEN NOT EDITING
  // When any value in the form changes, immediately save the form if it is not in editing mode.
  // * This currently only triggers when the requirement assignment checkboxes on the front of the card are clicked.
  // * Antd automatically passes changedValues and values into this function.
  // --------------------------------------
  const handleInput = (_changedValues: any, values: any) => {
    !isEditing && handleSave(values);
  };

  // --------------------------------------
  // CHECK ASSIGNMENT ELIGIBILITY
  // Searches peopleData from parent component (parent.tsx) to see if enough people have
  // already been designated for a given requirement. If the required amount is met, disable the option
  // to assign more.
  // --------------------------------------
  const checkAssignmentEligibility = (requirement: SignerRequirement) => {
    // If data hasn't loaded yet, allow the checkbox to be checked (should never happen).
    if (!data) return false;
    // Convert the requirementName into the key format used by the application. Example: "beneficialOwner" => "isBeneficialOwner"
    const key: string = `is${capitalizeFirstLetter(requirement.name)}`;
    // Check all people on the form if the key is marked "true." If so, increment the count.
    let count = 0;
    for (const person of peopleData) {
      if (person[key] === true) {
        count++;
      }
    }
    // If the count matches the required number of assignments and the checkbox is not checked, then return true (disabling the button)
    // * We want to allow the user to uncheck an existing checkbox to assign to someone else.
    const isChecked = data[key];
    return count === requirement.numRequired && !isChecked;
  };

  // --------------------------------------
  // RENDER A CUSTOM DATEPICKER WITH DISABLE LOGIC
  // Disable requirements are established in the config.
  // [ TO DO ] - Make a custom component with all of this logic.
  // --------------------------------------

  const renderDatePicker = (field: any) => {
    return (
      <DatePicker
        className="w-100"
        config={field.config?.date}
        defaultPickerValue={
          field.config?.date && dayjs(field.config?.date?.defaultPickerDate)
        }
      />
    );
  };

  // --------------------------------------
  // RENDER INPUTS BASED ON THEIR NAME
  // --------------------------------------
  const renderInput = (field: PeopleField) => {
    switch (field.name) {
      case "ssn":
      case "socialSecurityNumber":
        return <MaskedInput mask="000-00-0000" />;
      case "phone":
        return <MaskedInput mask="(000) 000-0000" />;
      default:
        return <Input />;
    }
  };

  // --------------------------------------
  // VALIDATE INPUTS
  // Validation errors are triggered when the user hits "Save" in editing mode
  // (this is the designated form submit button)
  // --------------------------------------
  const isValidInput = (field: PeopleField, value: any) => {
    // If no entry is provided, this validation step should always return true.
    // * If the Form.Item is empty but marked {required: true}, it will still throw error.
    if (!value) {
      return true;
    }
    switch (field.name) {
      case "email":
        return isValidEmail(value);
      case "ssn":
      case "socialSecurityNumber":
        return isValidSsn(value);
      case "phone":
        return isValidPhoneNumber(value, "US");
      default:
        return true;
    }
  };

  // --------------------------------------
  // SAVE DATA ON INPUT CHANGE WHEN NOT EDITING
  // When any value in the form changes, immediately save the form if it is not in editing mode.
  // * This currently only triggers when the requirement assignment checkboxes on the front of the card are clicked.
  // * Antd automatically passes changedValues and values into this function.
  // --------------------------------------
  const additionalKeysExist = (data: Person) => {
    let standardKeys = [
      "id",
      "firstName",
      "middleName",
      "title",
      "lastName",
      "phone",
      "email",
    ];
    for (const key of Object.keys(data)) {
      if (
        key.slice(0, 2) !== "is" &&
        !standardKeys.includes(key) &&
        data[key]
      ) {
        return true;
      }
    }
    return false;
  };

  // -------------- RENDER ------------------------------------------

  // * NOTE: We're not using Antd form to do a normal form submit, but just to take advantage of its dynamic value storing and validation features.
  // *       The parent file (person.tsx) will package the information we're passing to it with the handleSave function and
  // *       save that to the flow application.

  // ----------------------------------------------------------------

  return (
    <div className="ba br-2 b-primary pv-3 pl-3 pr-0 mb-4 bg-white">
      <Form onValuesChange={handleInput} form={form} onFinish={handleSave}>
        {/* ------------- CARD DISPLAY (WHILE EDITING)------------------ */}
        <div className={isEditing ? "" : "dn"}>
          <h3 className="fwb">Add/Edit Details</h3>
          <Row>
            {/**** TEXT INPUTS ****/}
            {fields.map((field, key) => {
              const name = field.name;
              // Rendering logic for all inputs except "address", which takes up extra space and is located at the bottom of the other inputs.
              // * For future development, if we need additional larger pre-formatted items, I recommend expanding "address" into a list.
              if (name !== "address") {
                return (
                  <Col xs={12} md={6} className="pr-3" key={field.name}>
                    {field.required && <span className="red-1">* </span>}
                    <label>{convertCamel(name)}</label>
                    {/* Custom rendering logic for birthday because antd validator method doesn't play well with dayjs */}
                    {name === "birthday" ? (
                      <Form.Item
                        key={key}
                        name={field.name}
                        valuePropName={"value"}
                        className="mb-1"
                        rules={[
                          {
                            required: field.required,
                            message: "Field required",
                          },
                        ]}
                      >
                        {renderDatePicker(field)}
                      </Form.Item>
                    ) : (
                      // Default rendering logic for input
                      <Form.Item
                        key={key}
                        name={field.name}
                        valuePropName={"value"}
                        className="mb-1"
                        initialValue={field.placeholder}
                        validateTrigger={""} // Only validates on form submission
                        rules={[
                          {
                            required: field.required,
                            message: "Field required",
                          },
                          {
                            validator: (_, value) => {
                              if (!isValidInput(field, value)) {
                                return Promise.reject(
                                  new Error(
                                    field.errorMessage ||
                                      `Invalid input or formatting`
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        {renderInput(field)}
                      </Form.Item>
                    )}
                  </Col>
                );
              }
            })}
          </Row>

          {/**** ADDRESS INPUT ****/}
          {fields.map((field) => {
            const name = field.name;
            if (name !== "address") {
              return;
            }

            // All inputs are required or not required (except apartment, which is never required)
            const isRequired = field.required;
            return (
              <Col xs={24} className="mt-4" key={field.name}>
                <span className="primary f-1 mb-0">
                  ------------- Address Details -------------
                </span>
                {/* ROW - Street and Appt/Building/Suite */}
                <Row>
                  <Col xs={12} md={12} className="pr-3">
                    {isRequired && <span className="red-1">* </span>}
                    <label>Address</label>
                    <Form.Item
                      name={addressKey}
                      className="mb-1"
                      rules={[
                        {
                          required: isRequired,
                          message: "Field required",
                        },
                      ]}
                    >
                      <AddressAutocomplete
                        onAddressSelect={handleAddressSelect}
                        icon={<HomeOutlined />}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={12} className="pr-3">
                    <label>Apartment/Building/Suite</label>
                    <Form.Item name={apartmentKey} className="mb-1">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                {/* ROW - City, State, Zip */}
                <Row>
                  <Col xs={12} className="pr-3">
                    {isRequired && <span className="red-1">* </span>}
                    <label>City</label>
                    <Form.Item
                      name={cityKey}
                      validateTrigger={""}
                      rules={[
                        {
                          required: isRequired,
                          message: "Field required",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={5} className="pr-3">
                    {isRequired && <span className="red-1">* </span>}
                    <label>State</label>
                    <Form.Item
                      name={stateKey}
                      validateTrigger={""}
                      rules={[
                        {
                          required: isRequired,
                          message: "Field required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        className="w-100"
                        optionFilterProp="children"
                      >
                        {field?.config?.address?.allowedHomeStates
                          ? field.config.address.allowedHomeStates.map(
                              (state, key) => (
                                <option key={key} value={state}>
                                  {state}
                                </option>
                              )
                            )
                          : defaultStatesAbbreviated.map((state, key) => (
                              <Select.Option key={key} value={state}>
                                {state}
                              </Select.Option>
                            ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={7} className="pr-3">
                    {isRequired && <span className="red-1">* </span>}
                    <label>Zip Code</label>
                    <Form.Item
                      name={zipKey}
                      validateTrigger={""}
                      rules={[
                        {
                          required: isRequired,
                          message: "Field required",
                        },
                      ]}
                    >
                      <MaskedInput mask="00000" name="zip" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            );
          })}
          <Button
            type="primary"
            className="mr-2 f-2 ph-3 mt-1"
            htmlType="submit"
          >
            Save
          </Button>
          <Button
            type="primary"
            className="mr-2 f-2 ph-3"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>

        {/* ------------- CARD DISPLAY (WHILE NOT EDITING)------------------ */}
        <div className={isEditing ? "dn" : ""}>
          <Row>
            <Col xs={12} className="flex flex-column justify-evenly">
              <div
                className={isUser ? "bl pl-3 h-100" : ""}
                style={{ borderColor: isUser ? brand.primaryColor : "" }}
              >
                {/* LEFT COLUMN */}
                {/* Primary Applicant Label - Only displayed on User's Card */}
                {isUser && (
                  <span className="primary fwb ttu underline">
                    Primary Applicant
                  </span>
                )}
                {/* Display key data items */}
                <p className="f-5 fwb mb-0">
                  {typeof data?.firstName === "string"
                    ? `${data?.firstName} `
                    : ""}
                  {typeof data?.middleName === "string"
                    ? `${data?.middleName} `
                    : ""}
                  {typeof data?.lastName === "string"
                    ? `${data?.lastName} `
                    : ""}
                </p>
                <p className="f-3 fwb mb-0">
                  {typeof data?.title === "string" ? data?.title : ""}
                </p>
                <p className="mb-0 f-2 gray-7">
                  {typeof data?.email === "string" ? data?.email : ""}
                </p>
                <p className="mb-0 f-2 gray-7">
                  {typeof data?.phone === "string" ? data?.phone : ""}
                </p>
                {!isUser && data && additionalKeysExist(data) && (
                  <p className="mb-0 f-2 mt-2 primary">
                    + Additional Data Provided
                  </p>
                )}

                {/* "Your Signature is Required" - Only displayed on User's Card */}
                {isUser && (
                  <p className="f-2 mt-2 primary">
                    * Your Signature Is Required
                  </p>
                )}
              </div>
              {!isUser && (
                <div className="mt-4">
                  <Button
                    type="primary"
                    className="mr-2 f-2 ph-3"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    type="primary"
                    className="f-2 ph-3"
                    onClick={handleDelete}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </Col>
            {/* RIGHT COLUMN */}
            <Col xs={12} className="flex flex-column justify-evenly">
              {requirements.map((requirement: SignerRequirement) => {
                const isDisabled = checkAssignmentEligibility(requirement);
                return (
                  <Form.Item
                    className="mb-0"
                    name={`is${capitalizeFirstLetter(requirement.name)}`}
                    valuePropName={"checked"}
                    key={requirement.name}
                  >
                    <Checkbox
                      className="w-100 ph-2 pv-1 lightenPrimaryBkd bl b-primary"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      }}
                      // Run a normal check on whether to disable the input unless the user is the owner
                      // and the input is "beneficial owner", which the user MUST select, so it will be disabled automatically.
                      disabled={
                        !isUser
                          ? isDisabled
                          : requirement.name !== "beneficialOwner"
                          ? isDisabled
                          : true
                      }
                    >
                      <span className="f-4 fwn">
                        {convertCamel(requirement.name)}
                        {isDisabled && " (Requirement Met)"}
                      </span>
                    </Checkbox>
                  </Form.Item>
                );
              })}
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}
