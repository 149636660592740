import { useEffect, useState } from "react";
import truncate from "truncate";
import {
  CheckCircleFilled,
  ArrowRightOutlined,
  MinusCircleFilled,
} from "@ant-design/icons";
import { Form } from "@dreambigger/design-system/src/components";
import { ShareProduct, ShareProductRate } from "@dreambigger/shared/src/types";

// ---------------------------------- TYPES ----------------------------------
export interface ProductProps {
  product: ShareProduct;
  accentColor?: string;
  form: any;
  onChange?: (newValue: boolean) => void;
  radioSelection?: boolean;
  reset?: boolean;
  setResetAllExcept?: (resetAllExcept: string) => void;
  resetAllExcept?: string;
  disabled: boolean;
}

// ---------------------------------- COMPONENT ----------------------------------
const ProductCheckbox = ({
  product,
  accentColor,
  form,
  onChange,
  radioSelection,
  setResetAllExcept,
  resetAllExcept,
  disabled,
}: ProductProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const { title, slug, description, rates, url, required } = product;

  useEffect(() => {
    // If the product is required, it should be checked by default.
    if (required) {
      setIsChecked(true);
      return;
    }
    // Pre-fill
    setIsChecked(!!form.getFieldValue(slug));
  }, []);

  useEffect(() => {
    // If radioSelection is true, reset all other products when this one is selected.
    if (radioSelection && isChecked && setResetAllExcept) {
      form.resetFields();
      setResetAllExcept(slug);
    }
    form.setFieldsValue({
      [slug]: isChecked,
    });
    if (typeof onChange === "function") {
      onChange(isChecked);
    }
  }, [isChecked]);

  const handleClick = () => {
    if (required || disabled) return;
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (radioSelection && resetAllExcept && resetAllExcept !== slug) {
      setIsChecked(false);
    }
  }, [resetAllExcept]);

  // RENDER --------------------------------------------
  return (
    <Form.Item name={slug}>
      <div
        onClick={handleClick}
        className={`flex br-4 pointer hlight-border-primary ${
          disabled ? `disabled not-allowed` : `bg-white subtleLiftNoShadow`
        }
        } ${isChecked || required ? `s-5` : `s-3`}`}
        style={{
          overflow: "hidden",
          border:
            isChecked || required
              ? `solid ${accentColor} 1px`
              : "solid lightGray  1px",
        }}
      >
        {/* Left Column - Checked Icon */}
        <div className="pv-3 pl-3 br-100">
          {isChecked || required ? (
            <CheckCircleFilled
              style={{
                fontSize: "20px",
                color: accentColor,
              }}
            />
          ) : (
            <MinusCircleFilled
              style={{
                fontSize: "20px",
                color: "#dcdcde",
              }}
            />
          )}
        </div>
        {/* Right Column - Content */}
        <div className="flex flex-column justify-between pl-2 pr-3 pv-1 w-100">
          <div className="pb-2">
            <div className="fwb">
              {/* TITLE */}
              <div className="f-5 xl_f-6 fwt lh-2">
                {title}{" "}
                {required && (
                  <span style={{ color: accentColor }}>- REQUIRED</span>
                )}
              </div>
            </div>
            {/* DESCRIPTION */}
            {description && (
              <div className="pb-3 f-2 xxl_f-4 lh-3" style={{ color: "gray" }}>
                {truncate(description, 250)}
              </div>
            )}
            <div
              style={{
                borderTop: rates.length > 0 ? "solid lightGray 1px" : "",
                borderBottom: url ? "solid lightGray 1px" : "",
              }}
            >
              {/* RATES TABLE */}
              {rates.length > 0 && (
                <>
                  <table className={`w-100 tl f-1 xl_f-2 xxl_f-3`}>
                    {/* Header values are determined by the first rate in the array.
                    To avoid issues, please ensure that all rates in the array have the same headers. */}
                    <thead>
                      <tr>
                        {rates[0]?.title && <th>Title</th>}
                        {rates[0]?.minimumBalance && <th>Min Balance</th>}
                        {rates[0]?.maximumBalance && <th>Max Balance</th>}
                        {rates[0]?.apyPercent && <th>APY</th>}
                        {rates[0]?.interestRatePercent && <th>Interest %</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {rates.map((rate: ShareProductRate, arrayNum: number) => {
                        const {
                          title,
                          apyPercent,
                          interestRatePercent,
                          maximumBalance,
                          minimumBalance,
                        } = rate;
                        return (
                          <>
                            {/* Add background color to odd rows */}
                            <tr
                              style={{
                                backgroundColor: arrayNum % 2 ? "" : "#f0f5f5",
                              }}
                            >
                              {title && <td>{title}</td>}
                              {minimumBalance && <td>${minimumBalance}</td>}
                              {maximumBalance && <td>${maximumBalance}</td>}
                              {apyPercent && <td>{apyPercent}%</td>}
                              {interestRatePercent && (
                                <td>{interestRatePercent}%</td>
                              )}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
          {/* LEARN MORE Row (if url is provided in meta column)
           *** Opens link to product page in new window. */}
          {url && (
            <div
              style={{
                color: accentColor,
                borderBottom: "solid transparent 1px",
              }}
              className="fwb borderUnderline self-end"
            >
              <a
                href={url}
                target="_blank"
                style={{ color: "inherit" }}
                onClick={(e) => e.stopPropagation()} // Prevents handleClick from happening
              >
                LEARN MORE
                <ArrowRightOutlined className="ml-1" />
              </a>
            </div>
          )}
        </div>
      </div>
    </Form.Item>
  );
};

export default ProductCheckbox;
