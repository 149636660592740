import { useState, useEffect, useMemo } from "react";
import { Col, Checkbox, message } from "antd";
import { PocConsolidateDebtAssets } from "@dreambigger/shared/src/types";
import { StepProps } from "../pages/flows/[flowId]";
import { Form } from "@dreambigger/design-system";
import StepWrapper from "./step-wrapper";
import { useApplication } from "../api";
import { changeStepForward } from "../utils/changeStepForward";
import dayjs from "dayjs";

export default function PocConsolidateDebt({
  flow,
  step,
  brand,
  progress,
}: StepProps) {
  const assets: PocConsolidateDebtAssets = step.assets;

  // State for managing whether the submit button is enabled.
  const [disabled, setDisabled] = useState(false);
  const [form] = Form.useForm();
  const { creditLiabilities, nextSlug } = assets;

  const applicationHelper = useApplication(flow.financialInstitution.id);
  const application = useMemo(
    () => applicationHelper.find(flow.id, "draft"),
    [applicationHelper, flow]
  );

  // Ensure that disabled is always set to default to "true" upon step transition,
  // even when coming from another custom step where the component does not re-render.
  useEffect(() => {
    setDisabled(false);
  }, [step]);

  const handleNext = () => {
    if (!application) {
      message.error("Unable to find application");
      return;
    }
    if (!creditLiabilities) {
      message.error(
        "There was a problem saving this form. Please contact support."
      );
      return;
    }

    changeStepForward(nextSlug);
  };

  const wrapperProps = {
    step,
    brand,
    flow,
    progress,
    form,
    // handleInput,
    // handlePrefill,
    disabled,
    handleNext,
  };

  // ----- RENDER ------

  if (!assets.creditLiabilities) {
    return (
      <p>
        There was an error loading this page. Please contact support to finish
        your application.
      </p>
    );
  }
  return (
    <StepWrapper {...wrapperProps}>
      <Col xs={24}>
        <>
          <h2 className="fwt gray-8">{assets.subTitle}</h2>
          {assets.creditLiabilities?.map((creditLiability, index) => {
            return (
              <div
                key={creditLiability.lender}
                className={`pt-2 ${
                  index !== assets.creditLiabilities!.length - 1 && "bb"
                }`}
                style={{ borderColor: "#bfbfbf" }}
              >
                <Checkbox
                  key={index}
                  value={index}
                  className="pa-2 ao-lg-checkbox lightenPrimaryBkd lightenPrimaryBkdChecked w-100 subtleLift br-4 mb-2 checkedFill"
                >
                  <div className="f-5">
                    {creditLiability.lender} - Balance:{" "}
                    {creditLiability.currentBalance.toLocaleString("en-us", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </div>
                  <div className="f-2 mt-1">
                    Loan #: {creditLiability.accountNumber} - Date Opened:{" "}
                    {dayjs(creditLiability.accountOpenedDate).format(
                      "MMM YYYY"
                    )}
                  </div>
                  {creditLiability.monthlyPayment &&
                    creditLiability.pendingPayments &&
                    creditLiability.totalPayments && (
                      <div className="f-2 mt-1">
                        {creditLiability.monthlyPayment.toLocaleString(
                          "en-us",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}{" "}
                        / Month - {creditLiability.pendingPayments}/
                        {creditLiability.totalPayments} Payments Pending
                      </div>
                    )}
                </Checkbox>
              </div>
            );
          })}
        </>
      </Col>
    </StepWrapper>
  );
}
