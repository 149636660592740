import { AutoComplete, Input } from "@dreambigger/design-system/src/components";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useState, useCallback, useEffect, useMemo, ReactNode } from "react";

export interface AddressFields {
  fullAddress: string;
  address: string;
  subpremise: string;
  country: string;
  locality: string;
  postcode: string;
  state: string;
}

interface Props {
  value?: string;
  onAddressSelect: (
    address: string,
    addressFields?: AddressFields,
    isPopulated?: boolean
  ) => void;
  icon?: ReactNode;
  placeholder?: string;
  className?: string;
  useFullAddress?: boolean;
}

const AddressAutocompleteComponent = ({
  value = "",
  onAddressSelect,
  icon,
  placeholder,
  className,
  useFullAddress = false,
  ...rest
}: Props) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [options, setOptions] = useState<any[]>([]);
  const service = useMemo(
    () => new window.google.maps.places.AutocompleteService(),
    []
  );
  const geocoder = useMemo(() => new window.google.maps.Geocoder(), []);

  const displaySuggestions = useCallback((predictions: null | any[]) => {
    const suggestions = predictions?.map((prediction) => {
      const matchedSubStringCoordinates = prediction.matched_substrings[0];
      const before = prediction.description.slice(
        0,
        matchedSubStringCoordinates.offset
      );
      const matched = prediction.description.slice(
        matchedSubStringCoordinates.offset,
        matchedSubStringCoordinates.length
      );
      const after = prediction.description.slice(
        matchedSubStringCoordinates.length
      );
      return {
        value: prediction.description,
        label: (
          <div className="gray-7" key={prediction.place_id}>
            {before}
            <span className="gray-9">{matched}</span>
            {after}
          </div>
        ),
      };
    });
    setOptions(suggestions || []);
  }, []);

  const cb = useCallback(() => {
    if (currentValue) {
      service.getPlacePredictions(
        { input: currentValue, componentRestrictions: { country: "us" } },
        displaySuggestions
      );
    } else {
      setOptions([]);
    }
  }, [currentValue, displaySuggestions, service]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      cb();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [cb, currentValue]);

  const handleSelect = (address: string) => {
    onAddressSelect(address);
    geocodeByAddress(address).then((result) => {
      const populatedField = {
        ...populateAddressFields(result),
        fullAddress: result.formatted_address || "",
      };
      onAddressSelect(address, populatedField, true);
      if (useFullAddress === true && populatedField.fullAddress) {
        setCurrentValue(populatedField.fullAddress);
      } else if (populatedField.address) {
        setCurrentValue(populatedField.address);
      }
    });
  };

  const populateAddressFields = (place: any) => {
    const fields = {
      address: "",
      subpremise: "",
      postcode: "",
      locality: "",
      state: "",
      country: "",
    };

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          fields.address = `${component.long_name} ${fields.address}`;
          break;
        }

        case "subpremise": {
          fields.subpremise = `${component.long_name} ${fields.address}`;
          break;
        }

        case "route": {
          fields.address += component.short_name;
          break;
        }

        case "postal_code": {
          fields.postcode = `${component.long_name}${fields.postcode}`;
          break;
        }

        case "postal_code_suffix": {
          fields.postcode = `${fields.postcode}-${component.long_name}`;
          break;
        }

        case "locality":
          fields.locality = component.long_name;
          break;

        case "administrative_area_level_1": {
          fields.state = component.short_name;
          break;
        }

        case "country":
          fields.country = component.long_name;
          break;
      }
    }
    return fields;
  };

  const geocodeByAddress = (
    address: string
  ): Promise<
    Awaited<ReturnType<typeof geocoder.geocode>>["results"][number]
  > => {
    const OK = window.google.maps.GeocoderStatus.OK;
    return new Promise((resolve) => {
      geocoder.geocode(
        { address, componentRestrictions: { country: "us" } },
        (results, status) => {
          if (status == OK && results) {
            resolve(results[0]);
          }
        }
      );
    });
  };

  return (
    <AutoComplete
      options={options}
      searchValue={currentValue}
      value={currentValue}
      onSearch={setCurrentValue}
      onSelect={handleSelect}
      className={className}
      {...rest}
    >
      <Input
        autoComplete="false"
        placeholder={placeholder || ""}
        prefix={icon}
      />
    </AutoComplete>
  );
};

export const AddressAutocomplete = (props: Props) => (
  <Wrapper
    libraries={["places"]}
    apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY as string}
  >
    <AddressAutocompleteComponent {...props} />
  </Wrapper>
);
