import axios, { AxiosInstance } from "axios";

export const basePath = "https://vpic.nhtsa.dot.gov/api";

/**
 *  Types for Decode VIN Response
 */
export type DecodeVinResults = {
  Value: string | null;
  ValueId: string | null;
  Variable: string;
  VariableId: number;
};

export type DecodeVinResponse = {
  /** A count of the items returned in the Results array. */
  Count: number;
  /** A message describing the Results array. */
  Message: string;
  /** Search terms (VIN, WMI, manufacturer, etc.) used in the request URL. */
  SearchCriteria: string;
  /** The search results returned by the NHSTA API request. */
  Results: DecodeVinResults[];
};

/**
 *  Types for the Get Modeels For Make Year Response
 */
export type GetModelsForMakeYearAndVehicleTypeResults = {
  Make_ID: number;
  Make_Name: string;
  Model_ID: number;
  Model_Name: string;
};

export type GetModelsForMakeYearResponse = {
  /** A count of the items returned in the Results array. */
  Count: number;
  /** A message describing the Results array. */
  Message: string;
  /** Search terms (VIN, WMI, manufacturer, etc.) used in the request URL. */
  SearchCriteria: string;
  /** The search results returned by the NHSTA API request. */
  Results: GetModelsForMakeYearAndVehicleTypeResults[];
};

// The paths for all the Cloudinary endpoints.
export const nhtsaApiPath = {
  decodeVin: (vin: string, format = "json") =>
    `${basePath}/vehicles/DecodeVin/${vin}?format=json`,

  getModelsForMakeByYearAndVehicleType: ({
    make,
    year,
    vehicleType,
  }: {
    make: string;
    year: number;
    vehicleType: string;
  }) =>
    `${basePath}/vehicles/getmodelsformakeyear/make/${make}/modelyear/${year}/vehicletype/${vehicleType}?format=json`,
};

/**
 * Class to make calls to the NHTSA API.
 * REFERENCE: https://vpic.nhtsa.dot.gov/api
 */
class NhtsaApi {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: basePath,
    });
  }

  /**
   * Decode VIN
   */
  decodeVin = (vin: string) => {
    return this.api.get<DecodeVinResponse>(nhtsaApiPath.decodeVin(vin));
  };

  /**
   * Get all models for a make by year and vehicle type
   */
  getModelsForMakeByYearAndVehicleType = (params: {
    make: string;
    year: number;
    vehicleType: string;
  }) => {
    return this.api.get<GetModelsForMakeYearResponse>(
      nhtsaApiPath.getModelsForMakeByYearAndVehicleType(params)
    );
  };
}

export default NhtsaApi;
