import { ResponseFields } from "@dreambigger/shared/src/types";
import dayjs from "dayjs";

export const getPrefillDates = (
  initialValues: ResponseFields,
  dateFields: string[]
) => {
  if (!initialValues) {
    return;
  }

  // Define modifiedValues const to be returned in place of initialValues.
  const modifiedValues: ResponseFields = {};

  for (const field in initialValues) {
    // DatePicker component requires a dayjs object instead of a string, so convert.
    if (dateFields.includes(field)) {
      modifiedValues[field] = dayjs(initialValues[field] as string);
    } else {
      modifiedValues[field] = initialValues[field];
    }
  }

  return modifiedValues;
};
